<template>
    <div class="">
        <lr-config
            :ctx-name="ctxName"
            :pubkey="publicKey"
            :multiple="multiple"
            :multipleMax="multipleMax"
            confirmUpload="false"
            removeCopyright="true"
            :source-list="tabs"
            :accept="inputAcceptTypes"
            class="hidden"
        >
        </lr-config>

        <lr-file-uploader-inline
            :ctx-name="ctxName"
            css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@0.30.0/web/lr-file-uploader-inline.min.css"
        >
        </lr-file-uploader-inline>

        <lr-data-output
            :ctx-name="ctxName"
            use-event
            input-required
            @lr-data-output="handleUploaderEvent"
        ></lr-data-output>
        <slot />
    </div>
</template>
\
<script>
import * as LR from '@uploadcare/blocks';

LR.FileUploaderInline.shadowStyles = /* CSS */ `
  :host lr-start-from {
    grid-auto-flow: column;
  }
  :host lr-upload-list button.done-btn, :host lr-upload-list button.cancel-btn {
    display: none;
  }
  lr-start-from .content {
    padding: 20px 0!important;
  }
`;

LR.registerBlocks(LR);

export default {
    name: 'UploadcareInline',
    data() {
        return {
            uploadedValue: null
        };
    },
    props: {
        publicKey: {
            type: String,
            default: '71f65abd77a8d66ef2e1'
        },
        tabs: {
            type: String,
            default: 'local, url, gdrive, facebook'
        },
        inputAcceptTypes: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: false
        },
        multipleMax: {
            type: Number,
            default: 1
        },
        ctxName: {
            type: String,
            default: ''
        }
    },
    methods: {
        getValue() {
            return this.uploadedValue;
        },
        isPopup() {
            return true;
        },
        handleUploaderEvent(e) {
            const { data: files } = e.detail;

            if (!files?.length) {
              this.$emit('files-removed');
              this.uploadedValue = null;
              return;
            }

            const uploadedFiles = files.filter((file) => file.isUploaded === true);

            if(!uploadedFiles?.length) {
              return;
            }

            if (uploadedFiles.length === 1) {
                this.uploadedValue = uploadedFiles[0].cdnUrl;
                this.$emit('success', uploadedFiles[0]);
                return;
            }

            this.uploadedValue = uploadedFiles.map(file => file.cdnUrl);
            this.$emit('success', uploadedFiles);
        }
    }
};
</script>

<style>
.wrapper {
    display: flex;
    flex-direction: column;
}

.output {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    width: 100%;
    max-width: 1000px;
}
</style>
