<template>
    <modal
        adaptive
        width="600px"
        height="auto"
        name="multiple-venue-select-modal"
        @before-open="beforeOpen"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="text-center sm:text-left">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Publish across multiple stores
                </h3>
                <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                        Select the stores to which these settings should be
                        applied
                    </p>
                </div>
            </div>
            <div
                class="mt-3 grid max-h-96 grid-cols-2 gap-3 overflow-scroll p-2"
            >
                <base-button
                    size="sm"
                    class="col-span-2"
                    :button-text="
                        areAllSelected ? 'Deselect all' : 'Select all'
                    "
                    :disabled="isLoading"
                    @clicked="onSelectAll"
                />

                <base-checkbox
                    v-for="venue in venues"
                    :id="`venue-${venue.id}`"
                    v-model="venue.isSelected"
                    :key="venue.id"
                    :label="venue.name"
                />
            </div>
            <div class="mt-8 flex justify-between">
                <base-button
                    button-text="Cancel"
                    :disabled="isLoading"
                    @clicked="hideModal"
                />
                <base-button
                    :disabled="isLoading || !selectedVenueIds.length"
                    button-text="Publish multiple"
                    button-style="action"
                    @clicked="saveVenues"
                >
                    <template #left-icon>
                        <base-spinner
                            v-if="isLoading"
                            is-loading
                            class="mr-2 h-4 w-4"
                        />
                        <base-doc-icon v-else class="mr-2 h-4 w-4" />
                    </template>
                </base-button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'MultipleVenueSelectModal',
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            venues: []
        };
    },
    computed: {
        selectedVenueIds() {
            return this.venues
                .filter(({ isSelected }) => isSelected)
                .map(({ id }) => id);
        },
        areAllSelected() {
            return this.venues.every(({ isSelected }) => isSelected);
        }
    },
    methods: {
        beforeOpen({ params }) {
            const venues = params.venues.map(({ id, name }) => {
                return {
                    id,
                    name,
                    isSelected:
                        id === params.venueId || params.selectedIds.includes(id)
                };
            });

            this.venues = venues.sort(
                (a, b) => (b.id === params.venueId) - (a.id === params.venueId)
            );
        },
        saveVenues() {
            this.$emit('save-venues', this.selectedVenueIds);
        },
        hideModal() {
            this.$modal.hide('multiple-venue-select-modal');
        },
        onSelectAll() {
            this.venues = this.venues.map(venue => {
                return { ...venue, isSelected: !this.areAllSelected };
            });
        }
    }
};
</script>
