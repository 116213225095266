<template>
    <div
        class="fixed inset-0 flex flex-col items-center justify-center gap-10 p-4 text-center"
    >
        <div class="flex flex-col gap-2">
            <base-page-title title="Store Designer" />

            <span class="text-lg">
                The Store Designer is fully optimized for large screens. Please
                keep this in mind if you wish to proceed.
            </span>
        </div>

        <div class="flex w-full flex-wrap justify-between px-2">
            <router-link
                :to="{ name: 'editVenue' }"
                class="inline-flex items-center text-sm text-gray-600"
            >
                <base-back-icon />
                Go back
            </router-link>

            <button
                class="inline-flex items-center text-sm text-gray-600"
                @click="$emit('continue')"
            >
                Continue
                <base-back-icon class="ml-1" style="transform: scaleX(-1)" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileDeviceWarning'
};
</script>
