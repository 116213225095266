<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <ToggleSidebarButton />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-4 md:py-6"
        >
            <div class="mx-auto max-w-3xl px-4 sm:px-6 md:px-8">
                <div class="inline-flex w-full">
                    <nav class="flex w-full" aria-label="Breadcrumb">
                        <ol
                            role="list"
                            class="flex items-center justify-between w-full space-x-4 text-sm"
                        >
                            <li>
                                <div>
                                    <div
                                        class="flex cursor-pointer items-start font-bold tracking-wide text-gray-600"
                                    >
                                        <base-home-icon class="h-4 w-4" />
                                        <span class="ml-2 font-heading">{{
                                            accountName
                                        }}</span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)" >
                              <venue-selector
                                  v-if="venues.length && !isLoading"
                                  :venues="venues"
                                  :multiple-select="false"
                                  :allow-empty="true"
                                  size="sm"
                                  :pre-selected="dashboardVenue?.id"
                                  :hide-all-stores-option="true"
                                  @selected="onVenueChange"
                              />
                            </li>
                        </ol>
                    </nav>
                </div>
                <HomepageStats :hasOnboarded="hasOnboarded" v-if="reportingNotBlocked" />
                <!--                <HomepageOrders />-->
                <div
                    class="rounded-sm border bg-white shadow-sm mt-4"
                    v-if="!isLoading && !hasOnboarded"
                >
                    <div class="px-4 py-5 sm:p-6">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Create your store
                        </h3>
                        <div
                            class="mt-2 sm:flex sm:items-start sm:justify-between"
                        >
                            <div
                                class="max-w-xl text-sm leading-5 text-gray-500"
                            >
                                <p>
                                    Before you can start taking orders online,
                                    you'll need to setup your store and create a
                                    menu.
                                </p>
                            </div>
                            <div
                                class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"
                            >
                                <span
                                    @click="
                                        $router.push({ name: 'createVenue' })
                                    "
                                    class="inline-flex rounded-md shadow-sm"
                                >
                                    <button
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                    >
                                        Create store
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4" v-if="reportingNotBlocked">
                    <div>
                        <Orders
                            :period="30"
                            :embedded="true"
                            :selectedVenues="venueIds"
                        />
                    </div>
                </div>
                <HomepageTopItems v-if="hasOnboarded && reportingNotBlocked" />
                <upgrade-card v-if="!isFeatureAvailable(FeaturesEnum.SAAS)" />
                <whats-new-slider />
                <feature-request-prompt />
                <div class="mt-4 h-3 border-b border-gray-300 text-sm text-center">
                  <span class="px-5 bg-sk-silver-grey font-medium text-gray-700">Help & support</span>
                </div>
              <SupportRequestCard />
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FeaturesEnum } from '@/enums';
import HomepageStats from '@/components/widgets/HomepageStats';
import Orders from '@/components/reports/Orders';
import HomepageTopItems from '@/components/widgets/HomepageTopItems';
import ToggleSidebarButton from '@/components/helpers/ToggleSidebarButton';
import WhatsNewSlider from '@/components/widgets/WhatsNewSlider';
import FeatureRequestPrompt from '@/components/widgets/FeatureRequestPrompt';
import VenueSelector from '@/components/selectors/VenueSelector';
import SupportRequestCard from '@/components/widgets/SupportRequestCard';
import UpgradeCard from '@/components/widgets/UpgradeCard';

export default {
    name: 'AppDashboard',
    metaInfo: {
        title: 'Dashboard'
    },
    components: {
        HomepageStats,
        Orders,
        HomepageTopItems,
        ToggleSidebarButton,
        WhatsNewSlider,
        FeatureRequestPrompt,
        VenueSelector,
        SupportRequestCard,
        UpgradeCard
    },
    data() {
        return {
            isLoading: true,
            FeaturesEnum,
            dashboardVenue: {}
        };
    },
    async created() {
        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }

        await this.setVenueByDefault();
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        async onVenueChange(id) {
            const venue = this.venues.find(venue => venue.id === id) || null;

            if (!venue) {
                return;
            }

            localStorage.setItem('venueId', id);

            try {
                const { data } = await this.$axios.get(
                    `/venues/${venue.slug}/private`
                );

                this.dashboardVenue = data.venue;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async setVenueByDefault() {
            try {
                if (
                    this.venues.length === 1 &&
                    !this.currentUser.accounts[0].allVenueAccess
                ) {
                    const { data } = await this.$axios.get(
                        `/venues/${this.venues[0].slug}/private`
                    );

                    this.dashboardVenue = data.venue;
                } else {
                    await this.onVenueChange(
                        parseInt(localStorage.getItem('venueId'))
                    );
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            venues: 'venues/getVenues',
            accountName: 'user/accountName',
            accountId: 'user/accountId',
            userId: 'user/userId',
            currentUser: 'user/currentUser'
        }),
        hasPaymentsEnabled() {
            if (this.venues?.length < 1) {
                return false;
            }

            return this.venues.some(venue => venue.paymentsEnabled);
        },
        venueIds() {
            return this.venues.map(venue => venue.id);
        },
        hasOnboarded() {
            return this.venues && this.venues.length > 0;
        },
      reportingNotBlocked() {
        //   @TODO Manjaros reporting
        if(this.accountId !== '89449ec1-8df1-4bbb-997e-bd9348bcc113' && this.accountId !== '413c45a4-ef9d-4365-902a-82576bf3a35c' && this.accountId !== '048d3672-331c-4241-beb0-b60e3cc72cda' && this.accountId !== '4222230b-dc4d-44fc-9cb3-2dfd184955b6'
            && this.accountId !== '7d627c9e-6502-4068-84f4-868499ccda63'
            && this.accountId !== 'c41e04e9-a424-44b9-93b4-3cc3166b1995'
            && this.accountId !== '7569153a-c9eb-4974-8fcd-3584b76a6800'
            && this.accountId !== 'da7b7de5-afb2-43e8-92fa-82adab05e767'
            && this.accountId !== '7a8ed699-6c9a-4404-a679-1588470ddd40'
        ) {
          return true;
        }
        else if (this.accountId === '89449ec1-8df1-4bbb-997e-bd9348bcc113' && this.userId !== 10846) {
          return false;
        }
        else if (this.accountId === '413c45a4-ef9d-4365-902a-82576bf3a35c' && this.userId !== 10847) {
          return false;
        }
        else if (this.accountId === '048d3672-331c-4241-beb0-b60e3cc72cda' && this.userId !== 9474 && this.userId !== 10903) {
          return false;
        }
        else if (this.accountId === '7d627c9e-6502-4068-84f4-868499ccda63' && this.userId !== 11336) {
          return false;
        }
        // Byron
        else if (this.accountId === '4222230b-dc4d-44fc-9cb3-2dfd184955b6' && this.userId === 11168) {
          return false;
        }

        // Arancina
        else if (this.accountId === '7a8ed699-6c9a-4404-a679-1588470ddd40' && this.userId === 9438) {
          return false;
        }

        // Gracey's
        else if (this.accountId === 'c41e04e9-a424-44b9-93b4-3cc3166b1995' && this.userId === 11895) {
          return false;
        }
        // Savan
        else if (this.accountId === '7569153a-c9eb-4974-8fcd-3584b76a6800' && this.userId !== 5925) {
          return false;
        }
        // Sliceburg
        else if (this.accountId === 'da7b7de5-afb2-43e8-92fa-82adab05e767' && this.userId === 13032) {
          return false;
        }
        return true;
      }
    }
};
</script>

<style></style>
