<template>
    <div class="mt-6">
        <span class="block text-sm font-medium leading-5 text-gray-700">
            Contain
        </span>
        <div class="mt-3">
            <div class="grid grid-cols-3" v-if="product.tags">
                <div v-for="tag in contain" :key="tag.value">
                    <div class="mb-3 flex flex-row items-center">
                        <span
                            @click="toggleProductTag(tag)"
                            @keydown.space.prevent="toggleProductTag(tag)"
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            :class="{
                                'bg-gray-200': !product.tags.includes(
                                    tag.value
                                ),
                                'bg-indigo-600': product.tags.includes(
                                    tag.value
                                )
                            }"
                            class="focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-5': product.tags.includes(
                                        tag.value
                                    ),
                                    'translate-x-0': !product.tags.includes(
                                        tag.value
                                    )
                                }"
                                class="relative inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                            >
                                <span
                                    :class="{
                                        'opacity-0 duration-100 ease-out': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-100 duration-200 ease-in': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                >
                                    <svg
                                        class="h-3 w-3 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    :class="{
                                        'opacity-100 duration-200 ease-in': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-0 duration-100 ease-out': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out"
                                >
                                    <svg
                                        class="h-3 w-3 text-indigo-600"
                                        fill="currentColor"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                        </span>

                        <span class="ml-3 text-sm font-semibold text-gray-700">
                            {{ tag.label }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <span class="mt-6 block text-sm font-medium leading-5 text-gray-700">
            May contain
        </span>
        <div class="mt-3">
            <div class="grid grid-cols-3" v-if="product.tags">
                <div v-for="tag in mayContain" :key="tag.value">
                    <div class="mb-3 flex flex-row items-center">
                        <span
                            @click="toggleProductTag(tag)"
                            @keydown.space.prevent="toggleProductTag(tag)"
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            :class="{
                                'bg-gray-200': !product.tags.includes(
                                    tag.value
                                ),
                                'bg-indigo-600': product.tags.includes(
                                    tag.value
                                )
                            }"
                            class="focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-5': product.tags.includes(
                                        tag.value
                                    ),
                                    'translate-x-0': !product.tags.includes(
                                        tag.value
                                    )
                                }"
                                class="relative inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                            >
                                <span
                                    :class="{
                                        'opacity-0 duration-100 ease-out': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-100 duration-200 ease-in': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                >
                                    <svg
                                        class="h-3 w-3 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    :class="{
                                        'opacity-100 duration-200 ease-in': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-0 duration-100 ease-out': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out"
                                >
                                    <svg
                                        class="h-3 w-3 text-indigo-600"
                                        fill="currentColor"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                        </span>

                        <span class="ml-3 text-sm font-semibold text-gray-700">
                            {{ tag.label }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <span class="mt-6 block text-sm font-medium leading-5 text-gray-700">
            Tags
        </span>
        <div class="mt-3">
            <div class="grid grid-cols-3" v-if="product.tags">
                <div v-for="tag in tags" :key="tag.value">
                    <div class="mb-3 flex flex-row items-center">
                        <span
                            @click="toggleProductTag(tag)"
                            @keydown.space.prevent="toggleProductTag(tag)"
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            :class="{
                                'bg-gray-200': !product.tags.includes(
                                    tag.value
                                ),
                                'bg-indigo-600': product.tags.includes(
                                    tag.value
                                )
                            }"
                            class="focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-5': product.tags.includes(
                                        tag.value
                                    ),
                                    'translate-x-0': !product.tags.includes(
                                        tag.value
                                    )
                                }"
                                class="relative inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                            >
                                <span
                                    :class="{
                                        'opacity-0 duration-100 ease-out': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-100 duration-200 ease-in': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                >
                                    <svg
                                        class="h-3 w-3 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    :class="{
                                        'opacity-100 duration-200 ease-in': product.tags.includes(
                                            tag.value
                                        ),
                                        'opacity-0 duration-100 ease-out': !product.tags.includes(
                                            tag.value
                                        )
                                    }"
                                    class="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out"
                                >
                                    <svg
                                        class="h-3 w-3 text-indigo-600"
                                        fill="currentColor"
                                        viewBox="0 0 12 12"
                                    >
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                        </span>

                        <span class="ml-3 text-sm font-semibold text-gray-700">
                            {{ tag.label }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            @click="showNutrition = !showNutrition"
            class="mt-6 flex w-full cursor-pointer select-none items-center justify-between"
        >
            <span class="text-sm font-medium leading-5 text-gray-700">
                Nutrition
            </span>
            <span class="text-sm font-medium text-gray-700">
                <svg
                    class="h-6 w-6 transition duration-300"
                    :class="{ 'rotate-180 transform': showNutrition }"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </span>
        </div>
        <div
            v-show="showNutrition"
            class="mt-3 rounded-md border-2 border-dashed border-gray-300 bg-gray-50 p-2 sm:p-4"
        >
            <div>
                <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="calories"
                            class="block text-sm font-medium text-gray-700"
                            >Calories (kcal)</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.calories"
                                name="calories"
                                id="calories"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    kcal
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="salt"
                            class="block text-sm font-medium text-gray-700"
                            >Salt</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.salt"
                                name="salt"
                                id="salt"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="carbohydrates"
                            class="block text-sm font-medium text-gray-700"
                            >Carbohydrates</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.carbs"
                                name="carbohydrates"
                                id="carbohydrates"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="carbohydrates-sugar"
                            class="block text-sm font-medium text-gray-700"
                            >Carbohydrates (sugars)</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.carbsSugars"
                                name="carbohydrates-sugar"
                                id="carbohydrates-sugar"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="fats"
                            class="block text-sm font-medium text-gray-700"
                            >Fats</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.fats"
                                name="fats"
                                id="fats"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="fats-saturates"
                            class="block text-sm font-medium text-gray-700"
                            >Fats (saturates)</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.fatSaturates"
                                name="fats-saturates"
                                id="fats-saturates"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="fibre"
                            class="block text-sm font-medium text-gray-700"
                            >Fibre</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                type="number"
                                v-model.number="product.nutrition.fibre"
                                name="fibre"
                                id="fibre"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="protein"
                            class="block text-sm font-medium text-gray-700"
                            >Protein</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                v-model.number="product.nutrition.protein"
                                type="number"
                                name="protein"
                                id="protein"
                                class="block w-full rounded-md border-gray-300 pr-16 focus:border-indigo-500 focus:ring-indigo-500 sm:pr-14 sm:text-sm"
                                placeholder=""
                            />
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                                <span class="text-gray-500 sm:text-sm">
                                    grams
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mt-6 flex w-full cursor-pointer select-none items-center justify-between"
            @click="showIngredients = !showIngredients"
        >
            <span class="text-sm font-medium leading-5 text-gray-700">
                Ingredients
            </span>
            <span class="text-sm font-medium text-gray-700">
                <svg
                    class="h-6 w-6 transition duration-300"
                    :class="{ 'rotate-180 transform': showIngredients }"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    />
                </svg>
            </span>
        </div>

        <base-tags-input
            v-if="product.ingredients"
            v-show="showIngredients"
            v-model="product.ingredients"
            class="mt-3"
            :placeholder="product.ingredients.length ? '' : 'salt, flour'"
        />

        <div
            class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
            :class="{ spinner: isLoading }"
        >
            <span class="flex rounded-md shadow-sm">
                <button
                    @click="update"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                >
                    Save changes
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { tags } from '@/helpers/productTags';

export default {
    name: 'ProductTagsSettings',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            showNutrition: false,
            showIngredients: false
        };
    },
    mounted() {
        if (!this.product.tags) {
            this.product.tags = [];
        }
        if (!this.product.nutrition) {
            this.product.nutrition = {};
        }
    },
    computed: {
        availableTags() {
            const mayContain = JSON.parse(JSON.stringify(tags)).filter(
                item => (item.value = 200000 + item.value)
            );

            return [...tags, ...mayContain];
        },
        contain() {
            return this.productTagsFiltered(100, 999);
        },
        mayContain() {
            return this.productTagsFiltered(200100, 200999);
        },
        tags() {
            return [
                ...this.productTagsFiltered(0, 99),
                ...this.productTagsFiltered(1000, 1129)
            ];
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        update() {
            this.$emit('update');
        },
        productTagsFiltered(minVal, maxVal) {
            return this.availableTags.filter(tag => {
                return tag.value >= minVal && tag.value <= maxVal;
            });
        },
        toggleProductTag(tag) {
            if (this.product.tags.includes(tag.value)) {
                this.product.tags = this.product.tags.filter(
                    el => el !== tag.value
                );
            } else {
                this.product.tags.push(tag.value);
            }
        }
    }
};
</script>
