<template>
    <sliding-sidebar
        v-model="isOpened"
        title="Edit modifer"
        size="2xl"
        :id="modifier.id || ''"
        @on-escape-key-up="close"
    >
        <div>
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select
                    id="tabs"
                    name="tabs"
                    v-model="currentTab"
                    class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                    <option
                        v-for="tab in tabs"
                        :key="tab.key"
                        :value="tab"
                        :selected="tab"
                    >
                        {{ tab.name }}

                        <span
                            v-if="
                                tab.key === TabsEnum.TAGS_N_ALLERGENS.key &&
                                !isSomeAllergenSelected
                            "
                        >
                            (*no allergens selected)
                        </span>
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-10" aria-label="Tabs">
                        <span
                            v-for="tab in tabs"
                            :key="tab.name"
                            @click="changeTab(tab)"
                            class="relative cursor-pointer"
                            :class="[
                                tab.key === currentTab.key
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                            ]"
                            :aria-current="
                                tab.key === currentTab.key ? 'page' : undefined
                            "
                        >
                            <div
                                v-if="
                                    tab.key === TabsEnum.TAGS_N_ALLERGENS.key &&
                                    !isSomeAllergenSelected
                                "
                                class="absolute -right-2 top-0 mb-1 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
                            >
                                <svg
                                    class="h-3 w-3"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    ></path>
                                </svg>
                            </div>
                            {{ tab.name }}
                        </span>
                    </nav>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in" appear>
            <div v-if="currentTab.key === TabsEnum.DETAILS.key">
                <div v-if="!isLoading">
                    <div class="bg-white pb-4 pt-5 sm:py-6 sm:pb-4">
                        <div>
                            <label
                                for="name"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Name
                                <span v-show="showPrintName">
                                    (Printer override)</span
                                >
                            </label>
                            <div
                                class="relative mt-1 flex rounded-md shadow-sm"
                            >
                                <input
                                    v-show="!showPrintName"
                                    id="name"
                                    v-model="modifier.name"
                                    class="form-input block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="eg Four Seasons Pizza"
                                />
                                <input
                                    v-show="showPrintName"
                                    id="printName"
                                    v-model="modifier.printName"
                                    class="form-input block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Printer override name"
                                />
                                <button
                                    @click="togglePrintName(true)"
                                    type="button"
                                    class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                                    :class="{ 'bg-gray-200': showPrintName }"
                                >
                                    <svg
                                        class="h-5 w-5 text-gray-400"
                                        :class="{
                                            'text-green-700': !!modifier.printName
                                        }"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    @click="togglePrintName(false)"
                                    type="button"
                                    class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                                    :class="{ 'bg-gray-200': !showPrintName }"
                                >
                                    <svg
                                        class="h-5 w-5 text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div class="mt-3 flex flex-col sm:flex-row">
                            <div class="w-full sm:w-2/3">
                                <label
                                    for="price"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Price
                                </label>
                                <div class="relative mt-1 rounded-md shadow-sm">
                                    <div
                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.symbol }}
                                        </span>
                                    </div>
                                    <currency-input
                                        id="price"
                                        v-model="modifier.price"
                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                        placeholder="0.00"
                                        :currency="null"
                                        locale="en-GB"
                                        :value-as-integer="true"
                                        :allow-negative="false"
                                    />
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.ISO }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 w-full sm:mt-0 sm:w-1/3 sm:pl-4">
                                <base-input-field
                                    v-if="isFeatureAvailable(FeaturesEnum.PLU) || isFeatureAvailable(FeaturesEnum.INTERNAL) || modifier.plu"
                                    label="PLU"
                                    id="plu"
                                    v-model="modifier.plu"
                                    placeholder="e.g. ABC01"
                                />
                                <base-input-field
                                    v-else
                                    label="SKU / PLU"
                                    id="sku"
                                    v-model="modifier.sku"
                                    placeholder="e.g. ABC01"
                                />
                            </div>
                        </div>

                        <div
                            v-if="
                                isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                                isFeatureAvailable(FeaturesEnum.COMTREX) ||
                                isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                                isFeatureAvailable(FeaturesEnum.TISSL)
                            "
                            class="mt-3 w-full"
                        >
                            <base-input-field
                                label="POS ID"
                                id="posId"
                                tooltip="The unique identifier for this product in your POS system database"
                                v-model="modifier.posId"
                                placeholder="e.g. 1234"
                                error-message="This field is required"
                            />
                        </div>

                        <div
                            class="mt-3 w-full"
                        >
                          <label
                              for="compareAtPrice"
                              class="flex items-center text-sm font-medium leading-5 text-gray-700"
                              v-tooltip="
                        'The compare at price will show next to the actual price, with a line through it to convey a discount.'
                    "
                          >
                            Compare at price
                            <span class="ml-1 text-gray-500">(optional)</span>
                            <base-question-icon class="ml-1" />
                          </label>
                            <div class="relative mt-1 rounded-md shadow-sm">
                                <currency-input
                                    id="compareAtPrice"
                                    v-model="modifier.compareAtPrice"
                                    class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                    placeholder="0.00"
                                    :currency="null"
                                    locale="en-GB"
                                    :value-as-integer="true"
                                    :allow-negative="false"
                                />
                            </div>
                        </div>
                        <div class="mt-3">
                            <label
                                for="popular-modifier"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Highlight as popular modifier
                            </label>

                            <div class="mt-1 flex h-9 flex-row items-center">
                                <div>
                                    <toggle-switch
                                        id="popular-modifier"
                                        v-model="modifier.popular"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                                isFeatureAvailable(FeaturesEnum.INTERNAL)
                            "
                            class="mt-3 w-full"
                        >
                            <label
                                for="tax"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Tax Rate
                            </label>
                            <div class="relative mt-1 rounded-md shadow-sm">
                                <currency-input
                                    id="tax"
                                    v-model="modifier.deliveryTax"
                                    class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                    placeholder="0.00%"
                                    :currency="null"
                                    locale="en-GB"
                                    :value-as-integer="true"
                                    :allow-negative="false"
                                />
                            </div>
                        </div>

                        <div class="mt-3">
                            <label
                                for="trackInventory"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Track Inventory
                            </label>

                            <div class="mt-1 flex h-9 flex-row items-center">
                                <div>
                                    <toggle-switch
                                        v-model="modifier.trackInventory"
                                    />
                                </div>

                                <div
                                    class="relative ml-4 w-full rounded-md shadow-sm"
                                    v-if="modifier.trackInventory"
                                >
                                    <currency-input
                                        id="inventory"
                                        v-model.number="modifier.inventory"
                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                        placeholder="0"
                                        :currency="null"
                                        :precision="0"
                                        locale="en-GB"
                                        :value-as-integer="true"
                                        :allow-negative="false"
                                    ></currency-input>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            Quantity in stock
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-5"
                        >
                            <div
                                class="flex flex-row items-center justify-between"
                            >
                                <label
                                    for="image"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Image
                                    <span class="text-gray-400">
                                        (optional)
                                    </span>
                                </label>
                                <span
                                    @click="modifier.modifierImage = ''"
                                    class="transition-duration-100 cursor-pointer pl-14 text-red-400 transition hover:text-red-700"
                                    v-if="modifier.modifierImage"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div class="w-full">
                                <div>
                                    <uploadcare-inline
                                        :ctxName="`${modifier.id}product_settings_modifier_image`"
                                        inputAcceptTypes="image/*"
                                        tabs="local, dropbox, url, gdrive"
                                        @success="onImageSuccess"
                                    >
                                        <div
                                            v-if="modifier.modifierImage"
                                            class="mt-2 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 p-2 shadow-inner"
                                        >
                                            <div
                                                v-if="modifier.modifierImage"
                                                class="w-auto"
                                            >
                                                <div
                                                    class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                                    :style="`background-image: url(${modifier.modifierImage}-/resize/288x/-/format/auto/)`"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </uploadcare-inline>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
                    :class="{ spinner: isLoading }"
                >
                    <span class="flex rounded-md shadow-sm">
                        <button
                            @click="update"
                            :disabled="isLoading"
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        >
                            Save changes
                        </button>
                    </span>
                    <span
                        class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                    >
                        <button
                            @click="close"
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                        >
                            Close
                        </button>
                    </span>
                </div>
            </div>

            <product-tags-settings
                v-else-if="currentTab.key === TabsEnum.TAGS_N_ALLERGENS.key"
                :product="modifier"
                @close="close"
                @update="update"
            />
        </transition>
    </sliding-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import SlidingSidebar from '../SlidingSidebar';
import ProductTagsSettings from './modules/ProductTagsSettings';
import UploadcareInline from '@/components/widgets/UploadcareInline';

const TabsEnum = Object.freeze({
    DETAILS: { name: 'Details', key: 'details' },
    TAGS_N_ALLERGENS: {
        name: 'Tags & Allergens',
        key: 'tags-n-allergens'
    }
});

export default {
    name: 'EditModifierSidebar',
    components: {
        ToggleSwitch,
        SlidingSidebar,
        ProductTagsSettings,
        UploadcareInline
    },
    props: {
        modifier: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            TabsEnum,
            FeaturesEnum,
            currentTab: TabsEnum.DETAILS,
            tabs: Object.values(TabsEnum),
            showPrintName: false,
            isLoading: false,
            isOpened: false
        };
    },
    mounted() {
        setTimeout(() => {
            this.isOpened = true;
        }, 50);
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            currency: 'user/currencySettings'
        }),
        isSomeAllergenSelected() {
            return (
                this.modifier.tags && this.modifier.tags.some(tag => tag > 10)
            );
        }
    },
    methods: {
        onImageSuccess(event) {
            const regexp = /^(video)\//gi;

            if (regexp.test(event.mimeType)) {
                return;
            }

            this.modifier.modifierImage = event.cdnUrl;
        },
        togglePrintName(showPrintName) {
            this.showPrintName = showPrintName;
        },
        resetCurrentTab() {
            this.currentTab = TabsEnum.DETAILS;
        },
        changeTab(tab) {
            this.currentTab = tab;
        },
        openSlideOver() {
            this.isOpened = true;
        },
        update() {
            this.isOpened = false;

            setTimeout(() => {
                this.$emit('update', this.modifier);
            }, 50);
        },
        close() {
            this.isOpened = false;

            setTimeout(() => {
                this.$emit('close');
            }, 50);
        }
    },
    watch: {
        isOpened(value) {
            if (!value) {
                setTimeout(() => {
                    this.$emit('close');
                }, 50);
            }
        }
    }
};
</script>

<style src="@/assets/css/animations.css" />
