<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Opening Hours" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <p class="mt-2 text-sm text-gray-500">
                            Your customers can only place orders if your store
                            is "open" at the time they checkout. Set your
                            opening times for each day here.
                        </p>

                        <p class="mt-2 text-sm text-gray-500">
                            Add multiple time periods if you close and re-open
                            during one day. For example you could be open from
                            9AM to 1PM and then 5PM to 10PM.
                        </p>
                    </div>
                </div>

                <div class="mt-5 md:col-span-2 md:mt-0">
                    <VenueHours
                        v-if="!isLoading"
                        :venueHours="venueHours"
                        :hoursDict="hoursDict"
                        :closedArray="closedArray"
                        :END_OF_THE_DAY="END_OF_THE_DAY"
                        :validationError="!!errors && $v.venueHours.$invalid"
                        @editedVenueHours="editedVenueHours"
                    />
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Special Opening Hours</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            In addition to your regular opening hours above, you
                            can set date specific opening hours to override your default weekly opening hours.
                        </p>

<!--                        <p class="mt-2 text-sm text-gray-500">-->
<!--                            This allows you to accommodate special events,-->
<!--                            holidays, or other occasions when your standard-->
<!--                            hours may not apply. Simply select the date and-->
<!--                            specify the time period when your store will be-->
<!--                            open, overriding any previously set opening hours.-->
<!--                        </p>-->
                    </div>
                </div>

                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div
                        v-show="!isLoading"
                        class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                    >
                        <div class="mt-5 md:col-span-2 md:mt-0">
                            <div
                                class="rounded-sm border border-gray-100 shadow-inner"
                            >
                                <table
                                    class="min-w-full table-auto divide-y divide-gray-200"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                            >
                                                Open Time
                                            </th>
                                            <th
                                                scope="col"
                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                            >
                                                Close Time
                                            </th>
                                            <th
                                                scope="col"
                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                            >
                                            </th>
                                        </tr>
                                    </thead>

                                    <SpecialHourGroupListOptionsContainer
                                        v-if="specialHours"
                                        v-model="specialHours"
                                        helperClass="z-1000"
                                    >
                                        <SpecialHourGroupListOption
                                            v-for="(specialHour, index) in $v
                                                .specialHours.$each.$iter"
                                            :key="index"
                                            :specialHour="specialHour"
                                            @remove="removeSlot"
                                        />
                                    </SpecialHourGroupListOptionsContainer>
                                </table>

                                <div class="p-3">
                                    <div
                                        class="flex justify-center rounded-md bg-white px-6 pb-3 pt-2 text-center"
                                    >
                                        <p class="mt-1 text-sm text-gray-600">
                                            <button
                                                @click.prevent="addSlot"
                                                type="button"
                                                class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                            >
                                                Add an option
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2" v-if="$v.specialHours.$error">
                            <span class="py-2 text-xs text-red-600">
                                Complete the required fields in the form
                                elements above
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="updateHours">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { FeaturesEnum } from "@/enums";
import VenueHours from '../../editVenue/VenueHours';
import SpecialHourGroupListOption from '@/components/menuBuilder/SpecialHourGroupListOption';
import SpecialHourGroupListOptionsContainer from '@/components/menuBuilder/SpecialHourGroupListOptionsContainer';
import {mapGetters} from "vuex";

export default {
    name: 'OpeningHoursSettings',
    metaInfo: {
        title: 'Opening Hours'
    },
    props: ['venueId', 'venue'],
    components: {
        SpecialHourGroupListOptionsContainer,
        SpecialHourGroupListOption,
        VenueHours
    },
    data() {
        return {
            FeaturesEnum,
            END_OF_THE_DAY: '23:59',
            isLoading: true,
            errors: null,
            closedArray: [false, false, false, false, false, false, false],
            hoursDict: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            venueHours: [
                {
                    day: 0,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 1,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 2,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 3,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 4,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 5,
                    openTime: '',
                    closeTime: ''
                },
                {
                    day: 6,
                    openTime: '',
                    closeTime: ''
                }
            ],
            specialHours: []
        };
    },
    validations: {
        venueHours: {
            required,
            minLength: minLength(7),
            $each: {
                openTime: {
                    required,
                    minLength: minLength(5)
                },
                closeTime: {
                    required,
                    minLength: minLength(5)
                }
            }
        },
        specialHours: {
            $each: {
                openTime: {
                    required,
                    minLength: minLength(5)
                },
                closeTime: {
                    required,
                    minLength: minLength(5)
                },
                date: {
                    required
                }
            }
        }
    },
    validationGroup: ['venueHours', 'specialHours'],
    async created() {
        await this.fetchHours();
    },
    methods: {
        addSlot() {
            const item = {
                date: '',
                openTime: '',
                closeTime: '',
                active: true
            };

            this.specialHours.push(item);
        },
        removeSlot(product) {
            this.specialHours = this.specialHours.filter(x => x !== product);
        },
        editedVenueHours(hours) {
            this.venueHours = hours;
        },
        async fetchHours() {
            this.isLoading = true;

            try {
                const response = await this.$axios.get(
                    '/venues/' + this.venueId + '/hours'
                );

                this.venueHours = response.data.hours;
                this.specialHours = response.data.specialHours;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async updateHours() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            this.isLoading = true;

            try {
                const response = await this.$axios.put(
                    `/venues/${this.venue.id}/hours`,
                    {
                        venueHours: this.venueHours,
                        specialHours: this.specialHours
                    }
                );

                if (!response === 'ok') {
                    throw new Error(`API error`);
                }

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable',
    })
  }
};
</script>
