<template>
    <div class="w-full">
        <div
            class="whitespace-nowrap text-sm font-medium leading-5 text-gray-700"
        >
            Select view
        </div>
        <multi-select
            id="viewsSelector"
            v-model="computedValue"
            :allow-empty="false"
            :show-labels="false"
            :searchable="false"
            :multiple="false"
            :options="views"
            close-on-select
            label="label"
        />
    </div>
</template>

<script>
export default {
    name: 'ViewSelector',
    props: {
        value: {
            type: Object,
            required: true
        },
        views: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
