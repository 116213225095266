<template>
    <modal
        name="add-category-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:p-6"
        >
            <div>
                <div class="text-left">
                    <h3
                        class="font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        Add a Category
                    </h3>
                    <div class="mt-3">
                        <base-input-field
                            label="Name"
                            id="name"
                            v-model="category.name"
                            :errors="$v.category.name.$error"
                            placeholder="e.g. Pizzas"
                        />
                    </div>
                    <div class="mt-3">
                        <base-text-area
                            label="Description"
                            v-model.trim="category.description"
                            :rows="2"
                            id="description"
                        />
                    </div>
                    <div
                        class="mt-5"
                        v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                    >
                        <div class="flex flex-row items-center justify-between">
                            <label
                                for="image"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Image
                                <span class="text-gray-400"> (optional) </span>
                            </label>
                            <span
                                v-if="category.image"
                                class="transition-duration-100 cursor-pointer pl-14 text-red-400 transition hover:text-red-700"
                                @click="category.image = ''"
                            >
                                <svg
                                    class="h-5 w-5"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                        <uploadcare-inline
                            :ctxName="`${ctxName}category_image`"
                            inputAcceptTypes="image/*"
                            tabs="local, dropbox, url, gdrive"
                            @success="onImageSuccess"
                        >
                            <div
                                :class="{
                                    'p-6': !category.image,
                                    'p-3': category.image
                                }"
                                class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300"
                            >
                                <div class="text-center" v-if="!category.image">
                                    <p class="mt-1 text-xs text-gray-500">
                                        PNG, JPG, GIF up to 10MB
                                    </p>
                                </div>
                                <div v-else class="w-auto">
                                    <div
                                        class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                        :style="`background-image: url(${category.image}-/resize/288x/-/format/auto/)`"
                                    >
                                    </div>
                                </div>
                            </div>
                        </uploadcare-inline>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 sm:mt-6 sm:flex sm:w-auton flex-row-reverse justify-between sm:flex"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        v-if="isImageReady"
                        @click="createCategory"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Create Category
                    </button>
                    <button
                        v-else
                        class="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm sm:text-sm sm:leading-5"
                    >
                        File uploading
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('add-category-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'AddCategoryModal',
    components: { UploadcareInline },
    validations: {
        category: {
            name: {
                required
            },
            description: {}
        }
    },
    data() {
        return {
            isLoading: false,
            category: {
                name: '',
                description: '',
                index: 99,
                adminIndex: 99,
                image: '',
                navImage: ''
            },
            isImageReady: true,
            FeaturesEnum,
            ctxName: '',
            venueId: ''
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            user: 'user/currentUser'
        })
    },
    methods: {
        onImageError(event) {
            throw new Error('Error: ', event);
        },
        beforeOpen({ params }) {
            this.category.index = params.categoriesLength + 1;
            this.category.adminIndex = params.categoriesLength + 1;
            this.category.name = '';
            this.category.description = '';
            this.category.image = '';
            this.category.navImage = '';
            this.ctxName = params.categoriesLength;
            this.venueId = params.venueId;
        },
        createCategory() {
            this.$v.category.$touch();
            if (this.$v.category.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.isLoading = true;
                let category = this.category;
                const menuId = this.$route.params.id;

                this.$axios
                    .post(`/categories/${menuId}`, category)
                    .then(() => {
                        this.$v.$reset();
                        this.$modal.hide('add-category-modal');
                        // Reset to inital state https://stackoverflow.com/questions/35604987/is-there-a-proper-way-of-resetting-a-components-initial-data-in-vuejs
                        this.$emit('product-added');
                        // Object.assign(this.$data, this.$options.data())
                        if (window.analytics) {
                            window.analytics.track('Category Created', {
                                menuId,
                                name: category.name,
                                description: category.description,
                                accountId: this.user.accounts[0].id,
                                venueId: this.venueId,
                                context: {
                                    groupId: this.user.accounts[0].id
                                }
                            });
                        }

                        this.$notify({
                          group: 'settings',
                          duration: 2000,
                          text: `Category created`,
                        });
                    })
                    .catch(e => {
                        throw new Error(`API ${e}`);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onImageSuccess(event) {
            this.category.image = event.cdnUrl;
        },
        imageProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isImageReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isImageReady = true;
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
