<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <toggle-sidebar />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto"
            tabindex="0"
        >
            <div class="pb-6 pt-2 md:py-6">
                <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div class="min-w-0 flex-1">
                            <router-link
                                :to="{ name: 'discounts' }"
                                class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                            >
                                <base-back-icon />
                                <span>Discounts</span>
                            </router-link>
                            <BasePageTitle
                                :title="
                                    isEdit ? 'Edit Discount' : 'Create Discount'
                                "
                            />
                        </div>

                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="isLoading"
                    class="mx-auto max-w-5xl px-4 grid grid-cols-6 py-8 gap-6 sm:px-6 md:px-8 relative"
                >
                    <div class="col-span-6 md:col-span-4 overflow-hidden bg-white shadow sm:rounded-md">
                      <div class="p-4">
                          <vcl-list :width="400" :height="200" />
                      </div>
                    </div>
                  <div
                      v-if="isEdit"
                    class="top-0 col-span-6 h-auto rounded-md bg-white  opacity-90 shadow md:sticky md:col-span-2"
                  >
                    <div class="p-4">
                      <vcl-list :width="250" :height="200" />
                    </div>
                  </div>
                </div>

                <div
                    v-else
                    class="relative mx-auto grid max-w-5xl grid-cols-6 gap-6 py-6 sm:py-8 sm:px-6 md:px-8"
                >
                    <div
                        class="col-span-6 overflow-hidden  md:col-span-4"
                    >
                        <div class="">
                            <form
                                @submit.prevent="
                                    isEdit
                                        ? editDiscountCode()
                                        : createDiscountCode()
                                "
                            >
                                <div>
                                    <div
                                        :class="{
                                            'invalid-form-field':
                                                $v.code.code.$error
                                        }"
                                        class="p-4 rounded-md bg-white shadow"
                                    >
                                        <div
                                            class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                        >
                                            <div class="sm:col-span-6">
                                                <div class="relative mb-1">
                                                    <label
                                                        for="code"
                                                        class="block text-sm font-medium text-gray-700"
                                                    >
                                                        Discount code
                                                    </label>
                                                </div>
                                                <div
                                                    class="mt-1 flex relative justify-between"
                                                >
                                                    <input
                                                        v-model="code.code"
                                                        id="code"
                                                        :disabled="isEdit"
                                                        :class="{
                                                            'border-red-300':
                                                                $v.code.code
                                                                    .$error,
                                                            'cursor-not-allowed select-none bg-gray-100': isEdit
                                                        }"
                                                        class="form-input relative block w-full min-w-0 flex-1 rounded-md uppercase shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    />
                                                      <span
                                                          v-if="code.autoApply"
                                                          class="absolute top-2 select-none right-2 inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
                                                      >AUTO</span
                                                      >
                                                    <button
                                                        v-if="!isEdit"
                                                        @click.stop="
                                                            generateRandomCode
                                                        "
                                                        type="button"
                                                        class="focus:outline-none ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >Generate code</button
                                                    >
                                                </div>
                                                <p
                                                    class="mt-2 text-xs text-gray-500"
                                                >
                                                    Customers will enter this
                                                    code at checkout.
                                                </p>
                                                <p
                                                    v-if="$v.code.code.$error"
                                                    class="text-xs text-red-400"
                                                >
                                                    Discount codes must be 3 or
                                                    more characters long and
                                                    contain only letters and
                                                    numbers
                                                </p>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <label
                                                for="about"
                                                class="block text-sm font-medium leading-5 text-gray-700"
                                            >
                                                Description
                                            </label>
                                            <div class="rounded-md shadow-sm">
                                                <textarea
                                                    v-model="code.description"
                                                    id="about"
                                                    rows="1"
                                                    class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    placeholder=""
                                                ></textarea>
                                            </div>
                                            <p
                                                class="mt-2 text-xs text-gray-500"
                                            >
                                                Add an optional description
                                                (visible only to store admins).
                                            </p>
                                        </div>
                                    </div>

                                  <div
                                      class="mt-5 rounded-md bg-white shadow"
                                  >
                                    <div
                                        class="grid p-4 grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                        v-auto-animate
                                    >
                                      <div class="sm:col-span-2">
                                        <label
                                            for="type"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                        >
                                          Discount type
                                        </label>
                                        <div
                                            class="mt-1 rounded-md shadow-sm"
                                        >
                                          <select
                                              id="type"
                                              v-model="code.type"
                                              class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                              :class="{
                                                            'border-red-300':
                                                                $v.code.type
                                                                    .$error
                                                        }"
                                          >
                                            <option
                                                :value="
                                                                DiscountTypeEnum.PERCENT
                                                            "
                                            >
                                              Percentage
                                            </option>
                                            <option
                                                :value="
                                                                DiscountTypeEnum.FIXED
                                                            "
                                            >
                                              Fixed
                                            </option>
                                            <option
                                                :value="
                                                                DiscountTypeEnum.FREE_DELIVERY
                                                            "
                                            >
                                              Free delivery
                                            </option>
                                            <option
                                                disabled
                                                :value="
                                                                DiscountTypeEnum.BUY_ONE_GET_ONE_FREE
                                                            "
                                            >
                                              Buy X get Y
                                            </option>
                                          </select>
                                        </div>
                                        <label
                                            v-if="$v.code.type.$error"
                                            class="mt-2 text-xs text-red-400"
                                        >
                                          Type of the discount code
                                          must be selected
                                        </label>
                                      </div>

                                      <div
                                          class="sm:col-span-4"
                                          v-if="
                                                    code.type !==
                                                    DiscountTypeEnum.FREE_DELIVERY
                                                "
                                      >
                                        <label
                                            for="codeAmount"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                        >
                                          Discount value
                                        </label>
                                        <div
                                            class="relative mt-1 rounded-md shadow-sm"
                                        >
                                          <div
                                              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                          >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                            code.type ===
                                                            DiscountTypeEnum.FIXED
                                                                ? currency.symbol
                                                                : ''
                                                          }}
                                                        </span>
                                          </div>
                                          <currency-input
                                              v-if="code.type === DiscountTypeEnum.PERCENT"
                                              id="codeAmount"
                                              name="codeAmount"
                                              class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                              v-model="
                                                            code.codeAmount
                                                        "
                                              v-bind="
                                                            codeAmountInputProps
                                                        "
                                          />
                                          <currency-input
                                              v-if="code.type === DiscountTypeEnum.BUY_ONE_GET_ONE_FREE"
                                              id="codeAmount"
                                              name="codeAmount"
                                              class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                              v-model="
                                                            code.codeAmount
                                                        "
                                              v-bind="
                                                            codeAmountInputProps
                                                        "
                                          />

                                          <currency-input
                                              v-if="
                                                            code.type ===
                                                            DiscountTypeEnum.FIXED
                                                        "
                                              id="codeAmount"
                                              name="codeAmount"
                                              class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                              v-model="
                                                            code.codeAmount
                                                        "
                                              v-bind="
                                                            codeAmountInputProps
                                                        "
                                          />
                                          <div
                                              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                          >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                            code.type ===
                                                            DiscountTypeEnum.FIXED
                                                                ? currency.ISO
                                                                : '%'
                                                          }}
                                                        </span>
                                          </div>
                                        </div>
                                        <label
                                            v-if="
                                                        $v.code.codeAmount
                                                            .$error
                                                    "
                                            class="mt-2 text-xs text-red-400"
                                        >
                                          Value of the discount code
                                          must be positive
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                        class="mt-2 border-t border-gray-100"
                                        v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.AUTO_APPLY)"
                                    >
                                      <div class="px-4 py-3 flex flex-row items-center">
                                        <base-small-toggle-switch
                                            v-model="code.autoApply"
                                        />
                                        <label
                                            class="ml-2 flex items-center text-sm font-medium text-gray-500"
                                            v-tooltip="'Automatically apply the discount code to the customer\'s order when the conditions are met. If disabled, the customer will need to enter the code at checkout.'"
                                        >
                                          Auto apply
                                          <base-question-icon class="ml-1 text-gray-700" />
                                        </label>
                                      </div>

                                    </div>
                                  </div>

                                    <div
                                        :class="{
                                            'invalid-form-field':
                                                $v.code.venues.$error
                                        }"
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                    >
                                        <div>
                                            <h3
                                                class="text-sm font-medium text-gray-900"
                                            >
                                                Schedule
                                            </h3>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Schedule the discount to
                                                activate and/or end in the
                                                future
                                            </p>
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <div class="mt-4 flex gap-4">
                                                    <div class="w-full" v-auto-animate>
                                                        <div
                                                            class="flex flex-row items-center"
                                                        >
                                                          <base-small-toggle-switch
                                                              v-model="enableStartDate"
                                                          />
                                                            <label
                                                                class="ml-2 text-sm font-medium text-gray-500"
                                                            >
                                                                Start date
                                                            </label>
                                                        </div>

                                                        <DiscountValiditySelector
                                                            v-if="enableStartDate"
                                                            v-model="
                                                                startDateRange
                                                            "
                                                            :disabled="
                                                                !enableStartDate
                                                            "
                                                        />
                                                    </div>
                                                    <div class="w-full">
                                                        <div
                                                            class="flex flex-row items-center"
                                                            v-auto-animate
                                                        >
                                                          <base-small-toggle-switch
                                                              v-model="enableEndDate"
                                                          />
                                                            <label
                                                                class="ml-2 text-sm font-medium text-gray-500"
                                                            >
                                                                End date
                                                            </label>
                                                        </div>
                                                        <DiscountValiditySelector
                                                            v-if="enableEndDate"
                                                            v-model="
                                                                endDateRange
                                                            "
                                                            :disabled="
                                                                !enableEndDate
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        :class="{
                                            'invalid-form-field':
                                                $v.code.venues.$error
                                        }"
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                    >
                                        <div>
                                            <h3
                                                class="text-sm font-medium text-gray-900"
                                            >
                                                Stores
                                            </h3>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Select which stores this
                                                discount code can be used on
                                            </p>
                                        </div>
                                        <div class="mt-2">
                                            <div
                                                class="mt-2"
                                                :class="{
                                                    invalid:
                                                        $v.code.venues.$error
                                                }"
                                            >
                                                <multiselect-with-select-all
                                                    :pre-selected="code.venues"
                                                    :available-options="venues"
                                                    placeholder="Select one or more stores"
                                                    @selected="
                                                        onChangeInSelectedStores
                                                    "
                                                />

                                                <label
                                                    v-if="$v.code.venues.$error"
                                                    class="mt-2 text-xs text-red-400"
                                                >
                                                    Please select at least one store
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                    >
                                        <div>
                                            <h3
                                                class="text-sm flex items-center font-medium text-gray-900"
                                                v-tooltip="'Select the days of the week customers will be able to use this discount.'"
                                            >
                                                Limit availability
                                                <base-question-icon class="ml-1 text-gray-700" />
                                            </h3>
                                        </div>
                                        <div
                                            class="mt-1 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                        >
                                            <div class="sm:col-span-12">
                                                <label
                                                    class="block text-sm sr-only font-medium leading-5 text-gray-700"
                                                >
                                                    Only on:
                                                </label>

                                                <WeekDayPicker v-model="code.days" :error="$v.code.days.$error" class="mt-2" />

                                                <p
                                                    v-if="$v.code.days.$error"
                                                    class="mt-3 text-red-500 text-xs"
                                                >
                                                    Select one or more days
                                                </p>
                                            </div>

                                            <div class="sm:col-span-12">
                                                <label
                                                    class="block text-sm font-medium leading-5 text-gray-700"
                                                >
                                                    Time period
                                                </label>
                                                <div
                                                    v-if="!allDay"
                                                    class="sm:col-span-12"
                                                >
                                                    <vue-timepicker
                                                        manual-input
                                                        v-model="code.startTime"
                                                        :minute-interval="1"
                                                    >
                                                    </vue-timepicker>

                                                    <span
                                                        class="px-4 py-2 text-gray-600"
                                                    >
                                                        to
                                                    </span>

                                                    <vue-timepicker
                                                        manual-input
                                                        v-model="code.endTime"
                                                        :minute-interval="1"
                                                    >
                                                    </vue-timepicker>
                                                </div>

                                                <div
                                                    class="mt-4 flex items-center"
                                                >
                                                    <span
                                                        role="checkbox"
                                                        tabindex="0"
                                                        aria-checked="false"
                                                        class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                                                        @click="toggleAllDay"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            :class="{
                                                                'bg-gray-200': !allDay,
                                                                'bg-black': allDay
                                                            }"
                                                            class="absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                                                        ></span>
                                                        <span
                                                            aria-hidden="true"
                                                            :class="{
                                                                'translate-x-0': !allDay,
                                                                'translate-x-5': allDay
                                                            }"
                                                            class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                                                        ></span>
                                                    </span>
                                                    <span
                                                        class="pl-3 text-sm font-medium leading-5 text-gray-700"
                                                    >
                                                        All day
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                $v.code.startTime.$error ||
                                                $v.code.endTime.$error
                                            "
                                            class="p-1 text-red-500 sm:col-span-12"
                                        >
                                            Date range incorrect.
                                        </div>

                                        <div class="mt-6 sm:col-span-12">
                                            <label
                                                for="types"
                                                class="block text-sm font-medium leading-5 text-gray-700"
                                            >
                                                Order types:
                                            </label>
                                            <div>
                                                <fulfillment-method-picker
                                                    v-model="code.fulfillmentMethods"
                                                    :error=" $v.code.fulfillmentMethods.$error"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                    >
                                        <div>
                                            <h3
                                                class="flex items-center text-sm font-medium text-gray-900"
                                                v-tooltip="'Require customers to spend a certain amount before they can use this discount code. The value is calculated using the order subtotal, i.e. the sum of items before gratuity and delivery fees.'"
                                            >
                                                Require a minimum spend
                                              <base-question-icon class="ml-1 text-gray-700" />
                                            </h3>
                                        </div>
                                        <div class="mt-6">
                                            <fieldset class="mt-2">
                                                <div v-auto-animate>
                                                    <div
                                                        class="flex items-center"
                                                        @click="
                                                            code.minimumAmount = 0
                                                        "
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasMinimum
                                                            "
                                                            :value="false"
                                                            id="push_everything"
                                                            name="form-input push_notifications"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="push_everything"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                None
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="mt-4 flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasMinimum
                                                            "
                                                            :value="true"
                                                            id="push_email"
                                                            name="form-input push_notifications"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="push_email"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Minimum spend
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        v-if="code.hasMinimum"
                                                        class="mt-2 pl-6 sm:col-span-3"
                                                    >
                                                        <div
                                                            class="relative mt-1 rounded-md shadow-sm"
                                                        >
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                            >
                                                                <span
                                                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                                                >
                                                                    {{
                                                                        currency.symbol
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <currency-input
                                                                id="price"
                                                                v-model="
                                                                    code.minimumAmount
                                                                "
                                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                                :class="{
                                                                    'border-red-300':
                                                                        $v.code
                                                                            .minimumAmount
                                                                            .$error
                                                                }"
                                                                placeholder="0"
                                                                :currency="null"
                                                                locale="en-GB"
                                                                :value-as-integer="
                                                                    true
                                                                "
                                                                :allow-negative="
                                                                    false
                                                                "
                                                            ></currency-input>
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                            >
                                                                <span
                                                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                                                >
                                                                    {{
                                                                        currency.ISO
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                    >
                                        <div>
                                          <h3
                                              class="flex items-center text-sm font-medium text-gray-900"
                                              v-tooltip="'Limit how many this discount code can be used in total. Limit applies across all customers, not per customer.'"
                                          >
                                            Limit the number of redemptions
                                            <base-question-icon class="ml-1 text-gray-700" />
                                          </h3>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit applies across all
                                                customers, not per customer.
                                            </p>
                                        </div>
                                        <div class="mt-6">
                                            <fieldset class="mt-6">
                                                <div v-auto-animate>
                                                    <div
                                                        class="flex items-center"
                                                        @click="
                                                            code.usageLimit = 0
                                                        "
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasUsageLimit
                                                            "
                                                            :value="false"
                                                            id="usage_limit_disabled"
                                                            name="form-input usage_limits"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="usage_limit_disabled"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                None
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="mt-4 flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasUsageLimit
                                                            "
                                                            :value="true"
                                                            id="usage_limit_enabled"
                                                            name="form-input usage_limits"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="usage_limit_enabled"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Limit number of
                                                                times this
                                                                discount can be
                                                                used
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            code.hasUsageLimit
                                                        "
                                                        class="mt-2 pl-6 sm:col-span-3"
                                                    >
                                                        <div
                                                            class="relative mt-1 rounded-md shadow-sm"
                                                        >
                                                            <currency-input
                                                                id="price"
                                                                v-model="
                                                                    code.usageLimit
                                                                "
                                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                                :class="{
                                                                    'border-red-300':
                                                                        $v.code
                                                                            .usageLimit
                                                                            .$error
                                                                }"
                                                                placeholder="0"
                                                                :currency="null"
                                                                :precision="0"
                                                                locale="en-GB"
                                                                :value-as-integer="
                                                                    true
                                                                "
                                                                :allow-negative="
                                                                    false
                                                                "
                                                            ></currency-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 rounded-md bg-white p-4 shadow"
                                        v-if="this.code.type === DiscountTypeEnum.PERCENT ||
                                                this.code.type === DiscountTypeEnum.BUY_ONE_GET_ONE_FREE"
                                    >
                                        <div>
                                            <h3
                                                class="flex items-center text-sm font-medium text-gray-900"
                                                v-tooltip="
                                                    'Limit the total discount that can be applied to an order. This restriction ensures the discount does not exceed the specified amount.'
                                                "
                                            >
                                                Maximum discount amount
                                                <base-question-icon
                                                    class="ml-1 text-gray-700"
                                                />
                                            </h3>
                                        </div>
                                        <div class="mt-6">
                                            <fieldset class="mt-2">
                                                <div v-auto-animate>
                                                    <div
                                                        class="flex items-center"
                                                        @click="
                                                            code.maxDiscountAmount = 0
                                                        "
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasMaxDiscountAmount
                                                            "
                                                            :value="false"
                                                            id="maxDiscountAmount"
                                                            name="maxDiscountAmount"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="maxDiscountAmount"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                None
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="mt-4 flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasMaxDiscountAmount
                                                            "
                                                            :value="true"
                                                            id="max_discount"
                                                            name="max_discount"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="max_discount"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Maximum discount
                                                                amount
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            code.hasMaxDiscountAmount
                                                        "
                                                        class="mt-2 pl-6 sm:col-span-3"
                                                    >
                                                        <div
                                                            class="relative mt-1 rounded-md shadow-sm"
                                                        >
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                            >
                                                                <span
                                                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                                                >
                                                                    {{
                                                                        currency.symbol
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <currency-input
                                                                id="price"
                                                                v-model="
                                                                    code.maxDiscountAmount
                                                                "
                                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                                :class="{
                                                                    'border-red-300':
                                                                        $v.code
                                                                            .maxDiscountAmount
                                                                            .$error
                                                                }"
                                                                placeholder="0"
                                                                :currency="null"
                                                                locale="en-GB"
                                                                :value-as-integer="
                                                                    true
                                                                "
                                                                :allow-negative="
                                                                    false
                                                                "
                                                            ></currency-input>
                                                            <div
                                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                            >
                                                                <span
                                                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                                                >
                                                                    {{
                                                                        currency.ISO
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div
                                        class="mt-5 rounded-md bg-white p-4 shadow"
                                    >
                                        <div>
                                            <h3
                                                class="text-sm font-medium text-gray-900"
                                            >
                                                Limit the number of redemptions
                                                per customer
                                            </h3>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit how many times this code
                                                can be used by each customer
                                                (Customers identified by email
                                                address).
                                            </p>
                                        </div>
                                        <div class="mt-6">
                                            <fieldset class="mt-2">
                                                <div v-auto-animate>
                                                    <div
                                                        class="flex items-center"
                                                        @click="
                                                            code.usageLimitPerUser = 0
                                                        "
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasUsageLimitPerUser
                                                            "
                                                            :value="false"
                                                            id="usage_limit_per_user_disabled"
                                                            name="form-input usage_limits_per_user"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="usage_limit_per_user_disabled"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                None
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="mt-4 flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.hasUsageLimitPerUser
                                                            "
                                                            :value="true"
                                                            id="usage_limit_per_user_enabled"
                                                            name="form-input usage_limits_per_user"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="usage_limit_per_user_enabled"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Limit number of
                                                                times this
                                                                discount can be
                                                                used by each
                                                                customer
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            code.hasUsageLimitPerUser
                                                        "
                                                        class="mt-2 pl-6 sm:col-span-3"
                                                    >
                                                        <div
                                                            class="relative mt-1 rounded-md shadow-sm"
                                                        >
                                                            <currency-input
                                                                id="price"
                                                                v-model="
                                                                    code.usageLimitPerUser
                                                                "
                                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                                :class="{
                                                                    'border-red-300':
                                                                        $v.code
                                                                            .usageLimitPerUser
                                                                            .$error
                                                                }"
                                                                placeholder="0"
                                                                :currency="null"
                                                                :precision="0"
                                                                locale="en-GB"
                                                                :value-as-integer="
                                                                    true
                                                                "
                                                                :allow-negative="
                                                                    false
                                                                "
                                                            ></currency-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 p-4 rounded-md bg-white shadow"
                                        v-if="
                                            code.type !==
                                            DiscountTypeEnum.FREE_DELIVERY && code.fulfillmentMethods.includes(FulfillmentMethodsEnum.DELIVERY)
                                        "
                                    >
                                        <div>
                                            <h3
                                                class="text-sm font-medium text-gray-900"
                                            >
                                                Includes Delivery
                                            </h3>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Choose whether this discount
                                                will apply to the delivery fee
                                                if it exists on the order.
                                                Exclude delivery fee to charge
                                                your standard delivery fee.
                                            </p>
                                        </div>
                                        <div class="mt-6">
                                            <fieldset class="mt-6">
                                                <div v-auto-animate>
                                                    <div
                                                        class="flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.excludeDelivery
                                                            "
                                                            :value="false"
                                                            id="excludeDelivery"
                                                            name="form-input exclude-delivery"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="excludeDelivery"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Includes
                                                                delivery
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="mt-4 flex items-center"
                                                    >
                                                        <input
                                                            v-model="
                                                                code.excludeDelivery
                                                            "
                                                            :value="true"
                                                            id="includeDelivery"
                                                            name="form-input exclude-delivery"
                                                            type="radio"
                                                            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label
                                                            for="includeDelivery"
                                                            class="ml-3"
                                                        >
                                                            <span
                                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                            >
                                                                Excludes
                                                                delivery
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <div class="flex justify-end py-2">
                                        <div
                                            v-if="isEdit"
                                        >
                                                <button
                                                    v-if="code.archived"
                                                    :disabled="isLoading"
                                                    @click="activateDiscountCode"
                                                    type="button"
                                                    class="ml-3 inline-flex rounded-md focus:outline-none inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 text-green-500 transition duration-150 ease-in-out focus:border-green-700 focus:shadow-outline-green active:bg-green-100"
                                                >
                                                    Activate
                                                </button>
                                                <button
                                                    v-else
                                                    :disabled="isLoading"
                                                    @click="confirmModalArchiveDiscountCode"
                                                    type="button"
                                                    class="ml-3 inline-flex rounded-md focus:outline-none inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 text-red-500 transition duration-150 ease-in-out focus:border-red-700 focus:shadow-outline-red active:bg-red-100"
                                                >
                                                    Deactivate
                                                </button>
                                        </div>
                                        <span
                                            class="mr-3 sm:mr-0 sm:ml-3 inline-flex rounded-md shadow-sm"
                                        >
                                            <button
                                                :disabled="isLoading"
                                                type="submit"
                                                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                            >
                                                Save Discount
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>

                      <div
                          v-if="code.id"
                          class="max-w-2xl mx-auto pt-8">
                        <comment-section
                            :comments="comments"
                            :discount-id="code.id"
                            @fetch-comments="fetchComments"
                        />

                      </div>
                    </div>

                    <div
                        v-if="isEdit"
                        style="height: max-content; top: 20px"
                        class="top-0 col-span-6 h-auto rounded-md bg-white opacity-90 shadow md:sticky md:col-span-2"
                    >
                        <div class="mb-1 p-4">
                            <span
                                class="block text-sm font-heading font-medium leading-5 text-gray-700"
                            >
                                Performance
                            </span>
                            <div class="mt-3">
                                <ul class="list-inside list-disc">
                                    <li class="text-sm text-gray-700">
                                        {{ code.usageCount || 0 }} used
                                    </li>
                                    <li class="text-sm text-gray-700">
                                        {{ (code.usageSum / 100) | currency }}
                                        redeemed
                                    </li>
                                    <br />
                                    <li class="text-sm text-gray-700">
                                        {{
                                            (parseFloat(code.orderTotal) / 100)
                                                | currency
                                        }}
                                        total order amount
                                    </li>
                                    <li class="text-sm text-gray-700">
                                        {{
                                            (parseFloat(
                                                code.averageOrderTotal
                                            ) /
                                                100)
                                                | currency
                                        }}
                                        average order size
                                    </li>
                                </ul>
                                <br />
                                <router-link
                                    :to="{
                                        name: 'discountedOrdersReport',
                                        query: { code: code.code }
                                    }"
                                    class="text-sm font-medium text-indigo-500 underline"
                                    >
                                    View orders by discount report
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <toggle-confirm-modal
            @confirm="archiveDiscountCode()"
            :options="{
            title: 'Confirm deactivation',
            description: 'To confirm deactivation of the discount code, please click the confirm button shown below.'
        }"
        />
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import { VclList } from 'vue-content-loading';
import {
    alphaNum,
    integer,
    minLength,
    required,
    minValue
} from 'vuelidate/lib/validators';
import ToggleSidebar from '@/components/common/ToggleSidebar';
import { DiscountTypeEnum, FulfillmentMethodsEnum, FeaturesEnum } from '@/enums';
import MultiselectWithSelectAll from '@/components/selectors/MultiselectWithSelectAll';
import DiscountValiditySelector from '@/components/widgets/DiscountValiditySelector';
import { mapActions, mapGetters } from 'vuex';
import CommentSection from '@/components/comments/CommentSection';
import ToggleConfirmModal from '@/components/modals/ToggleConfirmModal';
import WeekDayPicker from '@/components/WeekDayPicker';
import FulfillmentMethodPicker from "@/components/FulfillmentMethodPicker.vue";

export default {
    name: 'DiscountCode',
    metaInfo: {
        title: 'Create a discount'
    },
    components: {
        FulfillmentMethodPicker,
        WeekDayPicker,
        ToggleConfirmModal,
        MultiselectWithSelectAll,
        VueTimepicker,
        ToggleSidebar,
        VclList,
        DiscountValiditySelector,
        CommentSection
    },
    data() {
        const DEFAULT_START_TIME = '17:00';
        const DEFAULT_END_TIME = '18:00';
        const DAYS_DEFAULT = [0, 1, 2, 3, 4, 5, 6];
        const FULFILLMENT_METHODS = ['Delivery', 'Pickup', 'InStore'];

        return {
            isEdit: false,
            DEFAULT_START_TIME,
            DEFAULT_END_TIME,
            comments: [],
            code: {
                type: null,
                code: '',
                autoApply: false,
                description: '',
                codeAmount: 0,
                hasMinimum: false,
                hasUsageLimit: false,
                hasUsageLimitPerUser: false,
                minimumAmount: 0,
                excludeDelivery: false,
                days: DAYS_DEFAULT,
                startTime: null,
                endTime: null,
                usageLimit: 0,
                usageLimitPerUser: 0,
                usageCount: 0,
                usageSum: 0,
                venues: [],
                fulfillmentMethods: FULFILLMENT_METHODS,
                hasMaxDiscountAmount: false,
                maxDiscountAmount: 0
            },
            allDay: true,
            fulfillmentMethods: FULFILLMENT_METHODS,
            FulfillmentMethodsEnum,
            isLoading: false,
            enableStartDate: false,
            enableEndDate: false,
            startDateRange: {
                startDate: this.$moment(new Date().setHours(0, 0, 0, 0)),
                endDate: this.$moment(new Date().setHours(0, 0, 0, 0))
            },
            endDateRange: {
                startDate: this.$moment(new Date().setHours(0, 0, 0, 0)).add(
                    1,
                    'months'
                ),
                endDate: this.$moment(new Date().setHours(0, 0, 0, 0)).add(
                    1,
                    'months'
                )
            },
            DiscountTypeEnum,
            FeaturesEnum,
            FULFILLMENT_METHODS
        };
    },
    validations: {
        code: {
            code: {
                required,
                alphaNum,
                minLength: minLength(3)
            },
            venues: {
                required
            },
            type: {
                required,
                shouldBe: value =>
                    Object.values(DiscountTypeEnum.options).includes(value)
            },
            codeAmount: {
                required,
                minValue: minValue(0),
                integer
            },
            minimumAmount: {
                required,
                integer
            },
            usageLimit: {
                required,
                integer
            },
            usageLimitPerUser: {
                required,
                integer
            },
            maxDiscountAmount: {
                required,
                integer
            },
            days: {
                handler(value) {
                    return value.length > 0;
                }
            },
            startTime: {
                handler(value) {
                    return this.allDay || value.length === 5;
                }
            },
            endTime: {
                handler(value) {
                    return this.allDay || value.length === 5;
                }
            },
            fulfillmentMethods: {
                handler(value) {
                    return value.length > 0;
                }
            }
        }
    },
    async created() {
        this.isLoading = true;

        await Promise.all([
            !this.availableVenues?.length > 0 ? this.fetchVenues() : null,
            !this.archivedVenues?.length > 0
                ? this.fetchVenues({ archived: true })
                : null
        ]);

        if (this.$route.name === 'editDiscount') {
            this.isEdit = true;
            await this.fetchDiscountCode();
        }

        if (this.$route.name !== 'editDiscount' && !this.code.type) {
            this.code.type = DiscountTypeEnum.PERCENT;
        }

        this.isLoading = false;

        await this.fetchComments();
    },
    watch: {
        'code.type'() {
            if (this.code.type === DiscountTypeEnum.FREE_DELIVERY) {
                this.code.fulfillmentMethods = [this.FULFILLMENT_METHODS[0]];
                this.fulfillmentMethods = [this.FULFILLMENT_METHODS[0]];
            } else {
                this.fulfillmentMethods = this.FULFILLMENT_METHODS;
            }
        }
    },
    computed: {
        ...mapGetters({
            availableVenues: 'venues/getVenues',
            venuesArchived: 'venues/getArchivedVenues',
            currency: 'user/currencySettings',
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        codeAmountInputProps() {
            const isPercentage = this.code.type === DiscountTypeEnum.PERCENT;

            return {
                class: {
                    'border-red-300': this.$v.code.codeAmount.$error
                },
                currency: null,
                precision: isPercentage ? 1 : 2,
                valueRange: isPercentage
                    ? {
                          min: 0,
                          max: 100
                      }
                    : {},
                valueAsInteger: true,
                allowNegative: false,
                locale: 'en-GB',
                placeholder: '0'
            };
        },
        venues() {
            return [...this.availableVenues, ...this.venuesArchived];
        }
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        onChangeInSelectedStores(value) {
            this.code.venues = value;
        },
        async fetchDiscountCode() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/discount-codes/${this.$route.params.id}`
                );

                this.enableStartDate = !!data.startDate;
                this.enableEndDate = !!data.endDate;

                if (this.enableStartDate) {
                    this.startDateRange = {
                        startDate: data.startDate,
                        endDate: data.startDate
                    };
                }

                if (this.enableEndDate) {
                    this.endDateRange = {
                        startDate: data.endDate,
                        endDate: data.endDate
                    };
                }

                this.code = {
                    id: data.id,
                    type: data.type,
                    code: data.code,
                    autoApply: data.autoApply,
                    description: data.description,
                    codeAmount: data.codeAmount,
                    hasMinimum: data.minimumAmount !== 0,
                    hasUsageLimit: data.usageLimit !== 0,
                    hasUsageLimitPerUser: data.usageLimitPerUser !== 0,
                    minimumAmount: data.minimumAmount,
                    excludeDelivery: data.excludeDelivery,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    days: data.days,
                    usageLimit: data.usageLimit,
                    usageLimitPerUser: data.usageLimitPerUser,
                    usageSum: data.usageSum,
                    usageCount: data.usageCount,
                    averageOrderTotal: data.averageOrderTotal,
                    orderTotal: data.orderTotal,
                    venues: this.venues.filter(venue =>
                        data.venues.find(
                            venueWithCode => venue.id === venueWithCode.id
                        )
                    ),
                    fulfillmentMethods: data.fulfillmentMethods,
                    startDate: data.endDate,
                    endDate: data.endDate,
                    archived: data.archived,
                    maxDiscountAmount: data.maxDiscountAmount,
                    hasMaxDiscountAmount: data.maxDiscountAmount !== 0
                };

                this.allDay = !this.code.startTime && !this.code.endTime;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        generateRandomCode() {
            const randomCode = Math.random()
                .toString(36)
                .substring(2, 12)
                .toUpperCase();

            this.code.code = randomCode;
        },
        async createDiscountCode() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    const el = this.$el.querySelector('.invalid-form-field');
                    el.scrollIntoView({ behavior: 'smooth' });
                });

                return false;
            }

            const code = {
                type: this.code.type,
                code: this.code.code,
                autoApply: this.code.autoApply,
                codeAmount: this.code.codeAmount,
                excludeDelivery: this.code.excludeDelivery,
                days: this.code.days,
                startTime: this.code.startTime,
                endTime: this.code.endTime,
                description: this.code.description,
                minimumAmount: this.code.hasMinimum
                    ? this.code.minimumAmount
                    : 0,
                usageLimit: this.code.hasUsageLimit ? this.code.usageLimit : 0,
                usageLimitPerUser: this.code.hasUsageLimitPerUser
                    ? this.code.usageLimitPerUser
                    : 0,
                venues: this.code.venues.map(venue => {
                    return {
                        id: venue.id,
                        enabled: true
                    };
                }),
                fulfillmentMethods: this.code.fulfillmentMethods,
                startDate: this.enableStartDate
                    ? this.startDateRange.startDate
                    : null,
                endDate: this.enableEndDate ? this.endDateRange.endDate : null,
                maxDiscountAmount: this.code.hasMaxDiscountAmount
                    ? this.code.maxDiscountAmount
                    : 0
            };

            this.isLoading = true;

            try {
                await this.$axios.post('/discount-codes', code);

                this.$router.push({ name: 'discounts' });
                this.$notify({
                    group: 'settings',
                    title: 'Discount code created!'
                });
            } catch (error) {
                if (error.response?.status === 409) {
                    window.alert(
                        'A discount with this code already exists, please try a different code'
                    );
                } else {
                    throw new Error(`API ${error}`);
                }
            } finally {
                this.isLoading = false;
            }
        },
        async editDiscountCode() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            const code = {
                id: this.code.id,
                type: this.code.type,
                autoApply: this.code.autoApply,
                codeAmount: this.code.codeAmount,
                excludeDelivery: this.code.excludeDelivery,
                days: this.code.days,
                startTime: this.code.startTime,
                endTime: this.code.endTime,
                description: this.code.description,
                minimumAmount: this.code.hasMinimum
                    ? this.code.minimumAmount
                    : 0,
                usageLimit: this.code.hasUsageLimit ? this.code.usageLimit : 0,
                usageLimitPerUser: this.code.hasUsageLimitPerUser
                    ? this.code.usageLimitPerUser
                    : 0,
                venues: this.code.venues.map(venue => {
                    return {
                        id: venue.id,
                        enabled: true
                    };
                }),
                fulfillmentMethods: this.code.fulfillmentMethods,
                startDate: this.enableStartDate
                    ? this.startDateRange.startDate
                    : null,
                endDate: this.enableEndDate ? this.endDateRange.endDate : null,
                maxDiscountAmount: this.code.hasMaxDiscountAmount
                    ? this.code.maxDiscountAmount
                    : 0
            };

            this.isLoading = true;

            try {
                await this.$axios.patch(`/discount-codes/${code.id}`, code);

                await this.fetchDiscountCode();

                this.$notify({
                    group: 'settings',
                    title: 'Discount code updated'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async archiveDiscountCode() {
            this.isLoading = true;

            try {
                await this.$axios.delete(`/discount-codes/${this.code.id}`);

                this.$router.push({ name: 'discounts' });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async activateDiscountCode() {
            this.isLoading = true;

            try {
                await this.$axios.patch(`/discount-codes/${this.code.id}`, { id: this.code.id, archived: false });

                await this.fetchDiscountCode();

                this.$notify({
                    group: 'settings',
                    title: 'Discount code activated'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        confirmModalArchiveDiscountCode() {
            this.$modal.show('toggle-confirm-modal');
        },
        toggleAllDay() {
            this.allDay = !this.allDay;

            if (this.allDay) {
                this.code.startTime = null;
                this.code.endTime = null;
            } else {
                this.code.startTime = this.DEFAULT_START_TIME;
                this.code.endTime = this.DEFAULT_END_TIME;
            }
        },

        async fetchComments() {
          if (!this.code.id) {
            return;
          }
          try {
            const { data } = await this.$axios.get(`/comments`, {
              params: {
                discountCodeId: this.code.id
              }
            });

            this.comments = data;
          } catch (error) {
            throw new Error(`API ${error}`);
          }
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/assets/css/components/create-edit-discount-code.css"></style>
