<template>
    <tr v-if="product" class="bg-white">
        <td
            :class="{ 'draggable-product': !isMobile }"
            class="max-w-xs cursor-move whitespace-nowrap border-b border-gray-200 py-1.5 pl-1 pr-3 sm:pl-6 sm:pr-6"
        >
            <div class="flex max-w-max items-center">
                <div class="h-8 w-8 flex-shrink-0 sm:h-10 sm:w-10">
                    <img
                        v-if="product.image"
                        class="h-8 w-8 rounded-lg shadow-inner sm:h-10 sm:w-10"
                        :class="{ 'bg-gray-400': !product.image }"
                        :src="
                            product.image +
                            '-/scale_crop/80x80/smart/-/format/auto/'
                        "
                        :alt="product.name"
                    />
                    <div
                        v-else
                        class="h-8 w-8 rounded-lg bg-gray-50 shadow-inner sm:h-10 sm:w-10"
                    />
                </div>
                <div class="ml-2 sm:ml-4">
                    <div
                        class="max-w-3xs sm:min-w-3xs lg:max-w-2xs lg:min-w-2xs truncate text-sm font-medium leading-5 text-gray-700 xl:max-w-xs xl:max-w-xs"
                    >
                        {{ product.name }}
                    </div>
                    <div
                        class="max-w-xs truncate text-xs leading-5 text-gray-500 sm:hidden"
                    >
                        {{ (product.price / 100) | currency }} •
                        {{
                            product.modifierGroups.length
                                | pluralize('group', 'groups')
                        }}
                    </div>
                </div>
            </div>
        </td>
        <td
            :class="{ 'draggable-product': !isMobile }"
            class="hidden cursor-move whitespace-nowrap border-b border-gray-200 px-6 py-2 sm:table-cell"
        >
            <div class="max-w-xs truncate text-sm leading-5 text-gray-500">
                {{
                    product.modifierGroups.length | pluralize('group', 'groups')
                }}
            </div>
        </td>
        <td
            class="hidden whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500 sm:table-cell"
        >
            <div class="flex w-full flex-shrink-0 items-center">
                <star-icon
                    class="cursor-pointer"
                    :color="product.popular ? '#FFD700' : '#D1D5DB'"
                    @click.native="togglePopular"
                />
                <div
                    class="ml-4 h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isPopularLoading }"
                >
                    <span></span>
                </div>
            </div>
        </td>
        <td
            class="whitespace-nowrap border-b border-gray-200 px-3 py-2 sm:px-6"
        >
            <div v-if="isDeliverectManaged">
                <base-eye-hidden-outline-icon
                    v-if="product.snooze"
                    v-tooltip="
                        `Snoozed in deliverect until ${this.$moment(
                            product.snooze.end
                        ).format('HH:mm DD-MM-YYYY')}`
                    "
                    class="outline-none h-6 w-6 text-gray-300"
                />
                <base-eye-outline-icon
                    v-else
                    v-tooltip="'To disable this item, snooze it in deliverect'"
                    class="outline-none h-6 w-6 text-gray-400"
                />
            </div>
            <div v-if="product.snooze"
                 @click="unSnoozeItem()"
                 class="cursor-pointer"
            >
<!--              <span class="text-xs">-->
<!--              Unsnoozing-->
<!--              {{ product.snooze.end | moment('from', 'now') }}-->
<!--              </span>-->
              <div class="flex hover:bg-gray-100 rounded-md px-1 py-0.5" v-tooltip="
                            `Snoozed until ${this.$moment(
                                product.snooze.end
                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`
                        ">
                <span class="text-xs text-gray-700">Unsnooze</span>
                <base-snooze-icon
                    v-tooltip="
                            `Snoozed until ${this.$moment(
                                product.snooze.end
                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`
                        "
                    class="outline-none h-4 w-4 text-gray-700 ml-1 cursor-pointer"
                />

              </div>
            </div>
            <div v-else>
                <div
                    class="flex w-full flex-shrink-0 items-center"
                    v-if="!product.trackInventory"
                >
                    <base-small-toggle-switch
                        :value="isAvailable"
                        @input="toggle()"
                    />
                    <div
                        class="ml-4 h-4 w-4"
                        :class="{ 'text-gray-900 spinner': isLoading }"
                    >
                        <span></span>
                    </div>
                </div>
                <div class="flex w-full flex-shrink-0" v-else>
                    <div class="flex flex-row">
                        <click-to-edit
                            class="cursor-pointer text-sm text-gray-600 font-mono"
                            style="width: 60px"
                            :value="product.inventory"
                            :label="'quantity'"
                            v-on:input="updateInventory"
                        />
                        <div
                            class="ml-4 h-4 w-4"
                            :class="{
                                'text-gray-900 spinner': inventoryLoading
                            }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td
            class="hidden whitespace-nowrap font-mono  border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500 sm:table-cell"
        >
            {{ (product.price / 100) | currency }}
        </td>

        <td
            class="cursor-pointer whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm font-medium leading-5"
        >
            <span
                @click="$emit('showEditProductSidebar', product)"
                class="focus:outline-none text-indigo-600 hover:text-indigo-900 focus:underline"
            >
                Edit
            </span>
        </td>
    </tr>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ClickToEdit from '../helpers/ClickToEdit';
import StarIcon from '@/components/common/StarIcon';
import BaseEyeOutlineIcon from '@/components/base/icons/BaseEyeOutlineIcon';

export default {
    name: 'MenuCategoryProduct',
    components: {
        BaseEyeOutlineIcon,
        ClickToEdit,
        StarIcon
    },
    props: ['product', 'isDeliverectManaged'],
    data() {
        return {
            isLoading: false,
            isPopularLoading: false,
            isAvailable: null,
            inventoryLoading: false
        };
    },
    created() {
        this.isAvailable = this.product.isAvailable;
    },
    methods: {
        async toggle() {

                if (this.product.snooze) {
                  try {
                      this.isLoading = true;
                      await this.$axios.delete(`/products/snooze`, {
                          id: this.product.id,
                      });
                      return;
                  } catch (error) {
                      throw new Error(`API ${error}`);
                  }
                  finally {
                    this.isLoading = false;
                  }
                }

                if (this.isAvailable) {
                    this.$modal.show('snooze-item-availability-modal', {
                        product: this.product
                    });
                }

                if (!this.isAvailable) {
                  try {
                      await this.$axios.put(`/products/${this.product.id}`, {
                          isAvailable: true
                      });

                      this.isAvailable = true;
                      this.$notify({
                          group: 'settings',
                          title: 'Availability updated!'
                      });
                  } catch (error) {
                      throw new Error(`API ${error}`);
                  } finally {
                      this.isLoading = false;
                  }
                }
        },
        async updateInventory(val) {
            const newValue = parseInt(val);

            if (
                this.inventoryLoading !== true &&
                newValue !== this.product.inventory
            ) {
                try {
                    this.inventoryLoading = true;

                    await this.$axios.put(`/products/${this.product.id}`, {
                        inventory: newValue,
                        isAvailable: true
                    });

                    this.product.inventory = newValue;
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.inventoryLoading = false;
                }
            }
        },
        async togglePopular() {
            const value = !this.product.popular;
            this.isPopularLoading = true;

            try {
                await this.$axios.put(`/products/${this.product.id}`, {
                    popular: value
                });

                this.product.popular = value;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isPopularLoading = false;
            }
        },
        async unSnoozeItem() {
            try {
              this.isLoading = true;
                await this.$axios.delete(`/products/${this.product.id}/snooze`);

                this.product.snooze = null;

                this.$notify({
                  group: 'settings',
                  type: 'success',
                  duration: 2000,
                  text: 'Product un-snoozed!'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
            finally {
              this.isLoading = false;
            }
        }
    },
    computed: {
        isMobile() {
            return isMobile;
        }
    }
};
</script>
