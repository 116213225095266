var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('tr',{staticClass:"bg-white"},[_c('td',{staticClass:"max-w-xs cursor-move whitespace-nowrap border-b border-gray-200 py-1.5 pl-1 pr-3 sm:pl-6 sm:pr-6",class:{ 'draggable-product': !_vm.isMobile }},[_c('div',{staticClass:"flex max-w-max items-center"},[_c('div',{staticClass:"h-8 w-8 flex-shrink-0 sm:h-10 sm:w-10"},[(_vm.product.image)?_c('img',{staticClass:"h-8 w-8 rounded-lg shadow-inner sm:h-10 sm:w-10",class:{ 'bg-gray-400': !_vm.product.image },attrs:{"src":_vm.product.image +
                            '-/scale_crop/80x80/smart/-/format/auto/',"alt":_vm.product.name}}):_c('div',{staticClass:"h-8 w-8 rounded-lg bg-gray-50 shadow-inner sm:h-10 sm:w-10"})]),_c('div',{staticClass:"ml-2 sm:ml-4"},[_c('div',{staticClass:"max-w-3xs sm:min-w-3xs lg:max-w-2xs lg:min-w-2xs truncate text-sm font-medium leading-5 text-gray-700 xl:max-w-xs xl:max-w-xs"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div',{staticClass:"max-w-xs truncate text-xs leading-5 text-gray-500 sm:hidden"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.product.price / 100)))+" • "+_vm._s(_vm._f("pluralize")(_vm.product.modifierGroups.length,'group', 'groups'))+" ")])])])]),_c('td',{staticClass:"hidden cursor-move whitespace-nowrap border-b border-gray-200 px-6 py-2 sm:table-cell",class:{ 'draggable-product': !_vm.isMobile }},[_c('div',{staticClass:"max-w-xs truncate text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("pluralize")(_vm.product.modifierGroups.length,'group', 'groups'))+" ")])]),_c('td',{staticClass:"hidden whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500 sm:table-cell"},[_c('div',{staticClass:"flex w-full flex-shrink-0 items-center"},[_c('star-icon',{staticClass:"cursor-pointer",attrs:{"color":_vm.product.popular ? '#FFD700' : '#D1D5DB'},nativeOn:{"click":function($event){return _vm.togglePopular.apply(null, arguments)}}}),_c('div',{staticClass:"ml-4 h-4 w-4",class:{ 'text-gray-900 spinner': _vm.isPopularLoading }},[_c('span')])],1)]),_c('td',{staticClass:"whitespace-nowrap border-b border-gray-200 px-3 py-2 sm:px-6"},[(_vm.isDeliverectManaged)?_c('div',[(_vm.product.snooze)?_c('base-eye-hidden-outline-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        `Snoozed in deliverect until ${this.$moment(
                            _vm.product.snooze.end
                        ).format('HH:mm DD-MM-YYYY')}`
                    ),expression:"\n                        `Snoozed in deliverect until ${this.$moment(\n                            product.snooze.end\n                        ).format('HH:mm DD-MM-YYYY')}`\n                    "}],staticClass:"outline-none h-6 w-6 text-gray-300"}):_c('base-eye-outline-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('To disable this item, snooze it in deliverect'),expression:"'To disable this item, snooze it in deliverect'"}],staticClass:"outline-none h-6 w-6 text-gray-400"})],1):_vm._e(),(_vm.product.snooze)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.unSnoozeItem()}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            `Snoozed until ${this.$moment(
                                _vm.product.snooze.end
                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`
                        ),expression:"\n                            `Snoozed until ${this.$moment(\n                                product.snooze.end\n                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`\n                        "}],staticClass:"flex hover:bg-gray-100 rounded-md px-1 py-0.5"},[_c('span',{staticClass:"text-xs text-gray-700"},[_vm._v("Unsnooze")]),_c('base-snooze-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            `Snoozed until ${this.$moment(
                                _vm.product.snooze.end
                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`
                        ),expression:"\n                            `Snoozed until ${this.$moment(\n                                product.snooze.end\n                            ).format('HH:mm DD-MM-YYYY')}. Click to remove snooze`\n                        "}],staticClass:"outline-none h-4 w-4 text-gray-700 ml-1 cursor-pointer"})],1)]):_c('div',[(!_vm.product.trackInventory)?_c('div',{staticClass:"flex w-full flex-shrink-0 items-center"},[_c('base-small-toggle-switch',{attrs:{"value":_vm.isAvailable},on:{"input":function($event){return _vm.toggle()}}}),_c('div',{staticClass:"ml-4 h-4 w-4",class:{ 'text-gray-900 spinner': _vm.isLoading }},[_c('span')])],1):_c('div',{staticClass:"flex w-full flex-shrink-0"},[_c('div',{staticClass:"flex flex-row"},[_c('click-to-edit',{staticClass:"cursor-pointer text-sm text-gray-600 font-mono",staticStyle:{"width":"60px"},attrs:{"value":_vm.product.inventory,"label":'quantity'},on:{"input":_vm.updateInventory}}),_c('div',{staticClass:"ml-4 h-4 w-4",class:{
                                'text-gray-900 spinner': _vm.inventoryLoading
                            }},[_c('span')])],1)])])]),_c('td',{staticClass:"hidden whitespace-nowrap font-mono border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500 sm:table-cell"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.product.price / 100)))+" ")]),_c('td',{staticClass:"cursor-pointer whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm font-medium leading-5"},[_c('span',{staticClass:"focus:outline-none text-indigo-600 hover:text-indigo-900 focus:underline",on:{"click":function($event){return _vm.$emit('showEditProductSidebar', _vm.product)}}},[_vm._v(" Edit ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }