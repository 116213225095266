<template>
    <div
        class="z-10 flex h-14 w-full items-center justify-between bg-white px-4 py-2 shadow-sm"
    >
        <base-page-title class="hidden sm:block" title="Store Designer" />
      <div
         class="flex w-full items-center justify-between sm:w-auto sm:justify-start"
      >
        <slot name="left"></slot>
        <div
            class="mr-4 flex flex-grow-0 items-center justify-center gap-1 rounded-md bg-gray-200 py-0.5 px-1"
        >
          <label
              v-for="device in devices"
              :key="device.value"
              class="inline-flex items-center gap-2 rounded p-1.5"
              v-tooltip="device.text"
              :class="{
                    'cursor-default bg-white shadow-sm': value === device.value,
                    'cursor-pointer hover:bg-gray-100': value !== device.value
                }"
          >
            <input
                v-model="computedValue"
                :value="device.value"
                type="radio"
                class="hidden"
            />
            <component :is="device.icon" class="h-4 w-4" />
          </label>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
</template>

<script>
import MobileIcon from '@/components/storeDesigner/MobileIcon';
import TabletIcon from '@/components/storeDesigner/TabletIcon';
import DesktopIcon from '@/components/storeDesigner/DesktopIcon';

export default {
    name: 'DesignerHeader',
    components: {
        MobileIcon,
        TabletIcon,
        DesktopIcon
    },
    props: {
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            devices: [
                {
                    text: 'Desktop',
                    value: 'desktop',
                    icon: DesktopIcon
                },
                {
                    text: 'Tablet',
                    value: 'tablet',
                    icon: TabletIcon
                },
                {
                    text: 'Mobile',
                    value: 'mobile',
                    icon: MobileIcon
                }
            ]
        };
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
