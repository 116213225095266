<template>
    <modal
        name="snooze-venue-modal"
        width="600px"
        height="auto"
        class="snooze-item-availability-modal overflow-visible"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform rounded-lg bg-white p-6 shadow-xl transition-all sm:w-full"
        >
            <div class="text-left" v-if="!isSnoozed">
                <h3
                    class="font-heading text-lg font-medium leading-6 text-gray-900"
                >
                    Disable orders for:
                </h3>
                <div class="mt-4 flex items-center justify-between">
                    <div class="flex w-full items-center">
                        <label
                            v-for="(option, key) in options"
                            @click="selectOption(option.value)"
                            :key="key"
                            class="focus:outline-none mr-2 flex cursor-pointer items-center justify-center rounded-md border px-3 py-2 text-sm font-medium transition-colors hover:border-indigo-600 sm:flex-1"
                            :class="{
                                'cursor-not-allowed opacity-25': !option.active,
                                'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white':
                                    optionSelected === option.value,
                                'hover:text-indigo-600':
                                    optionSelected !== option.value
                            }"
                        >
                            <span id="size-choice-0-label">
                                {{ option.label }}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="mt-4 flex items-center sm:flex-1">
                    <span class="mr-2 min-w-max text-gray-600"> or until </span>
                    <DateRangeSelector
                        @date-updated="selectPicker"
                        :styles="pickerStyles"
                        aligned="left"
                        :singleDate="true"
                        :allowFuture="true"
                        :disallowPast="true"
                        :minDate="minSnoozeDate"
                        :maxDate="maxSnoozeDate"
                    />
                </div>
                <div class="mt-4 w-full sm:w-1/2">
                    <label
                        for="reason"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Select a reason for disabling orders
                    </label>
                    <div >
                        <select
                            v-model.trim="snoozeReasonOption"
                            id="refundReason"
                            :class="errors && !snoozeReasonOption ? 'border-red-300': 'border-gray-300'"
                            class="rounded-md shadow-sm focus:outline-none mt-1 block w-full rounded-md border px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        >
                            <option :value="null"> Select an option </option>
                            <option
                                v-for="(
                                    value, key
                                ) of VenueSnoozeReasonsEnum.dictionary"
                                :key="key"
                                :value="key"
                            >
                                {{ value }}
                            </option>
                        </select>
                        <div class="mt-1" v-if="errors && !snoozeReasonOption">
                          <span class="py-2 text-xs text-red-600"
                          >Please select a reason for snoozing orders</span
                          >
                        </div>

                        <div
                            v-if="
                                snoozeReasonOption ===
                                VenueSnoozeReasonsEnum.OTHER
                            "
                        >
                            <label
                                for="otherReason"
                                class="mt-4 block text-sm font-medium leading-5 text-gray-700"
                            >
                                Add reason for disabling orders
                            </label>

                            <textarea
                                v-model="snoozeReason"
                                id="otherReason"
                                rows="1"
                                class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                placeholder=""
                            ></textarea>
                        </div>

                        <div
                            v-if="
                                snoozeReasonOption ===
                                VenueSnoozeReasonsEnum.TECH_ISSUE
                            "
                        >
                            <label
                                for="otherReason"
                                class="mt-4 block text-sm font-medium leading-5 text-gray-700"
                            >
                                What's the tech issue you are experiencing?
                            </label>

                            <textarea
                                v-model="techIssueReason"
                                id="techIssue"
                                rows="1"
                                class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                placeholder=""
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="text-left">
                    <h3
                        class="font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        Remove snooze
                    </h3>
                    <p class="mt-2">
                        Currently snoozed for
                        {{ disabledUntil | moment('from', 'now', true) }}</p
                    >
                </div>
            </div>
            <div
                class="mt-5 flex-row-reverse justify-between sm:mt-8 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm md:w-36">
                    <button
                        v-if="!isSnoozed"
                        @click="snooze"
                        type="button"
                        :disabled="(!optionSelected && !dateSelected)"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        :class="{
                            'cursor-not-allowed opacity-50':
                                (!optionSelected && !dateSelected) || !snoozeReasonOption
                        }"
                    >
                        Disable orders
                    </button>
                    <button
                        v-else
                        @click="unSnooze"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Enable orders
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('snooze-venue-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
import { RefundReasonsEnum, VenueSnoozeReasonsEnum } from '@/enums';
import { mapActions } from 'vuex';
export default {
    name: 'SnoozeVenueModal',
    components: { DateRangeSelector },
    data() {
        return {
            isLoading: false,
            options: [
                { label: '5 mins', value: 5, active: true },
                { label: '10 mins', value: 10, active: true },
                { label: '30 mins', value: 30, active: true },
                { label: '1 hr', value: 60, active: true },
                { label: '2 hr', value: 120, active: true },
                { label: '12 hr', value: 720, active: true }
                // { label: 'Indefinitely', value: 1051200, active: true }
            ],
            pickerStyles: 'min-w-max rounded-md transition-color font-medium',
            dateSelected: null,
            optionSelected: null,
            venueId: null,
            disabledUntil: null,
            snoozeReasonOption: null,
            snoozeReason: '',
            techIssueReason: '',
            errors: null,
            VenueSnoozeReasonsEnum
        };
    },
    watch: {
        dateSelected(value) {
            this.pickerStyles =
                'min-w-max rounded-md transition-color font-medium ' +
                (value
                    ? 'bg-indigo-600 text-white hover:text-white border-indigo-600'
                    : 'hover:border-indigo-600 hover:text-indigo-600');
        }
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        beforeOpen({ params }) {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.disabledUntil = params.disabledUntil;
            this.venueId = params.venueId;
        },
        selectPicker({ endDate }) {
            this.dateSelected = endDate;
            this.optionSelected = null;
        },
        selectOption(option) {
            this.optionSelected = option;
            this.dateSelected = null;
        },
        async snooze() {
            let disabledForInMinutes = 0;
            if (!this.snoozeReasonOption) {
              this.errors = true;
              return;
            }

            if (this.dateSelected) {
                const rightNow = this.$moment();
                const endDateMoment = this.$moment(this.dateSelected);
                endDateMoment.set({ hour: 5, minute: 0, second: 0 });
                disabledForInMinutes = endDateMoment.diff(rightNow, 'minutes');
            } else {
                disabledForInMinutes = this.optionSelected;
            }

            if (!disabledForInMinutes) {
                this.errors = true;
                return;
            }

            const payload = {
                id: this.venueId,
                disabledForInMinutes,
                reason:
                    this.snoozeReasonOption &&
                    this.snoozeReasonOption !== RefundReasonsEnum.OTHER
                        ? this.snoozeReasonOption
                        : this.snoozeReason
            };

            if (this.techIssueReason) {
                payload.reason += ` ${this.techIssueReason}`;
            }

            try {
                this.isLoading = true;

                await this.updateVenueSettings(payload);

                this.$emit('venue-snoozed');
                this.$modal.hide('snooze-venue-modal');

                this.$notify({
                  group: 'settings',
                  duration: 2000,
                  text: `Store snoozed`,
                });

            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async unSnooze() {
            let disabledForInMinutes = 0;

            const payload = {
                id: this.venueId,
                disabledForInMinutes,
                enabled: true
            };

            try {
                this.isLoading = true;
                await this.updateVenueSettings(payload);

                this.$emit('venue-snoozed');
                this.$modal.hide('snooze-venue-modal');

                this.$notify({
                  group: 'settings',
                  duration: 2000,
                  text: `Store unsnoozed`,
                });

            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        isSnoozed() {
            const until = this.$moment(this.disabledUntil);

            return until.isAfter();
        },
        minSnoozeDate() {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);

            return tomorrow.toString();
        },
        maxSnoozeDate() {
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + 21);

            return maxDate.toString();
        }
    }
};
</script>

<style>
.snooze-venue-modal .vm--modal {
    overflow: visible;
}
</style>
