<template>
    <nav class="mt-4 flex-1 bg-sk-black px-2">
        <router-link
            exact
            :to="{ name: 'dashboard' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
            </svg>
            Dashboard
        </router-link>
        <router-link
            v-if="
                isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                isFeatureAvailable(FeaturesEnum.PAT)
            "
            exact
            :to="{ name: 'tables' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                    clip-rule="evenodd"
                ></path>
            </svg>

            Tables
        </router-link>
        <span
            @click="showOrdersSubMenuAndNavigate"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex cursor-pointer rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                    clip-rule="evenodd"
                ></path>
            </svg>
            Orders
            <div
                class="ml-auto pl-4"
                @click.stop="showOrdersSubMenu = !showOrdersSubMenu"
            >
                <base-chevron-icon
                    :class="{ '-rotate-180': showOrdersSubMenu }"
                />
            </div>
        </span>
        <div v-auto-animate>
            <router-link
                v-if="showOrdersSubMenu"
                :to="{ name: 'orders' }"
                active-class="text-white bg-gray-700"
                class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
                All Orders
            </router-link>
            <router-link
                v-if="showOrdersSubMenu"
                :to="{ name: 'liveOrders' }"
                class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
                Live Orders
            </router-link>
            <router-link
                v-if="showOrdersSubMenu && isFeatureAvailable(FeaturesEnum.INTERNAL)"
                :to="{ name: 'preOrderPrep' }"
                class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
              Pre Orders
            </router-link>
        </div>
        <router-link
            :to="{ name: 'stores' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                ></path>
            </svg>
            Stores
        </router-link>
        <router-link
            :to="{ name: 'menus' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                ></path>
            </svg>

            Menus
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'discounts' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"
                ></path>
            </svg>
            Discounts
        </router-link>
        <router-link
            v-if="isFeatureAvailable(FeaturesEnum.SAAS) || isFeatureAvailable(FeaturesEnum.INTERNAL)"
            :to="{ name: 'offers' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
          <svg class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path d="M16.881 4.345A23.112 23.112 0 0 1 8.25 6H7.5a5.25 5.25 0 0 0-.88 10.427 21.593 21.593 0 0 0 1.378 3.94c.464 1.004 1.674 1.32 2.582.796l.657-.379c.88-.508 1.165-1.593.772-2.468a17.116 17.116 0 0 1-.628-1.607c1.918.258 3.76.75 5.5 1.446A21.727 21.727 0 0 0 18 11.25c0-2.414-.393-4.735-1.119-6.905ZM18.26 3.74a23.22 23.22 0 0 1 1.24 7.51 23.22 23.22 0 0 1-1.41 7.992.75.75 0 1 0 1.409.516 24.555 24.555 0 0 0 1.415-6.43 2.992 2.992 0 0 0 .836-2.078c0-.807-.319-1.54-.836-2.078a24.65 24.65 0 0 0-1.415-6.43.75.75 0 1 0-1.409.516c.059.16.116.321.17.483Z"></path>
          </svg>
            Banners
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'customers' }"
            active-class="bg-gray-900 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                ></path>
            </svg>
            Customers
        </router-link>
        <router-link
            v-if="reportingNotBlocked"
            :to="{ name: 'feedbackOverview' }"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <base-magic-icon
                class="mr-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
            />
            Feedback
        </router-link>
        <span
            v-if="reportingNotBlocked"
            @click="showReportsSubMenuAndNavigate"
            active-class="bg-gray-700 text-white"
            class="focus:outline-none group mt-0.5 flex cursor-pointer rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
        >
            <svg
                class="mr-3 h-5 w-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                ></path>
            </svg>
            Analytics
            <div
                class="ml-auto pl-4"
                @click.stop="showReportsSubMenu = !showReportsSubMenu"
            >
                <base-chevron-icon
                    :class="{ '-rotate-180': showReportsSubMenu }"
                />
            </div>
        </span>
        <div v-auto-animate v-if="reportingNotBlocked">
            <router-link
                v-if="showReportsSubMenu"
                :to="{ name: 'ordersReport' }"
                active-class="text-white bg-gray-700"
                class="focus:outline-none group mt-0.5 flex items-center rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
              Sales
            </router-link>
            <router-link
                v-if="showReportsSubMenu"
                :to="{ name: 'adyenBalanceReport' }"
                active-class="text-white bg-gray-700"
                class="focus:outline-none group mt-0.5 flex items-center rounded-md px-2 py-2 pl-10 font-heading text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
                Accounting
            </router-link>
        </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    components: {},
    props: {
        isOgav: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isFeatureAvailable: 'user/isFeatureAvailable',
            accountId: 'user/accountId',
            userId: 'user/userId'
        }),
        reportingNotBlocked() {
          // @TODO Manjaros reporting access
          if(this.accountId !== '89449ec1-8df1-4bbb-997e-bd9348bcc113' && this.accountId !== '413c45a4-ef9d-4365-902a-82576bf3a35c'
              && this.accountId !== '048d3672-331c-4241-beb0-b60e3cc72cda'
              && this.accountId !== '4222230b-dc4d-44fc-9cb3-2dfd184955b6'
              && this.accountId !== '7d627c9e-6502-4068-84f4-868499ccda63'
              && this.accountId !== 'c41e04e9-a424-44b9-93b4-3cc3166b1995'
              && this.accountId !== '7569153a-c9eb-4974-8fcd-3584b76a6800'
              && this.accountId !== 'da7b7de5-afb2-43e8-92fa-82adab05e767'
              && this.accountId !== '7a8ed699-6c9a-4404-a679-1588470ddd40'
          ) {
            return true;
          }
          else if (this.accountId === '89449ec1-8df1-4bbb-997e-bd9348bcc113' && this.userId !== 10846) {
            return false;
          }
          else if (this.accountId === '413c45a4-ef9d-4365-902a-82576bf3a35c' && this.userId !== 10847) {
            return false;
          }
          else if (this.accountId === '048d3672-331c-4241-beb0-b60e3cc72cda' && this.userId !== 9474 && this.userId !== 10903) {
            return false;
          }
          else if (this.accountId === '7d627c9e-6502-4068-84f4-868499ccda63' && this.userId !== 11336) {
            return false;
          }
          // Byron
          else if (this.accountId === '4222230b-dc4d-44fc-9cb3-2dfd184955b6' && this.userId === 11168) {
            return false;
          }
          // Gracey's
          else if (this.accountId === 'c41e04e9-a424-44b9-93b4-3cc3166b1995' && this.userId === 11895) {
            return false;
          }
          // Savan
          else if (this.accountId === '7569153a-c9eb-4974-8fcd-3584b76a6800' && this.userId !== 5925) {
            return false;
          }

          // Arancina
          else if (this.accountId === '7a8ed699-6c9a-4404-a679-1588470ddd40' && this.userId === 9438) {
            return false;
          }

          // Sliceburg
          else if (this.accountId === 'da7b7de5-afb2-43e8-92fa-82adab05e767' && this.userId === 13032) {
            return false;
          }
          return true;
        }
    },
    data() {
        return {
            showReportsSubMenu: false,
            showOrdersSubMenu: false,
            FeaturesEnum
        };
    },
    methods: {
        showOrdersSubMenuAndNavigate() {
            if (this.$router.currentRoute.name !== 'orders') {
                this.$router.push({ name: 'orders' });
            }
            this.showOrdersSubMenu = !this.showOrdersSubMenu;
        },
        showReportsSubMenuAndNavigate() {
            if (!this.$router.currentRoute.path.includes('reports')) {
                this.$router.push({ name: 'ordersReport' });
            }
            this.showReportsSubMenu = !this.showReportsSubMenu;
        }
    }
};
</script>
