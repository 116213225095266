<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <div class="z-20 h-14 w-auto mt-4">
            <div
                v-if="Object.keys(menu).length"
                class="mx-auto flex h-full max-w-7xl glass bg-white items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div
                    class="w-2/5 items-center overflow-hidden sm:w-auto lg:flex-shrink-0"
                >
                    <div
                        class="text-xs font-medium text-indigo-600 sm:text-base"
                    >
                        <ClickToEdit
                            class="cursor-pointer"
                            :value="menu.name"
                            :label="'name'"
                            @input="updateMenuName"
                        />
                    </div>
                </div>
                <div class="flex w-full items-center justify-end">

                  <div class="mr-2 hidden sm:flex">
                    <div class="inline-flex items-center">
                            <span
                                class="relative z-0 inline-flex rounded-md shadow-sm"
                            >
                                <button
                                    type="button"
                                    @click="useAdminIndex = true"
                                    class="focus:outline-none relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2.5 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    :class="{ 'bg-gray-50': useAdminIndex }"
                                >
                                    <span class="sr-only">Print sorting</span>
                                    <svg
                                        class="h-5 w-5 text-gray-400"
                                        :class="{
                                            'text-gray-800': useAdminIndex
                                        }"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    type="button"
                                    @click="useAdminIndex = false"
                                    class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2.5 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                    :class="{
                                        'bg-gray-50': !useAdminIndex
                                    }"
                                >
                                    <span class="sr-only">Menu sorting</span>
                                    <svg
                                        class="h-5 w-5 text-gray-400"
                                        :class="{
                                            'bg-gray-50 text-gray-800': !useAdminIndex
                                        }"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M4 6h16M4 12h16M4 18h7"
                                        ></path>
                                    </svg>
                                </button>
                            </span>
                    </div>
                  </div>

                  <base-button
                    :button-text="showProducts ? 'Collapse' : 'Expand'"
                    size="sm"
                    @clicked="showProducts = !showProducts"
                  >
                    <template #right-icon>
                      <base-chevron-icon
                          class="-ml-1 -mr-1 sm:ml-2 w-4 h-4"
                          :class="{ 'rotate-180': showProducts }"
                      />
                    </template>
                  </base-button>

                    <div
                        v-if="isFeatureAvailable(FeaturesEnum.IKENTOO_BETA)"
                        class="ml-2"
                    >
                        <button
                            type="button"
                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            @click="showMenuSyncModal(FeaturesEnum.IKENTOO)"
                        >
                            Lightspeed Edit &amp; Sync
                        </button>
                    </div>
                    <div class="ml-2">
                        <base-button
                          @clicked="openMenuSettingsSidebar"
                          button-text="Menu settings"
                          size="sm"
                        />
                    </div>
                    <flat-file-launcher
                        v-if="!isWebExtension"
                        class="ml-2"
                        managed
                        title="Menu import"
                        :fields="menuImportFields"
                        type="Menu"
                        :status="csvImportStatus"
                        @csvImport="submitCsvMenu"
                    />
                </div>
            </div>
        </div>
        <ToggleSidebarButton />
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-end px-4 sm:px-6 lg:px-8"
            >
                <div class="flex pb-2">
                    <div class="mx-2 sm:hidden">
                        <button
                            type="button"
                            @click="showSortCategoriesModal"
                            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <base-expand-icon class="mr-2 h-4 w-4" />
                            Sort Categories
                        </button>
                    </div>
                    <div
                        class="-mt-2 ml-2"
                    >
                        <ExportButton @clicked="showExportCsvModal" />
                    </div>
                    <button
                        v-if="isFeatureAvailable(FeaturesEnum.MULTI_MENU)"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="showMenuHoursModal"
                    >
                        Menu availability
                    </button>
                    <div class="flex flex-col pl-4 md:flex-row">
                        <div
                            class="h-4 w-4"
                            :class="{
                                'text-gray-900 spinner':
                                    isLoading || isLoadingOrder
                            }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    isFeatureAvailable(FeaturesEnum.IKENTOO) &&
                    menu &&
                    menu.venue &&
                    menu.venue.posProvider === 'ikentoo' &&
                    menu.posId
                "
                class="mx-3 max-w-7xl rounded-lg sm:mx-auto sm:px-6 md:px-8"
            >
                <div
                    class="flex w-auto flex-col items-start bg-white rounded-md shadow-inner border border-dashed border-gray-400 px-4 py-5 sm:flex-row sm:p-6"
                >
                    <div>
                        <h2 class="flex items-center text-sm font-medium">
                            <span class="mr-2 text-green-600">
                                <base-tick-icon />
                            </span>
                            Connected to Lightspeed
                        </h2>
                        <span class="text-xs" v-tooltip="lastSyncedAtFormatted"
                            >Last synced:
                            {{ menu.lastSyncedAt | moment('from', 'now') }} -
                        </span>
                        <br class="sm:hidden" />
                        <span class="text-xs"
                            >version: {{ menu.version || 1 }}</span
                        >
                    </div>
                    <div class="flex flex-row">
                        <div class="mb-2 rounded-md shadow-sm sm:mb-0 sm:ml-5">
                            <a
                                :href="
                                    'https://pos-admin.lsk.lightspeed.app/menu-management/menus/' +
                                    menu.posId
                                "
                                target="_blank"
                                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 sm:text-sm"
                            >
                                <base-lightspeed-icon />
                                <span>View in POS</span>
                            </a>
                        </div>
                        <div class="ml-3 rounded-md shadow-sm sm:ml-5">
                            <button
                                type="button"
                                :class="{ spinner: isPosLoading }"
                                :disabled="isPosLoading"
                                @click="syncMenuWithLightspeed"
                                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 sm:text-sm"
                            >
                                <base-lightspeed-icon />
                                <span :class="{ 'text-white': isPosLoading }"
                                    >Sync menu</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <progress-bar
                v-if="showProgressBar"
                :progress="progressBarData"
                :text="progressBarText"
                class="m-5 p-4 text-center"
            />

            <div
                class="mx-auto max-w-7xl sm:px-6 md:px-8"
                v-if="!isLoading && categories"
                :key="categoriesKey"
            >
                <draggable
                    v-model="sortedCategories"
                    @end="updateItemOrder"
                    handle=".draggable-category"
                >
                    <div
                        v-for="(category, index) in sortedCategories"
                        :key="category.id"
                    >
                        <MenuCategory
                            :key="category.id"
                            :index="index"
                            @product-added="fetchCategories"
                            :category="category"
                            :categoryCount="categoryCount"
                            :showCategory="showProducts || categoryCount === 1"
                            :isPosManaged="isPosManaged"
                            :isDeliverectManaged="!!menu.deliverectId"
                            @show-pos-only-managed-notify="
                                showPosOnlyManagedNotify
                            "
                            @editCategory="editCategory(category)"
                            @showEditProductSidebar="showEditProductSidebar"
                        />
                    </div>
                </draggable>

                <div
                    v-if="!isLoading && categories.length === 0"
                    @click="showCategoryModal"
                    class="my-10 cursor-pointer rounded-md border border-dashed border-gray-400 bg-gray-100 py-6 text-center transition transition-colors duration-500 ease-in-out hover:bg-gray-200 sm:my-24"
                >
                    <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            vector-effect="non-scaling-stroke"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        />
                    </svg>
                    <h3 class="mt-2 text-sm font-semibold text-gray-900"
                        >Create your first category</h3
                    >
                    <p class="mt-1 text-sm text-gray-500"
                        >Menu items are organised into categories. Get started
                        by creating a new menu category.</p
                    >
                    <div class="mt-6">
                        <base-button
                            @clicked="showCategoryModal"
                            buttonText="Create new category"
                            size="sm"
                        />
                    </div>
                </div>

                <div v-else class="mt-6">
                    <span class="inline-flex rounded-md shadow-sm">
                        <button
                            @click="showCategoryModal"
                            type="button"
                            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        >
                            Add category
                        </button>
                    </span>
                </div>
            </div>

            <div v-if="isLoading" class="mx-auto max-w-7xl sm:px-6 md:px-8">
                <div class="mt-4 bg-white px-3 py-5 shadow sm:rounded-lg">
                    <VclList :width="450" :height="150" />
                </div>

                <div class="mt-4 bg-white px-3 py-5 shadow sm:rounded-lg">
                    <VclList :width="400" :height="150" />
                </div>

                <div class="mt-4 bg-white px-3 py-5 shadow sm:rounded-lg">
                    <VclList :width="420" :height="150" />
                </div>
            </div>
        </main>
        <AddProductModal
            v-if="menu.venue"
            :posProvider="menu.venue?.posProvider"
            :categories="categories"
            @product-added="fetchCategories"
        />
        <AddCategoryModal @product-added="fetchCategories" />
        <SortCategoriesModal @sorted="fetchCategories" />
        <SnoozeItemAvailabilityModal
          v-if="menu.venueId"
          :venue-id="menu.venueId"
          @product-snoozed="fetchCategories"
        />
        <MenuHoursModal
            v-if="Object.keys(menu).length"
            :menuHours="menu.menuHours"
            :menuId="menu.id"
            :alwaysOpen="menu.alwaysOpen"
            @alwaysOpenUpdate="alwaysOpenUpdate"
        />
        <external-import-menu-modal />
        <menu-sync-modal @fetch-menu="fetchCategories" />
        <edit-category-sidebar
            v-if="!isLoading && menu.venueId"
            v-model="editCategorySidebar"
            :category="editedCategory"
            :venue-id="menu.venueId"
            @update="fetchCategories"
        />
        <edit-product-sidebar
            v-if="menu.venue && editedProduct"
            v-model="editProductSidebar"
            :product="editedProduct"
            :venue="menu.venue"
            @update="fetchCategories"
        />
        <menu-settings-sidebar
            v-if="Object.keys(menu).length"
            v-model="menuSettingsSidebar"
            :menu="menu"
        />
        <snooze-table-modal @category-snoozed="categorySnoozed" />
        <sync-products-with-centegra-modal @update="fetchCategories" />
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import { VclList } from 'vue-content-loading';
import ably from '@/helpers/ably';
import { tags } from '@/helpers/productTags';
import { FeaturesEnum } from '@/enums';
import AddProductModal from '@/components/modals/AddProductModal';
import AddCategoryModal from '@/components/modals/AddCategoryModal';
import SnoozeItemAvailabilityModal from '@/components/modals/SnoozeItemAvailabilityModal';
import ExternalImportMenuModal from '@/components/modals/ExternalImportMenuModal';
import MenuHoursModal from '@/components/modals/MenuHoursModal';
import SyncProductsWithCentegraModal from '@/components/modals/SyncProductsWithCentegraModal';
import MenuSyncModal from '@/components/modals/MenuSyncModal';
import MenuCategory from '@/components/menuBuilder/MenuCategory';
import EditCategorySidebar from '@/components/sidebars/EditCategorySidebar';
import MenuSettingsSidebar from '@/components/sidebars/MenuSettingsSidebar';
import ClickToEdit from '@/components/helpers/ClickToEdit';
import ToggleSidebarButton from '@/components/helpers/ToggleSidebarButton';
import EditProductSidebar from '@/components/sidebars/EditProductSidebar';
import ProgressBar from '@/components/helpers/ProgressBar';
import ExportButton from '@/components/helpers/ExportButton';
import SortCategoriesModal from '@/components/modals/SortCategoriesModal';
import SnoozeTableModal from '@/components/modals/SnoozeTableModal';

export default {
    name: 'editMenu',
    metaInfo: {
        title: 'Edit Menu'
    },
    components: {
        EditProductSidebar,
        EditCategorySidebar,
        MenuSettingsSidebar,
        MenuCategory,
        AddProductModal,
        AddCategoryModal,
        MenuHoursModal,
        ClickToEdit,
        VclList,
        draggable,
        ToggleSidebarButton,
        FlatFileLauncher: () => import('../components/csv/FlatFileLauncher'),
        ExternalImportMenuModal,
        ProgressBar,
        SyncProductsWithCentegraModal,
        ExportButton,
        MenuSyncModal,
        SnoozeItemAvailabilityModal,
        SortCategoriesModal,
        SnoozeTableModal
    },
    data() {
        const baseFields = [
            { label: 'Product Name', value: 'product_name' },
            { label: 'Product Description', value: 'product_description' },
            { label: 'Product Printer Name', value: 'product_print_name' },
            { label: 'Product ID', value: 'product_id' },
            { label: 'Price', value: 'price' },
            { label: 'Tax Rate %', value: 'tax' },
            { label: 'Image URL', value: 'image' },
            { label: 'PLU', value: 'plu' },
            { label: 'SKU', value: 'sku' },
            { label: 'PosId', value: 'posId' },
            { label: 'Track Inventory', value: 'trackInventory' },
            { label: 'Inventory Count', value: 'inventory' },
            { label: 'Modifier Groups', value: 'modifier_groups' },
            { label: 'Allergens', value: 'allergens' },
            { label: 'Video', value: 'video' },
            { label: 'Popular', value: 'popular' },
            { label: 'Deleted', value: 'deleted' }
        ];

        return {
            debounce: false,
            isLoading: true,
            isUpdating: false,
            isPosLoading: false,
            isLoadingOrder: false,
            categoriesKey: 1,
            showProducts: true,
            menu: {},
            categories: [],
            editedCategory: {},
            editedProduct: {},
            adminSortedCategories: [],
            newCategory: {},
            csvImportStatus: 'pending',
            menuImportFields: [
                {
                    label: 'Category Name',
                    key: 'categoryName',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ],
                    description:
                        "Group your products into categories. We'll match categories by their name so keep them consistent."
                },
                {
                    label: 'Product Name',
                    key: 'itemName',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ]
                },
                {
                    label: 'Product description',
                    key: 'itemDescription'
                },
                {
                    label: 'Product Price',
                    key: 'itemPrice',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^(?=.)([+-]?([0-9]*)(.([0-9]+))?)$',
                            error: 'Must be a number.'
                        },
                        {
                            validate: 'required'
                        }
                    ],
                    description: 'Product base price.'
                },
                {
                    label: 'Item Inventory Count',
                    key: 'itemInventory',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^\\d+$',
                            error: 'Must be a number.'
                        }
                    ],
                    description:
                        'Optionally set an inventory quantity for each product.'
                },
                {
                    label: 'Item index',
                    key: 'itemIndex',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^\\d+$',
                            error: 'Must be a number.'
                        }
                    ],
                    description:
                        'Specify the order in which products should appear on your menu. Starting from 0 at the top.'
                },
                {
                    label: 'Item PLU / SKU',
                    key: 'itemPlu'
                },
                {
                    label: 'Popular',
                    key: 'popular',
                    type: 'checkbox',
                    description:
                        'Tag items as popular to highlight them on your menu'
                },
                {
                    label: 'Image URL',
                    key: 'itemImageUrl'
                },
                {
                    label: 'PosId',
                    key: 'itemPosId',
                    description:
                        'Optionally set a POS ID for each product, only needed for direct integrations.'
                }
            ],
            editCategorySidebar: false,
            FeaturesEnum,
            menuSettingsSidebar: false,
            editProductSidebar: false,
            progressBarData: null,
            progressBarText: '',
            showProgressBar: false,
            csvTitle: 'Menu',
            baseFields,
            fields: [
                { label: 'Category Name', value: 'category_name' },
                { label: 'Category ID', value: 'category_id' },
                ...baseFields
            ],
            menuAnalytics: null,
            accountChannel: null
        };
    },
    beforeDestroy() {
        this.accountChannel ? this.accountChannel.detach() : true;
    },
    async created() {
        this.fetchCategories(true);
        await this.fetchMenu(true);
        this.pusherListen();
        await this.fetchMenuAnalytics();

        if (this.menu.importStatus === 'inProgress') {
            this.showProgressBar = true;
            this.progressBarData = {
                percent: 40,
                currentStep: 2
            };
            this.progressBarText = 'Woflow import in progress...';
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!document.body.classList.contains('flatfile-active')) {
            return next();
        }

        const answer = window.confirm(
            "Do you really want to leave? You'll lose import progress"
        );

        return answer ? next() : next(false);
    },
    methods: {
        ...mapActions({
            setAdminIndexUse: 'user/setAdminIndexUse'
        }),
        categorySnoozed({ id, disabledUntil }) {
            this.categories = this.categories.map(category =>
                category.id === id ? { ...category, disabledUntil } : category
            );
        },
        editCategory(category) {
            if (!category) {
                return;
            }

            this.editedCategory = { ...category };
            this.editCategorySidebar = true;
        },
        openMenuSettingsSidebar() {
            this.menuSettingsSidebar = true;
        },
        showEditProductSidebar(product) {
            this.editedProduct = { ...product };
            this.editProductSidebar = true;
        },
        showCategoryModal() {
            if (!this.isPosManaged) {
                this.$modal.show('add-category-modal', {
                    categoriesLength: this.categories.length,
                    venueId: this.menu.venueId
                });
            } else {
                this.showPosOnlyManagedNotify('category');
            }
        },
        showMenuHoursModal() {
            this.$modal.show('menu-hours-modal');
        },
        showExternalModal() {
            this.$modal.show('external-import-menu-modal', {
                venueId: this.menu.venueId
            });
        },
        showMenuSyncModal(integration) {
            if (integration === FeaturesEnum.IKENTOO) {
                this.$modal.show('menu-sync-modal', {
                    title: 'LIghtspeed menu sync',
                    oldCategories: this.categories,
                    venueId: this.menu.venueId,
                    menuId: this.menu.id,
                    provider: 'lightspeed'
                });
            }
        },
        alwaysOpenUpdate(value) {
            if (Object.keys(this.menu).length) {
                this.menu.alwaysOpen = value;
            }
        },

        async updateMenuName(value) {
            if (value) {
                const payload = { name: value };

                try {
                    await this.$axios.patch(
                        `/menus/${this.$route.params.id}`,
                        payload
                    );

                    this.menu.name = value;
                } catch (e) {
                    throw new Error(`API ${e}`);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async fetchMenu(inital) {
            if (inital) {
                this.isLoading = true;
            } else {
                this.isUpdating = true;
            }

            try {
                const { data } = await this.$axios.get(
                    `/menus/${this.$route.params.id}`
                );

                this.menu = data.menu;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.isUpdating = false;
            }
        },
        async fetchMenuAnalytics() {
            try {
                const { data } = await this.$axios.get(
                    `/menus/${this.$route.params.id}/analysis`
                );

                this.menuAnalytics = data;
            } catch (error) {
                throw new Error(error);
            }
        },
        async fetchCategories(inital) {
            if (inital) {
                this.isLoading = true;
            } else {
                this.isUpdating = true;
            }

            try {
                const { data } = await this.$axios.get(
                    `/categories/${this.$route.params.id}?showAll=true&cache=false&fetchPrinters=true`
                );

                data.categories.forEach(category => {
                    category.products = category.products.map(product => { return { ...product, oldCategoryId: category.id } })

                    return category
                })

                this.categories = data.categories;
                this.adminSortedCategories = this._.orderBy(
                    data.categories,
                    'adminIndex'
                );
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
                this.isUpdating = false;
                this.categoriesKey++;
            }
        },
        async createCategory() {
            const savedCategory = this.newCategory;
            this.isUpdating = true;
            this.newCategory = {};

            try {
                await this.$axios.post(
                    `/categories/${this.$route.params.id}`,
                    savedCategory
                );
            } catch (e) {
                this.newCategory = savedCategory;

                throw new Error(`API ${e}`);
            } finally {
                this.isUpdating = false;
            }
        },
        async updateItemOrder() {
            const index = this.useAdminIndex ? 'adminIndex' : 'index';

            let indexedCategories = [];

            this.sortedCategories.forEach((category, i) => {
                indexedCategories[i] = { id: category.id, index: i };
            });

            this.isLoadingOrder = true;

            try {
                await this.$axios.put('/categories/sort', {
                    categories: indexedCategories,
                    index
                });
                await this.fetchCategories();

                this.$notify({
                  group: 'settings',
                  duration: 2000,
                  text: `Category order updated`,
                });

            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoadingOrder = false;
            }
        },
        async submitCsvMenu(menus) {
            this.isLoading = true;
            this.csvImportStatus = 'pending';

            try {
                await this.$axios.post(
                    `/menus/${this.$route.params.id}/import-csv`,
                    menus
                );

                this.csvImportStatus = 'success';
                this.fetchCategories();
            } catch (e) {
                this.csvImportStatus = 'failed';

                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        showPosOnlyManagedNotify(item) {
            this.$notify({
                group: 'settings',
                duration: 5000,
                text: `You can't add a new ${item} here. Please create new items in your POS`,
                speed: 100
            });
        },
        pusherListen() {
            if (this?.menu?.venue?.posProvider === 'ikentoo') {
                this.progressBarText =
                    'Your menu from lightspeed is updating, current status:';
            } else {
                this.progressBarText =
                    'Your menus from deliverect are updating, current status:';
            }

            const channelName = `account-${this.accountId}`;
            const event = `${channelName}-menu-import`;

            this.accountChannel = ably.channels.get(channelName);

            this.accountChannel.subscribe(
                event,
                ({ data: { progressData } }) => {
                    this.progressBarData = progressData;
                    this.showProgressBar = true;

                    if (progressData.percent === 100) {
                        this.fetchMenu();
                        this.fetchCategories();
                        setTimeout(() => {
                            this.showProgressBar = false;
                        }, 3000);
                    }
                }
            );
        },
        syncMenuWithCentegra() {
            this.$modal.show('sync-products-with-centegra-modal', {
                categories: this.categories
            });
        },

        // old lightspeed sync
        async fetchLightspeedMenus() {
            try {
                await this.$axios.get(
                    `/ikentoo/menu-list?venueId=${this.venue.venueId}`
                );
                this.lightspeedMenus = '';
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isUpdating = false;
            }
        },
        async syncMenuWithLightspeed() {
            if (this.isPosLoading) {
                return;
            }
            this.isUpdating = true;
            this.isPosLoading = true;
            this.newCategory = {};

            try {
                await this.$axios.post(`/ikentoo/update-menu`, {
                    menuId: this.$route.params.id,
                    venueId: this.menu.venueId
                });

                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Synced Started Successfully'
                });
            } catch (e) {
                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Sync Failed - Please contact support'
                });

                throw new Error(`API ${e}`);
            } finally {
                this.isPosLoading = false;
                this.isUpdating = false;
            }
        },
        // end of old lightspeed sync
        async syncMenuWithOpenTable() {
            try {
                await this.$axios.post(`/opentable/sync-menu`, {
                    menuId: this.$route.params.id
                });

                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Synced Started Successfully'
                });
            } catch (e) {
                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Sync Failed - Please contact support'
                });

                throw new Error(`API ${e}`);
            } finally {
                this.isPosLoading = false;
                this.isUpdating = false;
            }
        },
        showSortCategoriesModal() {
            this.$modal.show('sort-categories-modal', {
                categoriesToSort: this.categories
            });
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                multiCsvData: this.csvModifiers,
                csvData: this.csvData,
                fields: this.fields
            });
        },
        productAllergensList(productTags = []) {
            const minVal = 100;
            const maxVal = 1000;
            // allergens only
            const filteredTags = tags.filter(tag => {
                return tag.value >= minVal && tag.value <= maxVal;
            });

            if (productTags && productTags.length) {
                return filteredTags
                    .flatMap(({ value, label }) => {
                        return productTags.includes(value) ? [label] : [];
                    })
                    .join(', ');
            }

            return '';
        }
    },
    computed: {
        ...mapGetters({
            multiMenuEnabled: 'user/multiMenuEnabled',
            mockEnabled: 'user/mockEnabled',
            useAdminIndex: 'user/useAdminIndex',
            isFeatureAvailable: 'user/isFeatureAvailable',
            accountId: 'user/accountId',
            isWebExtension: 'ui/isWebExtension'
        }),
        csvData() {
            const data = [];

            for (const category of this.orderedCategories) {
                if (category.products) {
                    for (const product of category.products) {
                        data.push({
                            category_name: category.name,
                            category_id: category.id,
                            product_name: product.name,
                            product_description: product.description,
                            product_print_name: product.printName || '',
                            product_id: product.id,
                            price: product.price / 100,
                            tax: product.deliveryTax / 100,
                            image: product.image,
                            plu: product.plu,
                            sku: product.sku,
                            posId: product.posId,
                            trackInventory: product.trackInventory,
                            inventory: product.inventory,
                            modifier_groups: product.modifierGroups.length,
                            allergens: this.productAllergensList(product.tags),
                            video: !!product.video,
                            popular: product.popular,
                            deleted: product.archived
                        });
                    }
                }
            }

            return data;
        },

        csvModifiers() {
            const data = [];

            const fields = [
                { label: 'Modifier Group', value: 'modifier_group_name' },
                { label: 'Modifier Group ID', value: 'modifier_group_id' },
                ...this.baseFields
            ];

            for (const category of this.orderedCategories) {
                if (category.products) {
                    for (const product of category.products) {
                        if (product.modifierGroups?.length) {
                            for (const modifierGroup of product.modifierGroups) {
                                if (modifierGroup.products?.length) {
                                    for (const modifierProduct of modifierGroup.products) {
                                        const modifierGroupId =
                                            modifierGroup.id;
                                        const productId = modifierProduct.id;

                                        const hasModifierBeenAdded = data.some(
                                            ({
                                                modifier_group_id,
                                                product_id
                                            }) =>
                                                modifier_group_id ===
                                                    modifierGroupId &&
                                                product_id === productId
                                        );

                                        if (hasModifierBeenAdded) {
                                            continue;
                                        }

                                        data.push({
                                            modifier_group_name: modifierGroup.name,
                                            modifier_group_id: modifierGroup.id,
                                            product_name: modifierProduct.name,
                                            product_description:
                                                modifierProduct.description,
                                            product_print_name:
                                                modifierGroup.printName || '',
                                            product_id: modifierProduct.id,
                                            price: modifierProduct.price / 100,
                                            tax:
                                                modifierProduct.deliveryTax /
                                                    100 || '',
                                            image: modifierProduct.image,
                                            plu: modifierProduct.plu,
                                            sku: modifierProduct.sku,
                                            posId: modifierGroup.posId,
                                            trackInventory:
                                                modifierProduct.trackInventory,
                                            inventory:
                                                modifierProduct.inventory,
                                            modifier_groups:
                                                product.modifierGroups.length,
                                            allergens: this.productAllergensList(modifierProduct.tags),
                                            video: !!product.video,
                                            popular: modifierProduct.popular,
                                            deleted: product.archived
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return [
                {
                    fields,
                    name: 'Modifiers_',
                    transactionsPerDay: data
                }
            ];
        },

        orderedCategories: {
            get: function () {
                return this._.orderBy(this.categories, 'index');
            },
            set: function (categories) {
                this.categories = categories;
            }
        },
        isPosManaged() {
            return (
                !!this.menu.deliverectId ||
                (this.menu?.venue?.posProvider === 'ikentoo' &&
                    !!this.menu.posId)
            );
        },
        useAdminIndex: {
            get() {
                return this.$store.state.user.useAdminIndex;
            },
            set(value) {
                this.setAdminIndexUse(value);
            }
        },

        sortedCategories: {
            get() {
                if (this.useAdminIndex) {
                    return this.adminSortedCategories;
                } else {
                    return this.categories;
                }
            },
            set(value) {
                if (this.useAdminIndex) {
                    this.adminSortedCategories = value;
                } else {
                    this.categories = value;
                }
            }
        },
        categoryCount() {
            return this.categories.length || 0;
        },
        lastSyncedAtFormatted() {
            return this.$moment(this.menu?.lastSyncedAt, 'HH:mm DD-MM-YYYY');
        }
    }
};
</script>

<style scoped>
.spinner.slug-spinner {
    color: black;
    pointer-events: all;
}

.spinner.slug-spinner:after {
    left: auto;
    right: 1em;
}
</style>
