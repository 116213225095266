<template>
    <div class="flex w-full flex-col items-center justify-between gap-5">
        <label v-if="label" :for="keyName" class="text-xs text-gray-700">
            {{ label }}
        </label>
        <tiptap-editor
            v-model="computedValue"
            links
            decorations
            :placeholder="placeholder"
            class="w-full overflow-hidden rounded border"
            @focus="onFocus"
            @input="onChange"
        />
    </div>
</template>

<script>
import TiptapEditor from '@/components/comments/TiptapEditor';

export default {
    components: {
        TiptapEditor
    },
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        keyName: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        onChange(value) {
            this.$emit('change', this.keyName, value);
        },
        onFocus() {
            this.$emit('focus', this.keyName);
        }
    }
};
</script>
