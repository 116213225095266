<template>
    <div v-if="!isLoading">
        <div class="bg-white pb-4 pt-5 sm:py-6 sm:pb-4">
            <div>
                <label
                    for="name"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Name
                    <span v-show="showPrintName"> (Printer override)</span>
                </label>
                <div class="relative mt-1 flex rounded-md shadow-sm">
                    <input
                        v-show="!showPrintName"
                        id="name"
                        v-model="product.name"
                        class="form-input block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        :class="{
                            'border-red-300': $v.product.name.$error
                        }"
                        placeholder="eg Four Seasons Pizza"
                    />
                    <input
                        v-show="showPrintName"
                        id="printName"
                        v-model="product.printName"
                        class="form-input block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Printer override name"
                    />
                    <button
                        @click="togglePrintName(true)"
                        type="button"
                        class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                        :class="{ 'bg-gray-200': showPrintName }"
                    >
                        <svg
                            class="h-5 w-5 text-gray-400"
                            :class="{ 'text-green-700': !!product.printName }"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                            ></path>
                        </svg>
                    </button>
                    <button
                        @click="togglePrintName(false)"
                        type="button"
                        class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                        :class="{ 'bg-gray-200': !showPrintName }"
                    >
                        <svg
                            class="h-5 w-5 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            ></path>
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="mt-3">
                <base-text-area
                    label="Description"
                    v-model="product.description"
                    :rows="3"
                    id="description"
                />
            </div>
            <div class="mt-3 flex flex-col sm:mt-4 sm:flex-row">
                <div class="w-full sm:w-2/3">
                    <label
                        for="price"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Price
                    </label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                        <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">
                                {{ currency.symbol }}
                            </span>
                        </div>
                        <currency-input
                            id="price"
                            v-model="product.price"
                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300': $v.product.price.$error
                            }"
                            placeholder="0.00"
                            :currency="null"
                            locale="en-GB"
                            :value-as-integer="true"
                            :allow-negative="false"
                        />
                        <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">
                                {{ currency.ISO }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mt-3 w-full sm:mt-0 sm:w-1/3 sm:pl-4">
                    <base-input-field
                        v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.PLU)"
                        label="PLU"
                        id="plu"
                        v-model="product.plu"
                        placeholder="e.g. ABC01"
                    />
                    <base-input-field
                        label="SKU / PLU"
                        id="sku"
                        v-model="product.sku"
                        placeholder="e.g. ABC01"
                    />
                </div>
            </div>

            <div class="mt-3 w-full">
                <label
                    for="compareAtPrice"
                    class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                    v-tooltip="
                        'The compare at price will show next to the actual price, with a line through it to convey a discount.'
                    "
                >
                    Compare at price
                    <span class="ml-1 text-gray-500">(optional)</span>
                    <base-question-icon class="ml-1" />
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <currency-input
                        id="compareAtPrice"
                        v-model="product.compareAtPrice"
                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                        placeholder="0.00"
                        :currency="null"
                        locale="en-GB"
                        :value-as-integer="true"
                        :allow-negative="false"
                    />
                </div>
            </div>

            <div
                v-if="
                    isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                    isFeatureAvailable(FeaturesEnum.COMTREX) ||
                    isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                    isFeatureAvailable(FeaturesEnum.TISSL)
                "
                class="mt-3 w-full"
            >
                <base-input-field
                    label="POS ID"
                    id="posId"
                    tooltip="The unique identifier for this product in your POS system database"
                    v-model="product.posId"
                    :errors="$v.product.posId.$error"
                    placeholder="e.g. 1234"
                    error-message="This field is required"
                />
            </div>

            <div class="mt-3">
                <label
                    for="popular"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Highlight as popular item
                </label>

                <div class="mt-1 flex h-9 flex-row items-center">
                    <div>
                        <toggle-switch v-model="product.popular" />
                    </div>
                </div>

                <!--                <label-->
                <!--                    for="new"-->
                <!--                    class="block text-sm font-medium leading-5 text-gray-700"-->
                <!--                >-->
                <!--                    Highlight as new item-->
                <!--                </label>-->

                <!--                <div class="mt-1 flex h-9 flex-row items-center">-->
                <!--                    <div>-->
                <!--                        <toggle-switch v-model="product.new" />-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>

            <div
                v-if="
                    isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                    isFeatureAvailable(FeaturesEnum.INTERNAL)
                "
                class="mt-3 w-full"
            >
                <label
                    for="tax"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Tax Rate
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <currency-input
                        id="tax"
                        v-model="product.deliveryTax"
                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                        placeholder="0.00%"
                        :currency="null"
                        locale="en-GB"
                        :value-as-integer="true"
                        :allow-negative="false"
                    />
                </div>
            </div>

            <div class="mt-3">
                <label
                    for="inventory"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Track Inventory
                </label>

                <div class="mt-1 flex h-9 flex-row items-center">
                    <div>
                        <toggle-switch
                            :value="product.trackInventory"
                            @input="toggleInventory()"
                        />
                    </div>

                    <div
                        class="relative ml-4 w-full rounded-md shadow-sm"
                        v-if="product.trackInventory"
                    >
                        <currency-input
                            id="inventory"
                            v-model="product.inventory"
                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300': $v.product.inventory.$error
                            }"
                            placeholder="0"
                            :currency="null"
                            :precision="0"
                            locale="en-GB"
                            :value-as-integer="true"
                            :allow-negative="false"
                        ></currency-input>
                        <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">
                                Quantity in stock
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <div class="flex flex-row items-center justify-between">
                    <label
                        for="image"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Image
                        <span class="text-gray-400"> (optional) </span>
                    </label>
                    <span
                        @click="product.image = ''"
                        class="transition-duration-100 cursor-pointer pl-14 text-red-400 transition hover:text-red-700"
                        v-if="product.image"
                    >
                        <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                    </span>
                    <div
                        v-if="product.image"
                        class="mt-2 p-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 shadow-inner"
                    >
                      <div
                          class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                          :style="`background-image: url(${product.image}-/resize/288x/-/format/auto/)`"
                      >
                      </div>
                    </div>
                </div>
                <div class="w-full">
                    <div>
                        <uploadcare-inline
                            :ctxName="`${ctxName}product_settings_image`"
                            inputAcceptTypes="image/*"
                            tabs="local, dropbox, url, gdrive"
                            @success="onImageSuccess"
                        >
                            <div
                                v-if="product.image"
                                class="mt-2 p-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 shadow-inner"
                            >
                              <div
                                  class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                  :style="`background-image: url(${product.image}-/resize/288x/-/format/auto/)`"
                              >
                              </div>
                          </div>
                        </uploadcare-inline>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
            :class="{ spinner: isLoading }"
        >
            <span class="flex rounded-md shadow-sm">
                <button
                    @click="update"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                >
                    Save changes
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'ProductDetailSettings',
    components: {
        UploadcareInline,
        ToggleSwitch
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            isImageReady: true,
            FeaturesEnum,
            showPrintName: false,
            ctxName: this.product.id
        };
    },
    validations: {
        product: {
            name: {
                required
            },
            price: {
                required
            },
            inventory: {
                required
            },
            posId: {
                required(value) {
                    return ['comtrex', 'centegra'].includes(
                        this.venue.posProvider
                    )
                        ? !!(value && value.length)
                        : true;
                }
            }
        }
    },
    methods: {
        close() {
            this.isImageReady = true;
            this.$emit('close');
        },
        update() {
            this.$v.product.$touch();

            if (this.$v.product.$invalid) {
                this.errors = 'ERROR';

                return;
            }

            this.$emit('update');
        },
        updateCategories() {
            this.$emit('update-categories');
        },
        onImageSuccess(event) {
            this.product.image = event.cdnUrl;
        },
        onImageError(event) {
            throw new Error(`Error: ${event}`);
        },
        imageProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isImageReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isImageReady = true;
            }
        },
        async toggleInventory() {
            if (!this.isLoading) {
                this.isLoading = true;

                try {
                    await this.$axios.put(`/products/${this.product.id}`, {
                        id: this.product.id,
                        trackInventory: !this.product.trackInventory
                    });

                    this.product.trackInventory = !this.product.trackInventory;
                    this.updateCategories();
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        togglePrintName(showPrintName) {
            this.showPrintName = showPrintName;
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            currency: 'user/currencySettings'
        })
    }
};
</script>
