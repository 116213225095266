<template>
    <div class="mt-12 flex w-0 flex-1 flex-col overflow-hidden sm:mt-0">
        <main
            class="focus:outline-none relative z-0 flex flex-1 items-center justify-center overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <img
                class="absolute top-0 top-0 mx-auto h-8 w-auto sm:top-10 sm:h-10"
                :src="$store.state.ui.logoUrl"
                alt="Logo"
            />
            <div
                class="onboarding-card mx-auto w-auto w-full max-w-7xl px-0 sm:px-6 md:px-8"
            >
                <div
                    v-if="step === 0"
                    class="flex h-full flex-row items-center justify-center rounded-sm border border-gray-100 bg-white shadow sm:rounded-lg"
                >
                    <div
                        class="lg:min-w-md w-full px-4 py-5 sm:p-6 md:w-1/2 md:px-10 md:py-12"
                    >
                        <span class="text-sm text-gray-600">1/5</span>
                        <base-page-title title="Create your store" />

                        <p
                            class="mb-4 mt-3 max-w-lg text-sm leading-normal text-gray-700"
                        >
                            Let's create your first
                            {{ isStoreKit ? 'storekit' : '' }} store, you'll be
                            able to change all settings and create additional
                            stores afterwards. It shouldn't take more than a few
                            minutes.
                        </p>
                        <venue-type-selection
                            :venue-mode="venueMode"
                            @selected-option="setVenueMode"
                        />
                        <div class="mx-auto my-6">
                            <div class="rounded-md">
                                <button
                                    @click="step = 1"
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-md transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                    :disabled="isLoading"
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="hidden h-full items-center overflow-hidden bg-gray-50 md:flex md:w-1/2"
                    >
                        <img
                            src="https://ucarecdn.com/11526658-01cb-4241-9cd0-9d71a97d27f5/63d00f37440bc1cb2aad94ce_Untitled2p1600.png"
                            alt=""
                        />
                    </div>
                </div>

                <div
                    v-if="step === 1 && !showForm"
                    class="flex h-full flex-row items-center justify-center overflow-hidden rounded-sm border border-gray-100 bg-white shadow sm:rounded-lg"
                >
                    <div
                        class="lg:min-w-md w-full self-start px-4 py-5 sm:p-6 md:w-1/2 md:px-16 md:py-12"
                    >
                        <span class="text-sm text-gray-600">2/5</span>
                        <base-page-title title="Store details" />

                        <p
                            class="mb-4 mt-3 max-w-lg text-sm leading-normal text-gray-700"
                        >
                            First we need a few details about your store. If
                            you're trading already you should be able to search
                            by using your store name below and we'll fetch
                            information from google.
                        </p>

                        <google-place-finder
                            @place-selected="placeSelected"
                            :searched-query="venueProfile.name"
                            :errors="$v.venueProfile.name.$error"
                        />

                        <p
                            v-if="$v.venueProfile.name.$error"
                            class="-mb-2 mt-2 text-sm text-red-600"
                        >
                            Please enter a name for your store and select a place to continue
                        </p>

                        <div class="mx-auto my-6 flex justify-between">
                             <span class=" rounded-md shadow-sm">
                                <button
                                    @click="goToAddress()"
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    :disabled="isLoading"
                                >
                                    I don't have a google listing yet
                                </button>
                            </span>

                            <span class=" rounded-md shadow-sm">
                                <button
                                    type="button"
                                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                    :disabled="isLoading"
                                    @click="completeProfile()"
                                >
                                    Continue
                                </button>
                            </span>
                        </div>
                    </div>
                    <div
                        style="width: 300px"
                        class="h-full flex-1 overflow-hidden bg-gray-50 md:flex md:w-1/2"
                    >
                        <venue-map :coordinates="mapCoordinates" />
                        <themeable-frontend-skeleton
                            :name="venueProfile.name"
                        />
                        <!--                        <img-->
                        <!--                            src="https://ucarecdn.com/11526658-01cb-4241-9cd0-9d71a97d27f5/63d00f37440bc1cb2aad94ce_Untitled2p1600.png"-->
                        <!--                            alt=""-->
                        <!--                        />-->
                    </div>
                </div>

                <div
                    class="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6"
                    v-if="step === 1 && showForm"
                >
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <span class="text-sm text-gray-600">2/5</span>
                            <base-page-title title="Store profile" />

                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                This information will be displayed on your
                                store.
                            </p>
                        </div>
                        <div class="mt-5 md:col-span-2 md:mt-0">
                            <div>
                                <div class="grid grid-cols-3 gap-6">
                                    <div class="col-span-6 sm:col-span-4">
                                        <label
                                            for="name"
                                            v-tooltip="
                                                'Your store name will be shown to customers on your store, so keep it simple and recognisable'
                                            "
                                            class="flex items-center text-sm font-medium leading-5 text-gray-700"
                                            >Store name
                                            <base-question-icon class="ml-1" />
                                        </label>
                                        <div
                                            class="relative mt-1 w-full rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model.trim="venueProfile.name"
                                                id="name"
                                                placeholder="eg Your Store Name"
                                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                                :class="{
                                                    'border-red-300':
                                                        $v.venueProfile.name
                                                            .$error
                                                }"
                                            />
                                            <div
                                                v-if="
                                                    $v.venueProfile.name.$error
                                                "
                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                            >
                                                <svg
                                                    class="h-5 w-5 text-red-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <p
                                            v-if="$v.venueProfile.name.$error"
                                            class="-mb-2 mt-2 text-sm text-red-600"
                                            id="email-error"
                                            >Please enter a name for your store.
                                            You can always change it later</p
                                        >
                                    </div>

                                    <div class="col-span-6 sm:col-span-4">
                                        <label
                                            for="url"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >Website
                                            <span class="text-gray-400">
                                                (optional)
                                            </span></label
                                        >
                                        <input
                                            v-model.trim="venueAddress.url"
                                            @blur="findBrand"
                                            id="url"
                                            type="url"
                                            placeholder="eg. https://restaurant.com"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        />
                                    </div>

                                    <div
                                        class="col-span-3 hidden sm:col-span-2"
                                    >
                                        <label
                                            for="venueSlug"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                        >
                                            Store public address
                                        </label>
                                        <div
                                            class="mt-1 flex select-none rounded-md shadow-sm"
                                            :class="{
                                                'slug-spinner spinner': slugLoading
                                            }"
                                        >
                                            <span
                                                class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500"
                                            >
                                                https://{{ publicHostname }}/
                                            </span>
                                            <slug-input
                                                :value="venueProfile.name"
                                                @change="
                                                    value =>
                                                        (venueProfile.slug = value)
                                                "
                                                type="hidden"
                                                class="input-reset pa2 br1 ba b--black-20 w-100 relative bg-white"
                                            ></slug-input>
                                            <input
                                                disabled
                                                v-model="venueProfile.slug"
                                                @blur="
                                                    checkSlug(
                                                        $event.target.value
                                                    )
                                                "
                                                class="form-input block w-full flex-1 rounded-none rounded-r-md text-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                placeholder="your-store-name"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="about"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        About
                                        <span class="text-gray-400"
                                            >(optional)</span
                                        >
                                    </label>
                                    <div class="rounded-md shadow-sm">
                                        <textarea
                                            id="about"
                                            rows="2"
                                            v-model="venueProfile.description"
                                            class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            placeholder=""
                                        ></textarea>
                                    </div>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Brief description of your store
                                    </p>
                                </div>

                                <div class="mt-6">
                                    <div class="rounded-md shadow-sm">
                                        <base-input-field
                                            label="Instagram username"
                                            labelPostfix="(optional)"
                                            id="instagramUsername"
                                            v-model="
                                                venueProfile.instagramUsername
                                            "
                                            placeholder="e.g. storekit"
                                            prefix="@"
                                        />
                                    </div>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="photo"
                                        v-tooltip="
                                            'For the best results, upload a square image at the highest resolution you have available and at least 160x160 pixels.'
                                        "
                                        class="flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Logo
                                        <span class="ml-1 text-gray-400"
                                            >(optional)</span
                                        >
                                        <base-question-icon class="ml-1" />
                                    </label>
                                    <div class="mt-2 flex items-center">
                                        <span
                                            v-if="venueProfile.logo"
                                            class="flex hidden h-12 w-12 items-center overflow-hidden rounded-md bg-gray-100 sm:inline-block"
                                        >
                                            <img
                                                :src="
                                                    venueProfile.logo +
                                                    '-/resize/320x/-/format/auto/'
                                                "
                                                alt="Logo"
                                            />
                                        </span>

                                        <uploadcare-inline
                                            ctxName="venue_logo"
                                            inputAcceptTypes="image/*"
                                            tabs="local, dropbox, url, gdrive"
                                            class="sm:w-9/12"
                                            @success="onLogoSuccess"
                                        >
                                        </uploadcare-inline>
                                    </div>
                                </div>

                                <!--                                <div class="mt-6">-->
                                <!--                                    <label-->
                                <!--                                        for="cover_photo"-->
                                <!--                                        class="block text-sm font-medium leading-5 text-gray-700"-->
                                <!--                                    >-->
                                <!--                                        Cover photo-->
                                <!--                                        <span class="text-gray-400"-->
                                <!--                                            >(optional)</span-->
                                <!--                                        >-->
                                <!--                                    </label>-->
                                <!--                                    <div>-->
                                <!--                                        <uploadcare-->
                                <!--                                            ctxName="venue_cover"-->
                                <!--                                            publicKey="71f65abd77a8d66ef2e1"-->
                                <!--                                            inputAcceptTypes="image/*"-->
                                <!--                                            tabs="local, instagram, dropbox, url, gdrive"-->
                                <!--                                            @success="onCoverPhotoSuccess"-->
                                <!--                                        >-->
                                <!--                                            <div-->
                                <!--                                                class="mt-2 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5"-->
                                <!--                                            >-->
                                <!--                                                <div-->
                                <!--                                                    class="text-center"-->
                                <!--                                                    v-if="-->
                                <!--                                                        !venueProfile.headerImage-->
                                <!--                                                    "-->
                                <!--                                                >-->
                                <!--                                                    <base-picture-icon-->
                                <!--                                                        class="mx-auto h-12 w-12 text-gray-400"-->
                                <!--                                                    />-->
                                <!--                                                    <p-->
                                <!--                                                        class="mt-1 text-sm text-gray-600"-->
                                <!--                                                    >-->
                                <!--                                                        <button-->
                                <!--                                                            class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"-->
                                <!--                                                        >-->
                                <!--                                                            Click to upload a-->
                                <!--                                                            file-->
                                <!--                                                        </button>-->
                                <!--                                                    </p>-->
                                <!--                                                    <p-->
                                <!--                                                        class="mt-1 text-xs text-gray-500"-->
                                <!--                                                    >-->
                                <!--                                                        PNG, JPG, GIF up to 10MB-->
                                <!--                                                    </p>-->
                                <!--                                                </div>-->
                                <!--                                                <div v-else class="w-full">-->
                                <!--                                                    <div-->
                                <!--                                                        class="relative h-40 w-full max-w-md rounded-sm bg-cover bg-center"-->
                                <!--                                                        :style="-->
                                <!--                                                            'background-image: url(' +-->
                                <!--                                                            venueProfile.headerImage +-->
                                <!--                                                            ');'-->
                                <!--                                                        "-->
                                <!--                                                    ></div>-->
                                <!--                                                </div>-->
                                <!--                                            </div>-->
                                <!--                                        </uploadcare>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="my-6 ml-4 flex flex-shrink-0 justify-between"
                    v-if="step === 1 || step === 10"
                >
                    <button
                        @click="step = 0; showForm = false;"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go back
                    </button>
                    <span
                        class="inline-flex rounded-md shadow-sm"
                        @click="completeProfile()"
                    >
                        <button
                            type="button"
                            class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        >
                            Next: Address
                        </button>
                    </span>
                </div>

                <div
                    class="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6"
                    v-if="step === 2"
                >
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <span class="text-sm text-gray-600">3/5</span>
                            <base-page-title title="Store information" />
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Add store contact and address information about
                                your store. We'll show this information publicly
                                to your customers.
                            </p>
                        </div>
                        <div class="mt-5 md:col-span-2 md:mt-0">
                            <form action="#" method="POST">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6 sm:col-span-4">
                                        <base-input-field
                                            label="Store email address"
                                            id="email"
                                            type="email"
                                            v-model="venueAddress.email"
                                            :errors="
                                                $v.venueAddress.email.$error
                                            "
                                            errorMessage="Please enter a valid email address
                                            for your store, we'll send
                                            notifications here"
                                            placeholder="e.g. hello@example.com"
                                            tooltip="We'll send notifications about your store to this email address."
                                        />
                                    </div>

                                    <div class="col-span-6 sm:col-span-4">
                                        <base-input-field
                                            label="Store phone number"
                                            labelPostfix="(optional)"
                                            id="tel"
                                            type="tel"
                                            v-model="venueAddress.phoneNumber"
                                            tooltip="We'll display this phone number publicly on your store page for customers to contact you."
                                            placeholder="e.g. 020 3318 2745"
                                        />
                                    </div>

                                    <div class="col-span-6">
                                        <label
                                            for="street"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >Street address</label
                                        >
                                        <input
                                            v-model.trim="
                                                venueAddress.address.street
                                            "
                                            placeholder="eg. 101 Regent St"
                                            autocomplete="shipping address-line1"
                                            id="street"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.venueAddress.address
                                                        .street.$error
                                            }"
                                        />
                                    </div>

                                    <div class="col-span-6">
                                        <label
                                            for="building_number"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >Building name / line 2</label
                                        >
                                        <input
                                            v-model.trim="
                                                venueAddress.address
                                                    .buildingNumber
                                            "
                                            placeholder="eg. Victory House"
                                            autocomplete="shipping address-line2"
                                            id="building_number"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        />
                                    </div>

                                    <div
                                        class="col-span-6 sm:col-span-6 lg:col-span-3"
                                    >
                                        <label
                                            for="city"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >City</label
                                        >
                                        <input
                                            id="city"
                                            v-model.trim="
                                                venueAddress.address.city
                                            "
                                            autocomplete="shipping locality"
                                            placeholder="eg. London"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.venueAddress.address.city
                                                        .$error
                                            }"
                                        />
                                    </div>

                                    <div
                                        class="col-span-6 sm:col-span-3 lg:col-span-3"
                                    >
                                        <label
                                            for="postal_code"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >Post code</label
                                        >
                                        <input
                                            id="postal_code"
                                            v-model.trim="
                                                venueAddress.address.postCode
                                            "
                                            placeholder="eg. W1B 4EZ"
                                            autocomplete="shipping postal-code"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.venueAddress.address
                                                        .postCode.$error
                                            }"
                                        />
                                    </div>
                                    <div class="col-span-6">
                                        <label
                                            for="building_number"
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                            >Country</label
                                        >
                                        <select
                                            id="model"
                                            name="model"
                                            v-model.trim="
                                                venueAddress.address.country
                                            "
                                            @change="
                                                setCurrency(
                                                    venueAddress.address.country
                                                )
                                            "
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            :class="{
                                                'border-red-300':
                                                    $v.venueAddress.address
                                                        .country.$error
                                            }"
                                        >
                                            <option
                                                v-for="country in countries"
                                                :key="country.code"
                                                :value="country.code"
                                            >
                                                {{ country.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    class="my-6 ml-4 flex flex-shrink-0 justify-between"
                    v-if="step === 2"
                >
                    <button
                        @click="step = 1"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go back
                    </button>
                    <span
                        class="inline-flex rounded-md shadow-sm"
                        @click="completeAddress()"
                    >
                        <button
                            type="button"
                            class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        >
                            Next: Opening Hours
                        </button>
                    </span>
                </div>

                <!-- Opening Hours -->

                <div
                    class="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6"
                    v-if="step === 3"
                >
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <span class="text-sm text-gray-600">4/5</span>
                            <base-page-title title="Opening hours" />
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Add the times you'd like to receive orders
                                throughout the week. <br /><br />You can always
                                change opening hours quickly, and add multiple
                                opening times for the same day after you've
                                created your store.
                            </p>
                        </div>
                        <div class="mt-5 md:col-span-2 md:mt-0">
                            <VenueHours
                                v-if="!isLoading"
                                :venueHours="venueHours"
                                :hoursDict="hoursDict"
                                :closedArray="closedArray"
                                :END_OF_THE_DAY="dayClosingTime"
                                :validationError="
                                    !!errors && $v.venueHours.$invalid
                                "
                                @editedVenueHours="editedVenueHours"
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="my-6 ml-4 flex flex-shrink-0 justify-between pb-8"
                    v-if="step === 3"
                >
                    <button
                        @click="step = 2"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go back
                    </button>
                    <span
                        class="inline-flex rounded-md shadow-sm"
                        @click="completeOpeningHours()"
                    >
                        <button
                            type="button"
                            class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        >
                            Next: Settings
                        </button>
                    </span>
                </div>

                <div
                    class="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6"
                    v-if="step === 4"
                >
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <span class="text-sm text-gray-600">5/5</span>
                            <base-page-title title="Settings" />
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Decide which services you'd like to enable and
                                how to receive notifications.
                            </p>
                        </div>
                        <div class="mt-5 md:col-span-2 md:mt-0">
                            <form action="#" method="POST">
                                <fieldset v-if="isTakeaway">
                                    <legend
                                        class="text-base font-medium leading-6 text-gray-900"
                                    >
                                        Fulfillment types
                                    </legend>
                                    <div class="mt-4">
                                        <base-checkbox
                                            v-model="
                                                venueSettings.acceptsDelivery
                                            "
                                            id="acceptsDelivery"
                                            label="Delivery"
                                            caption="Offer delivery orders"
                                        />
                                        <base-checkbox
                                            v-model="
                                                venueSettings.acceptsPickup
                                            "
                                            id="acceptsPickup"
                                            label="Pickup"
                                            caption="Offer pickup/collection orders"
                                            class="mt-4"
                                        />

                                        <div class="mt-6">
                                            <label
                                                for="minDeliveryAmount"
                                                class="block text-base font-medium leading-6 text-gray-900"
                                                >Minimum delivery amount</label
                                            >
                                            <span
                                                class="text-sm leading-5 text-gray-500"
                                                >Prevent customers from ordering
                                                delivery unless they spend a
                                                certain amount</span
                                            >

                                            <div
                                                class="relative mt-1 w-full rounded-md shadow-sm md:w-1/2"
                                            >
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                >
                                                    <span
                                                        class="text-gray-500 sm:text-sm sm:leading-5"
                                                    >
                                                        {{ currency.symbol }}
                                                    </span>
                                                </div>
                                                <currency-input
                                                    id="minimumDeliveryAmount"
                                                    v-model="
                                                        venueSettings.minDeliveryAmount
                                                    "
                                                    class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                    :class="{
                                                        'border-red-300':
                                                            $v.venueSettings
                                                                .minDeliveryAmount
                                                                .$error
                                                    }"
                                                    placeholder="12.50"
                                                    :currency="null"
                                                    locale="en-GB"
                                                    :value-as-integer="true"
                                                    :allow-negative="false"
                                                ></currency-input>
                                            </div>
                                        </div>

                                        <div class="mt-6">
                                            <label
                                                for="prepTime"
                                                class="block text-base font-medium leading-6 text-gray-900"
                                                >Prep time</label
                                            >
                                            <span
                                                class="text-sm leading-5 text-gray-500"
                                                >The average time it takes to
                                                prep an order</span
                                            >
                                            <div
                                                class="relative mt-1 w-full rounded-md shadow-sm md:w-1/2"
                                            >
                                                <currency-input
                                                    id="prepTime"
                                                    v-model="
                                                        venueSettings.prepTime
                                                    "
                                                    class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                                                    :class="{
                                                        'border-red-300':
                                                            $v.venueSettings
                                                                .prepTime.$error
                                                    }"
                                                    placeholder="30"
                                                    :currency="null"
                                                    :precision="0"
                                                    locale="en-GB"
                                                    :value-as-integer="true"
                                                    :allow-negative="false"
                                                ></currency-input>
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                >
                                                    <span
                                                        class="text-gray-500 sm:text-sm sm:leading-5"
                                                    >
                                                        Minutes
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="mt-6">
                                    <legend
                                        class="text-base font-medium leading-6 text-gray-900"
                                    >
                                        Order Notifications
                                    </legend>
                                    <p class="text-sm leading-5 text-gray-500">
                                        These are delivered when a new order is
                                        placed by a customer. When the order
                                        page is open, a sound will play as
                                        orders arrive.
                                    </p>
                                    <div class="mt-4">
                                        <div class="flex items-center">
                                            <input
                                                v-model="
                                                    venueSettings.emailNotifications
                                                "
                                                id="acceptsEmailNotifications"
                                                :value="true"
                                                name="form-input push_notifications"
                                                type="radio"
                                                class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                            />
                                            <label
                                                for="acceptsEmailNotifications"
                                                class="ml-3"
                                            >
                                                <span
                                                    class="block text-sm font-medium leading-5 text-gray-700"
                                                    >Email &amp; browser
                                                    notifications</span
                                                >
                                            </label>
                                        </div>
                                        <div class="mt-4 flex items-center">
                                            <input
                                                v-model="
                                                    venueSettings.emailNotifications
                                                "
                                                id="NoEmailNotifications"
                                                :value="false"
                                                name="form-input push_notifications"
                                                type="radio"
                                                class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                            />
                                            <label
                                                for="NoEmailNotifications"
                                                class="ml-3"
                                            >
                                                <span
                                                    class="block text-sm font-medium leading-5 text-gray-700"
                                                    >Browser only</span
                                                >
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="mt-6">
                                    <legend
                                        class="text-base font-medium leading-6 text-gray-900"
                                    >
                                        Fees
                                    </legend>
                                    <p class="text-sm leading-5 text-gray-500">
                                        Optionally charge additional fees per
                                        order.
                                    </p>
                                    <div class="mt-4">
                                        <div class="grid grid-cols-6 gap-6">
                                            <div
                                                v-if="isTakeaway"
                                                class="col-span-6 sm:col-span-6 lg:col-span-3"
                                            >
                                                <label
                                                    for="deliveryFee"
                                                    class="block text-sm font-medium leading-5 text-gray-700"
                                                    >Delivery Fee</label
                                                >
                                                <span
                                                    class="text-sm leading-5 text-gray-500"
                                                    >Charged on delivery
                                                    orders</span
                                                >
                                                <div
                                                    class="relative mt-1 rounded-md shadow-sm"
                                                >
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                                currency.symbol
                                                            }}
                                                        </span>
                                                    </div>
                                                    <currency-input
                                                        id="deliveryFee"
                                                        v-model="
                                                            venueSettings.deliveryFee
                                                        "
                                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                        :class="{
                                                            'border-red-300':
                                                                $v.venueSettings
                                                                    .deliveryFee
                                                                    .$error
                                                        }"
                                                        placeholder="0.00"
                                                        :currency="null"
                                                        locale="en-GB"
                                                        :value-as-integer="true"
                                                        :allow-negative="false"
                                                    ></currency-input>
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{ currency.ISO }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-span-6 sm:col-span-6 lg:col-span-3"
                                            >
                                                <label
                                                    for="serviceCharge"
                                                    class="block text-sm font-medium leading-5 text-gray-700"
                                                    >Service Charge</label
                                                >
                                                <span
                                                    class="text-sm leading-5 text-gray-500"
                                                    >Charged on every
                                                    order</span
                                                >
                                                <div
                                                    class="relative mt-1 rounded-md shadow-sm"
                                                >
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                                currency.symbol
                                                            }}
                                                        </span>
                                                    </div>
                                                    <currency-input
                                                        id="serviceCharge"
                                                        v-model="
                                                            venueSettings.serviceCharge
                                                        "
                                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                        placeholder="0.00"
                                                        :class="{
                                                            'border-red-300':
                                                                $v.venueSettings
                                                                    .serviceCharge
                                                                    .$error
                                                        }"
                                                        :currency="null"
                                                        locale="en-GB"
                                                        :value-as-integer="true"
                                                        :allow-negative="false"
                                                    ></currency-input>
                                                    <!--<input id="serviceCharge" v-model="venueSettings.serviceCharge" />-->
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{ currency.ISO }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    class="my-6 ml-4 flex flex-shrink-0 justify-between"
                    v-if="step === 4"
                >
                    <button
                        @click="step = 3"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Go back
                    </button>
                    <span
                        class="inline-flex rounded-md shadow-sm"
                        @click="completeSettings()"
                    >
                        <button
                            type="button"
                            class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            :disabled="isLoading"
                        >
                            Create store
                        </button>
                    </span>
                </div>
            </div>
        </main>
        <CreatedVenueModal :venue-id="createdVenueId" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, minLength, required } from 'vuelidate/lib/validators';
import COUNTRIES from '@/helpers/venueLocationCountries.json';
import UploadcareInline from './widgets/UploadcareInline';
import CreatedVenueModal from '../components/modals/CreatedVenueModal';
import VenueHours from '../components/editVenue/VenueHours.vue';
import VenueTypeSelection from '@/components/venueBuilder/VenueTypeSelection';
import GooglePlaceFinder from '@/components/venueBuilder/GooglePlaceFinder';
import ThemeableFrontendSkeleton from '@/components/widgets/ThemeableFrontendSkeleton';
import VenueMap from '../components/VenueMap.vue';
import slugify from '@sindresorhus/slugify';

const DEFAULT_VENUE_SETTINGS = {
    emailNotifications: true,
    acceptsDelivery: true,
    acceptsPickup: true,
    acceptsInStore: false,
    visualMenu: false,
    deliveryFee: 0,
    serviceCharge: 0,
    prepTime: 20,
    collectionPrepTime: 15,
    acceptsPreOrders: false,
    paymentsEnabled: false,
    smsNotificationsEnabled: false,
    smsNotificationsNumber: '',
    paymentsLegalInfo: {},
    cashEnabledDelivery: true,
    cardEnabledDelivery: false,
    cashEnabledPickup: true,
    cardEnabledPickup: false,
    autoAccept: false,
    enabled: true,
    minDeliveryAmount: 1500,
    googlePlaceId: null
}

const DEFAULT_VENUE_HOURS = [
    {
        day: 0,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 1,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 2,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 3,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 4,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 5,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    },
    {
        day: 6,
        openTime: '00:00',
        closeTime: '23:59',
        active: true
    }
];

const DEFAULT_VENUE_ADDRESS = {
    address: {
        buildingNumber: '',
        street: '',
        city: '',
        postCode: '',
        country: 'GB'
    },
    phoneNumber: '',
    email: ''
}

const DEFAULT_VENUE_PROFILE = {
    name: '',
    description: '',
    logo: '',
    slug: '',
    headerImage: null,
    instagramUsername: '',
    primaryColour: null,
};

const END_OF_THE_DAY = '23:59';

export default {
    name: 'createVenue',
    metaInfo: {
        title: 'Create your store'
    },
    components: {
        UploadcareInline,
        VenueMap,
        CreatedVenueModal,
        VenueHours,
        VenueTypeSelection,
        GooglePlaceFinder,
        ThemeableFrontendSkeleton
    },
    validations: {
        venueProfile: {
            name: {
                required,
                minLength: minLength(3)
            },
            slug: {
                required,
                minLength: minLength(3)
            }
        },
        venueAddress: {
            address: {
                buildingNumber: {},
                street: {
                    required,
                    minLength: minLength(4)
                },
                city: {
                    required
                },
                postCode: {
                    required
                },
                country: {
                    required
                }
            },
            email: {
                required,
                email
            }
        },
        venueHours: {
            required,
            minLength: minLength(7),
            $each: {
                openTime: {
                    required,
                    minLength: minLength(5)
                },
                closeTime: {
                    required,
                    minLength: minLength(5)
                }
            }
        },
        venueSettings: {
            emailNotifications: {
                required
            },
            acceptsDelivery: {
                required
            },
            acceptsPickup: {
                required
            },
            acceptsInStore: {
                required
            },
            prepTime: {
                required
            },
            serviceCharge: {
                required
            },
            deliveryFee: {
                required
            },
            minDeliveryAmount: {
                required
            }
        },
        validationGroup: [
            'venueProfile',
            'venueAddress',
            'venueSettings',
            'venueHours'
        ]
    },
    data() {
        return {
            createdVenueId: null,
            isLoading: false,
            brandSuggestion: null,
            slugLoading: false,
            showForm: false,
            storeType: null,
            step: 0,
            steps: [
                {
                    title: 'Account'
                },
                {
                    title: 'Store Profile'
                },
                {
                    title: 'Address'
                },
                {
                    title: 'Opening Hours'
                },
                {
                    title: 'Settings'
                }
            ],
            errors: '',
            closedArray: [false, false, false, false, false, false, false],
            hoursDict: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            venueProfile: {},
            venueAddress: {},
            venueSettings: {},
            venueHours: [],
            countries: COUNTRIES,
            venueModes: {
                TAKEAWAY: 'takeaway',
                ORDER_AND_PAY: 'orderAndPay',
                VISUAL_MENU: 'visualMenu'
            },
            venueMode: 'takeaway',
            mapCoordinates: [-0.1381, 51.51]
        };
    },
    created() {
        this.setDefaults()
    },
    mounted() {
        window.addEventListener('beforeunload', this.preventNav);
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        transformOpeningHours(regularOpeningHours) {
          const defaultHours = [];
          const END_OF_THE_DAY = "23:59";

          regularOpeningHours.periods.forEach(period => {
            const day = (period.open.day + 6) % 7;

            // Format hours and minutes with padding
            const openHour = period.open.hour.toString().padStart(2, '0');
            const openMinute = period.open.minute.toString().padStart(2, '0');
            const closeHour = period.close.hour.toString().padStart(2, '0');
            const closeMinute = period.close.minute.toString().padStart(2, '0');

            // Combine into time string format
            const openTime = `${openHour}:${openMinute}`;
            const closeTime = (closeHour === "00" && closeMinute === "00")
                ? END_OF_THE_DAY
                : `${closeHour}:${closeMinute}`;

            defaultHours.push({
              day,
              openTime,
              closeTime,
              active: true
            });
          });

          // Fill in missing days with default hours
          for (let i = 0; i < 7; i++) {
            if (!defaultHours.some(item => item.day === i)) {
              defaultHours.push({
                day: i,
                openTime: "09:00",
                closeTime: "17:00",
                active: false
              });
            }
          }

          return defaultHours;
        },
        setDefaults() {
            this.$v.$reset();

            const data = JSON.parse(JSON.stringify({
                venueProfile: DEFAULT_VENUE_PROFILE,
                venueAddress: DEFAULT_VENUE_ADDRESS,
                venueSettings: DEFAULT_VENUE_SETTINGS,
                venueHours: DEFAULT_VENUE_HOURS
            }))

            Object.assign(this, data)
        },
        async placeSelected(place) {

            const mode = this.venueMode;

            this.setDefaults()

            this.setVenueMode(mode)
            this.venueSettings.googlePlaceId = place.id

            if (place.regularOpeningHours?.periods) {
                this.venueHours = this.transformOpeningHours(place.regularOpeningHours);
            }

            if (place.displayName) {
                this.venueProfile.name = place.displayName.text;
                this.venueProfile.slug = slugify(place.displayName?.text);
            }

            if (place.websiteUri) {
                this.venueAddress.url = place.websiteUri;
                await this.findBrand();
            }

            if (place.internationalPhoneNumber) {
                this.venueAddress.phoneNumber =
                    place.internationalPhoneNumber;
            }

            if (place.location) {
                this.mapCoordinates = [
                    place.location.longitude,
                    place.location.latitude,
                ];
                this.venueAddress.address.coordinates = [
                    place.location.latitude,
                    place.location.longitude
                ];
            }

            if (place.addressComponents) {
                place.addressComponents.forEach(component => {
                    if (component.types.includes("street_number")) {
                        this.venueAddress.address.buildingNumber = component.longText;
                    }
                    if (component.types.includes("route")) {
                        this.venueAddress.address.street = component.longText;
                    }
                    if (component.types.includes("postal_town")) {
                        this.venueAddress.address.city = component.longText;
                    }
                    if (component.types.includes("postal_code")) {
                        this.venueAddress.address.postCode = component.longText;
                    }
                    if (component.types.includes("country")) {
                        this.venueAddress.address.country = component.shortText;
                        this.setCurrency(
                            component.shortText
                        )
                    }
                });
            }
        },
        setCurrency(countryCode) {
            const country = this.countries.find(
                ({ code }) => code === countryCode
            );

            this.venueSettings.currencyCode = country?.currency || 'GBP';
        },
        preventNav(event) {
            event.preventDefault();
            event.returnValue = '';
        },
        onCoverPhotoSuccess(event) {
            this.venueProfile.headerImage = event.cdnUrl;
        },
        onLogoSuccess(event) {
            this.venueProfile.logo = event.cdnUrl;
        },
         goToAddress() {
            this.setDefaults();
            this.showForm = true;
            this.step = 1;
        },
        completeProfile() {
            this.$v.venueProfile.$touch();
            if (this.$v.venueProfile.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.step = 2;
            }
        },
        completeAddress() {
            this.$v.venueAddress.$touch();
            if (this.$v.venueAddress.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.step = 3;
            }
        },
        editedVenueHours(hours) {
            this.venueHours = hours;
        },
        completeOpeningHours() {
            this.$v.venueHours.$touch();
            if (this.$v.venueHours.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.step = 4;
            }
        },
        completeSettings() {
            this.$v.venueSettings.$touch();
            if (this.$v.venueSettings.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.createVenue();
            }
        },
        createVenue() {
            this.isLoading = true;

            this.$axios
                .post('/venues', this.venuePayload)
                .then(res => {
                    const { data } = res;

                    if (this.$route.query.login_challenge) {
                        window.removeEventListener(
                            'beforeunload',
                            this.preventNav
                        );

                        this.$router.push({
                            name: 'oauthLogin',
                            query: {
                                login_challenge: this.$route.query
                                    .login_challenge
                            }
                        });
                    } else {
                        this.$modal.show('created-venue-modal', {
                            venueId: data.id,
                            venueSlug: data.slug,
                            newStore: true
                        });
                    }
                    if (window.analytics) {
                        window.analytics.track('Store Created', {
                            venueId: data.id,
                            accountId: this.user.accounts[0].id,
                            name: this.venuePayload.name,
                            type: this.venuePayload.acceptsInStore
                                ? 'order_and_pay'
                                : 'takeaway',
                            description: this.venuePayload.description,
                            slug: data.slug,
                            logo: this.venuePayload.logo,
                            instagram: this.venuePayload.instagramUsername,
                            address: {
                                city: this.venuePayload.address.city,
                                country: this.venuePayload.address.country
                            },
                            context: {
                                groupId: this.user.accounts[0].id
                            }
                        });
                        window.analytics.group(data.id, {
                            name: this.venuePayload.name,
                            type: this.venuePayload.acceptsInStore
                                ? 'order_and_pay'
                                : 'takeaway',
                            description: this.venuePayload.description,
                            slug: data.slug,
                            url: `https://order.storekit.com/${data.slug}/menu`,
                            logo: this.venuePayload.logo,
                            instagram: this.venuePayload.instagramUsername,
                            address: {
                                city: this.venuePayload.address.city,
                                country: this.venuePayload.address.country
                            },
                            deliverect: false,
                            orkestro: false,
                            airship: false,
                            shipday: false,
                            parent_company_id: this.user.accounts[0].id
                        });
                    }
                    this.fetchVenues();
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        checkSlug(slug) {
            if (slug.length > 1) {
                this.slugLoading = true;
                if (this.venueProfile.slug) {
                    return this.$axios
                        .get('/check-slug/' + slug)
                        .then(res => {
                            if (res.data !== 'available') {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .catch(e => {
                            throw new Error(`API ${e}`);
                        })
                        .finally(() => {
                            this.slugLoading = false;
                        });
                }
            }
        },
        async findBrand() {
            try {
                const url = this.venueAddress.url;
                const domain = new URL(url).hostname.replace('www.', '');

                const { data } = await this.$axios.get(
                    `/brandfetch/?domainName=${domain}`
                );

                this.brandSuggestion = data;
                if (data.description) {
                    this.venueProfile.description = data.description;
                }
                if (data.color) {
                    this.venueProfile.primaryColour = data.color;
                }
                if (data.logo) {
                    this.venueProfile.logo = data.logo;
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        },
        setVenueMode(mode) {
            if (mode === this.venueModes.TAKEAWAY) {
                this.venueSettings.acceptsInStore = false;
                this.venueSettings.visualMenu = false;
            } else if (mode === this.venueModes.ORDER_AND_PAY) {
                this.venueSettings.acceptsInStore = true;
                this.venueSettings.visualMenu = false;
            } else if (mode === this.venueModes.VISUAL_MENU) {
                this.venueSettings.acceptsInStore = false;
                this.venueSettings.visualMenu = true;
            }

            this.venueMode = mode;
        }
    },
    computed: {
        ...mapGetters({
            isStoreKit: 'user/isStoreKit',
            user: 'user/currentUser',
            currency: 'user/currencySettings'
        }),
        dayClosingTime() {
          return END_OF_THE_DAY;
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        venuePayload() {
            const hours = { venueHours: this.venueHours };
            const venue = {
                ...this.venueSettings,
                ...this.venueAddress,
                ...this.venueProfile,
                ...hours,
                opentable: !!this.$route.query.login_challenge
            };
            return venue;
        },
        coordinates() {
            let address =
                this.venueAddress.address.buildingNumber +
                ', ' +
                this.venueAddress.address.street +
                ', ' +
                this.venueAddress.address.city +
                ', ' +
                this.venueAddress.address.postcode +
                ', ' +
                this.venueAddress.address.country;
            return address;
        },
        isTakeaway() {
            return (
                !this.venueSettings.acceptsInStore &&
                !this.venueSettings.visualMenu
            );
        }
    }
};
</script>

<style>
.onboarding-card {
    height: 44rem;
}

.vue__time-picker input.display-time {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0.25rem;
    border-radius: 0.375rem;
    border-color: #d2d6dc;
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background-color: #5850ec !important;
    border-radius: 0.375rem;
}
</style>

<style scoped>
.spinner.slug-spinner {
    color: black;
    pointer-events: all;
}

.spinner.slug-spinner:after {
    left: auto;
    right: 1em;
}
</style>
