import Dashboard from '@/views/Dashboard';
import CreateVenue from '@/components/CreateVenue';
import AllOrders from '@/views/AllOrders.vue';
import Order from '@/views/Order.vue';
import Menus from '@/views/Menus';
import SalesReports from '@/views/SalesReports';
import Settings from '@/views/Settings';
import Webhooks from '@/views/Webhooks';
import Venues from '@/views/Venues';
import QRBuilder from '@/views/QRBuilder';
import Login from '@/views/Login';
import OAuthLogin from '@/views/OAuthLogin';
import OAuthConsent from '@/views/OAuthConsent';
import Signup from '@/views/Signup';
import CreateMenu from '@/components/CreateMenu';
import CreateMenuImport from '@/views/CreateMenuImport';
import EditMenu from '@/components/EditMenu';
import PasswordReset from '@/views/PasswordReset';
import Delivery from '@/components/reports/Delivery';
import Discounts from '@/views/Discounts';
import Offers from '@/views/Offers';
import CreateEditDiscountCode from '@/components/CreateEditDiscountCode';
import CreateEditOffer from '@/components/CreateEditOffer';
import LiveOrders from '@/views/LiveOrders';
import Customer from '@/views/Customer';
import Customers from '@/views/Customers';
import ChangePassword from '@/views/ChangePassword';
import PrinterIntegration from '@/components/venue/settings/PrinterIntegration';
import SettingsContainer from '@/components/venue/settings/SettingsContainer';
import PrinterSettings from '@/components/venue/settings/PrinterSettings';
import StoreNotices from '@/components/venue/settings/StoreNotices';
import DeliveryZones from '@/components/venue/settings/DeliveryZones';
import OpeningHours from '@/components/venue/settings/OpeningHours';
import BrandingSettings from '@/components/venue/settings/BrandingSettings';
import BrandingUpsell from '@/components/venue/settings/BrandingUpsell';
import OrderAndPay from '@/components/venue/settings/OrderAndPay';
import Operations from '@/components/venue/settings/Operations';
import GeneralSettings from '@/components/venue/settings/GeneralSettings';
import PickupSlotSettings from '@/components/venue/settings/PickupSlotSettings';
import SettingsList from '@/components/venue/settings/SettingsList';
import FacebookSettings from '@/components/venue/settings/FacebookSettings';
import TipjarSettings from '@/components/venue/settings/TipjarSettings';
import OrkestroSettings from '@/components/venue/settings/OrkestroSettings';
import DeliverectSettings from '@/components/venue/settings/DeliverectSettings';
import ShipdaySettings from '@/components/venue/settings/ShipdaySettings';
import NashSettings from '@/components/venue/settings/NashSettings';
import FreshKdsSettings from '@/components/venue/settings/FreshKdsSettings';
import PaymentSettings from '@/components/venue/settings/PaymentSettings';
import FeedbackReports from '@/views/FeedbackReports';
import FeedbackOverview from '@/components/reports/FeedbackOverview';
import FeedbackSettings from '@/components/venue/settings/FeedbackSettings';
import TipSettings from '@/components/venue/settings/TipSettings';
import FeedbackAverage from '@/components/reports/FeedbackAverage';
import CreateAdyenAccount from '@/components/venue/settings/adyen/CreateAdyenAccount';
import ManageAdyenAccountHolder from '@/components/venue/settings/adyen/ManageAdyenAccountHolder';
import ConnectAdyenAccount from '@/components/venue/settings/adyen/ConnectAdyenAccount';
import ManageAdyenAccount from '@/components/venue/settings/adyen/ManageAdyenAccount';
// adyen platform
import CreateAdyenPlatformAccount from '@/components/venue/settings/adyenPlatform/CreateAdyenPlatformAccount';
import ManageAdyenPlatformAccountHolder from '@/components/venue/settings/adyenPlatform/ManageAdyenPlatformAccountHolder';
import ConnectAdyenPlatformAccount from '@/components/venue/settings/adyenPlatform/ConnectAdyenPlatformAccount';
import AirshipSettings from '@/components/venue/settings/AirshipSettings';
import CentegraSettings from '@/components/venue/settings/CentegraSettings';
import DeliverectConnectedSettings from '@/components/venue/settings/DeliverectConnectedSettings';
import InstagramSettings from '@/components/venue/settings/InstagramSettings';
import InstagramAuthResult from '@/components/venue/settings/InstagramAuthResult';
import Lightspeed from '@/views/Lightspeed';
import LightspeedSettings from '@/components/venue/settings/LightspeedSettings';
import ComtrexSettings from '@/components/venue/settings/ComtrexSettings';
import ToggleSettings from '@/components/venue/settings/ToggleSettings';
import PayAtTableList from '@/views/PayAtTableList';
import SmartReviews from '@/components/SmartReviews';
import PiggySettings from '@/components/venue/settings/PiggySettings';
import MenuImportSpreadsheet from '@/views/MenuImportSpreadsheet';
import ResourcesScreen from '@/views/ResourcesScreen';
import UpsellScreen from '@/views/UpsellScreen';
import DiscountImportSpreadsheet from '@/views/DiscountImportSpreadsheet';
import KlaviyoSettings from '@/components/venue/settings/KlaviyoSettings.vue';
import CaptiveWifiSettings from '@/components/venue/settings/CaptiveWifiSettings.vue';
import GoogleAnalyticsSettings from '@/components/venue/settings/GoogleAnalyticsSettings.vue';
import OpentableSettings from '@/components/venue/settings/OpentableSettings.vue';
import KitchenCutSettings from '@/components/venue/settings/KitchenCutSettings';
import TisslSettings from '@/components/venue/TisslSettings';
import AtreemoSettings from '@/components/venue/settings/AtreemoSettings';
import GiftproSettings from '@/components/venue/settings/GiftproSettings';
import FeaturebaseSSO from '@/views/FeaturebaseSso';
import PedalMeSettings from "@/components/venue/settings/PedalMeSettings";
import StoreDesigner from '@/components/venue/settings/StoreDesigner';

const AdyenTerms = () => import('@/components/storekitPayments/AdyenTerms');
const OrdersDow = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/OrdersDow'
    );
const OrdersReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/Orders'
    );
const FailedOrdersReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/FailedOrders'
    );
const RevenueByTableArea = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/RevenueByTableArea'
    );
const OrdersTod = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/OrdersTod'
    );
const Products = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/Products'
    );
const Categories = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/Categories.vue'
    );
const AverageOrdeValue = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/AverageOrderValue'
    );
const TipsReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/TipsReport'
    );
const Z = () =>
    import(/* webpackChunkName: "sales-reports" */ '@/components/reports/Z');
const PaymentReports = () =>
    import(/* webpackChunkName: "payment-reports" */ '@/views/PaymentReports');
const AdyenBalanceReport = () =>
    import(
        /* webpackChunkName: "payment-reports" */ '@/components/reports/payments/AdyenBalance'
    );
const AdyenPayoutReport = () =>
    import(
        /* webpackChunkName: "payment-reports" */ '@/components/reports/payments/AdyenPayouts'
    );
const AdyenTransactionsByDayReport = () =>
    import(
        /* webpackChunkName: "transactions-reports" */ '@/components/reports/payments/AdyenTransactionsByDay'
    );
const AdyenAllTransactionsReport = () =>
    import(
        /* webpackChunkName: "transactions-reports" */ '@/components/reports/payments/AdyenAllTransactions'
    );
const DiscountsReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/DiscountsReport'
    );
const DiscountedOrdersReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/DiscountedOrdersReport'
    );
const RefundedOrdersReport = () =>
    import(
        /* webpackChunkName: "sales-reports" */ '@/components/reports/RefundedOrdersReport'
    );
// const CannyWidget = () => import('@/components/widgets/CannyWidget');

const FeaturebaseWidget = () =>
    import('@/components/widgets/FeaturebaseWidget');

const PreOrderPrepView = () => import('@/views/PreOrderPrepView');

const OrderCalendarView = () => import('@/views/OrderCalendarView');

const CateringView = () => import('@/views/CateringView');

const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            requiresGuest: true
        }
    },
    {
        path: '/oauth-login',
        name: 'oauthLogin',
        component: OAuthLogin,
        meta: {
            requiresAuth: false,
            requiresGuest: false
        }
    },
    {
        path: '/oauth-consent',
        name: 'oauthConsent',
        component: OAuthConsent,
        meta: {
            requiresAuth: false,
            requiresGuest: false
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: {
            requiresAuth: false,
            requiresGuest: true
        }
    },
    {
        path: '/signup/:referrerCode/',
        name: 'signupWithReferral',
        component: Signup,
        meta: {
            requiresAuth: false,
            requiresGuest: true
        }
    },
    {
        path: '/qr',
        name: 'qr',
        component: QRBuilder
    },
    {
        path: '/reset',
        name: 'reset',
        component: PasswordReset,
        meta: {
            requiresAuth: false
        },
        props: true
    },
    {
        path: '/change',
        name: 'change',
        component: ChangePassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stores',
        name: 'stores',
        component: Venues,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tables',
        name: 'tables',
        component: PayAtTableList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/resources',
        name: 'resources',
        component: ResourcesScreen,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/live-tables',
        name: 'liveTables',
        component: PayAtTableList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stores/create',
        name: 'createVenue',
        component: CreateVenue,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/adyen/account/:code',
        name: 'manageAdyenAccountHolderGlobal',
        component: ManageAdyenAccountHolder,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/adyen/platform/account/:code',
        name: 'manageAdyenPlatformAccountHolderGlobal',
        component: ManageAdyenPlatformAccountHolder,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stores/edit/:id/',
        component: SettingsContainer,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'editVenue',
                component: SettingsList
            },
            {
                path: 'general',
                name: 'generalSettings',
                component: GeneralSettings
            },
            {
                path: 'notices',
                name: 'storeNoticeSettings',
                component: StoreNotices
            },
            {
                path: 'branding',
                name: 'brandingSettings',
                component: BrandingSettings
            },
            {
                path: 'custom-branding',
                name: 'brandingUpsell',
                component: BrandingUpsell
            },
            {
                path: 'hours',
                name: 'openingHoursSettings',
                component: OpeningHours
            },
            {
                path: 'operations',
                name: 'opeationsSettings',
                component: Operations
            },
            {
                path: 'zones',
                name: 'deliveryZonesSettings',
                component: DeliveryZones,
                meta: {
                    fullWidth: true,
                    hideNav: true
                }
            },
            {
                path: 'store-designer',
                name: 'storeDesigner',
                component: StoreDesigner,
                meta: {
                    fullWidth: true,
                    hideNav: true,
                    hideToggleSidebar: true
                }
            },
            {
                path: 'pre-orders',
                name: 'pickupSlotSettings',
                component: PickupSlotSettings
            },
            {
                path: 'order-and-pay',
                name: 'OrderAndPaySettings',
                component: OrderAndPay,
                meta: {
                    fullWidth: true
                }
            },
            {
                path: 'printers',
                name: 'printerIntegration',
                component: PrinterIntegration
            },
            {
                path: 'printers/settings',
                name: 'printerSettings',
                component: PrinterSettings,
                props: route => ({
                    selectedMac: route.query.mac,
                    operation: route.query.operation
                })
            },
            {
                path: 'facebook',
                name: 'facebookSettings',
                component: FacebookSettings
            },
            {
                path: 'opentable',
                name: 'opentableSettings',
                component: OpentableSettings
            },
            {
                path: 'google-analytics',
                name: 'googleAnalyticsSettings',
                component: GoogleAnalyticsSettings
            },
            {
                path: 'captive-wifi',
                name: 'captiveWifiSettings',
                component: CaptiveWifiSettings
            },
            {
                path: 'klaviyo',
                name: 'klaviyoSettings',
                component: KlaviyoSettings
            },
            {
                path: 'tipjar',
                name: 'tipjarSettings',
                component: TipjarSettings
            },
            {
                path: 'orkestro',
                name: 'orkestroSettings',
                component: OrkestroSettings
            },
            {
                path: 'epos',
                name: 'deliverectSettings',
                component: DeliverectSettings
            },
            {
                path: 'deliverect',
                name: 'deliverectConnectedSettings',
                component: DeliverectConnectedSettings
            },
            {
                path: 'shipday',
                name: 'shipdaySettings',
                component: ShipdaySettings
            },
            {
                path: 'nash',
                name: 'nashSettings',
                component: NashSettings
            },
            {
                path: 'freshkds',
                name: 'freshkdsSettings',
                component: FreshKdsSettings
            },
            {
                path: 'tissl',
                name: 'tisslSettings',
                component: TisslSettings
            },
            {
                path: 'payments',
                name: 'paymentSettings',
                component: PaymentSettings
            },
            {
                path: 'feedback',
                name: 'feedbackSettings',
                component: FeedbackSettings
            },
            {
                path: 'tips',
                name: 'tipSettings',
                component: TipSettings
            },
            {
                path: 'account/create',
                name: 'createAdyenAccount',
                component: CreateAdyenAccount
            },
            {
                path: 'account/connect',
                name: 'connectAdyenAccount',
                component: ConnectAdyenAccount
            },
            {
                path: 'account/manage',
                name: 'mangeAdyenAccount',
                component: ManageAdyenAccount
            },
            {
                path: 'account/:code/status',
                name: 'manageAdyenAccountHolder',
                component: ManageAdyenAccountHolder
            },
            // adyen platform
            {
                path: 'account/platform/create',
                name: 'createAdyenPlatformAccount',
                component: CreateAdyenPlatformAccount
            },
            {
                path: 'account/platform/:accountHolderId/create',
                name: 'manageAdyenPlatformAccountHolder',
                component: ManageAdyenPlatformAccountHolder
            },
            {
                path: 'account/platform/connect',
                name: 'connectAdyenPlatformAccount',
                component: ConnectAdyenPlatformAccount
            },
            {
                path: 'airship',
                name: 'airshipSettings',
                component: AirshipSettings
            },
            {
                path: 'centegra',
                name: 'centegraSettings',
                component: CentegraSettings
            },
            {
                path: 'instagram',
                name: 'instagramSettings',
                component: InstagramSettings
            },
            {
                path: 'instagram/auth-result',
                name: 'instagramAuthResult',
                component: InstagramAuthResult
            },
            {
                path: 'lightspeed',
                name: 'lightspeedSettings',
                component: LightspeedSettings
            },
            {
                path: 'comtrex',
                name: 'comtrexSettings',
                component: ComtrexSettings
            },
            {
                path: 'toggle',
                name: 'toggleSettings',
                component: ToggleSettings
            },
            {
                path: 'piggy',
                name: 'piggySettings',
                component: PiggySettings
            },
            {
                path: 'kitchen-cut',
                name: 'kitchenCutSettings',
                component: KitchenCutSettings
            },
            {
                path: 'atreemo',
                name: 'atreemoSettings',
                component: AtreemoSettings
            },
            {
                path: 'giftpro',
                name: 'giftproSettings',
                component: GiftproSettings
            },
            {
                path: 'pedalme',
                name: 'pedalMeSettings',
                component: PedalMeSettings
            }
        ]
    },
    {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customers/:email',
        name: 'customerDetail',
        component: Customer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: AllOrders,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/live-orders/:id?',
        name: 'liveOrders',
        component: LiveOrders,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/prep',
        name: 'preOrderPrep',
        component: PreOrderPrepView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/calendar',
        name: 'orderCalendar',
        component: OrderCalendarView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/catering',
        name: 'cateringView',
        component: CateringView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/orders/:id',
        name: 'orderDetail',
        component: Order,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menus',
        name: 'menus',
        component: Menus,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menus/:venueId/create',
        name: 'createMenu',
        component: CreateMenu,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menus/:venueId/import',
        name: 'createMenuImport',
        component: CreateMenuImport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menus/:venueId/import/spreadsheet',
        name: 'menuImportSpreadsheet',
        component: MenuImportSpreadsheet,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/import/success',
        name: 'menu',
        component: CreateMenuImport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menus/:id/edit',
        name: 'editMenu',
        component: EditMenu,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/discounts',
        name: 'discounts',
        component: Discounts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners',
        name: 'offers',
        component: Offers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/discounts/import',
        name: 'discountsImport',
        component: DiscountImportSpreadsheet,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/discounts/new',
        name: 'newDiscount',
        component: CreateEditDiscountCode,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/discounts/:id',
        name: 'editDiscount',
        component: CreateEditDiscountCode,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners/new',
        name: 'newOffer',
        component: CreateEditOffer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners/:id',
        name: 'editOffer',
        component: CreateEditOffer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/sales',
        name: 'salesReports',
        component: SalesReports,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'orders',
                name: 'ordersReport',
                component: OrdersReport
            },
            {
                path: 'revenue-by-table-area',
                name: 'revenueByTableArea',
                component: RevenueByTableArea
            },
            {
                path: 'orders-dow',
                name: 'dowReport',
                component: OrdersDow
            },
            {
                path: 'orders-tod',
                name: 'todReport',
                component: OrdersTod
            },
            {
                path: 'products',
                name: 'productsReport',
                component: Products
            },
            {
                path: 'categories',
                name: 'categoriesReport',
                component: Categories
            },
            {
                path: 'delivery',
                name: 'deliveryReport',
                component: Delivery
            },
            {
                path: 'z',
                name: 'zReport',
                component: Z
            },
            {
                path: 'tips',
                name: 'tipsReports',
                component: TipsReport
            },
            {
                path: 'average-order',
                name: 'averageOrderValueReport',
                component: AverageOrdeValue
            },
            {
                path: 'discounts',
                name: 'discountsReports',
                component: DiscountsReport
            },
            {
                path: 'discounted-orders',
                name: 'discountedOrdersReport',
                component: DiscountedOrdersReport
            },
            {
                path: 'refunded-orders',
                name: 'refundedOrdersReport',
                component: RefundedOrdersReport
            },
            {
                path: 'failed-orders',
                name: 'failedOrdersReport',
                component: FailedOrdersReport
            }
        ]
    },
    {
        path: '/feedback',
        name: 'feedbackReports',
        component: FeedbackReports,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'overview',
                name: 'feedbackOverview',
                component: FeedbackOverview
            },
            {
                path: 'average',
                name: 'feedbackAverage',
                component: FeedbackAverage
            }
        ]
    },
    {
        path: '/smart-reviews',
        name: 'smartReviews',
        component: SmartReviews,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/upgrade',
        name: 'upgrade',
        beforeEnter: () => {
            window.location.href =
                'https://t09czsyu90l.typeform.com/to/gMpZl4lZ?typeform-source=storekit.com';
        },
        component: UpsellScreen,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/payments',
        name: 'paymentReports',
        component: PaymentReports,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'balance',
                name: 'adyenBalanceReport',
                component: AdyenBalanceReport
            },
            {
                path: 'payouts',
                name: 'adyenPayoutReport',
                component: AdyenPayoutReport
            },
            {
                path: 'transactions-by-day',
                name: 'adyenTransactionsByDayReport',
                component: AdyenTransactionsByDayReport
            },
            {
                path: 'transactions-all',
                name: 'adyenAllTransactionsReport',
                component: AdyenAllTransactionsReport
            }
        ]
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings/webhooks',
        name: 'webhooks',
        component: Webhooks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/auth/lightspeed',
        name: 'lightspeedAuth',
        component: Lightspeed,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/storekit-payments/terms',
        name: 'storeKitPaymentsTerms',
        component: AdyenTerms,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/feature-requests',
        name: 'feedback',
        component: FeaturebaseWidget,
        meta: {
            requiresAuth: true,
            featureBasePage: 'Board'
        }
    },
    {
        path: '/feature-requests/*',
        component: FeaturebaseWidget,
        meta: {
            requiresAuth: true,
            featureBasePage: 'Board'
        }
    },
    {
        path: '/release-notes',
        name: 'releaseNotes',
        component: FeaturebaseWidget,
        meta: {
            requiresAuth: true,
            featureBasePage: 'Changelog'
        }
    },
    {
        path: '/release-notes/*',
        component: FeaturebaseWidget,
        meta: {
            requiresAuth: true,
            featureBasePage: 'Changelog'
        }
    },
    {
        path: '/sso/featurebase',
        name: 'featurebaseSSO',
        component: FeaturebaseSSO,
        meta: {
            requiresAuth: true
        }
    }
];

export default routes;

