<template>
    <sliding-sidebar
        v-model="isOpened"
        title="Menu Settings"
        @on-escape-key-up="close"
    >
        <div>
            <div class="bg-white py-4" v-if="menu">
                <div class="mt-3">
                    <base-input-field
                        type="text"
                        label="Name"
                        id="name"
                        v-model="menu.name"
                    />
                </div>
                <div class="mb-1 mt-3">
                    <label
                        for="image"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Unavailable Product Options
                    </label>
                </div>
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                        <LargeRadioButton
                            :options="displaySettings"
                            v-model="menu.hideUnavailableItems"
                        />
                    </div>
                </div>
                <div class="mt-5">
                    <div class="flex flex-row items-center justify-between">
                        <label
                            for="image"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Image
                            <span class="text-gray-400"> (optional) </span>
                        </label>
                        <span
                            @click="menu.image = ''"
                            class="transition-duration-100 cursor-pointer pl-14 text-red-400 transition hover:text-red-700"
                            v-if="menu.image"
                        >
                            <svg
                                class="h-5 w-5"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>
                        </span>
                    </div>
                </div>
                <div>
                    <uploadcare-inline
                        :ctxName="`${menu.id}menu_settings_image`"
                        inputAcceptTypes="image/*"
                        tabs="local, dropbox, url, gdrive"
                        @success="onImageSuccess"
                    >
                        <div
                            :class="{
                                'p-6': !menu.image,
                                'p-3': menu.image
                            }"
                            class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300"
                        >
                            <div class="text-center" v-if="!menu.image">
                                <p class="mt-1 text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </div>
                            <div v-else class="w-auto">
                                <div
                                    class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                    :style="`background-image: url(${menu.image}-/resize/288x/-/format/auto/)`"
                                >
                                </div>
                            </div>
                        </div>
                    </uploadcare-inline>
                </div>
            </div>
            <div class="justify-between py-3 sm:flex sm:flex-row">
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="saveMenu"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-indigo-400 bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out focus:border-indigo-300 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Save changes
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="close"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
            <div
                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                class="mt-2 flex flex-shrink-0 flex-col items-center"
            >
                <button
                    @click="toggleArchiveMenu"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    {{ !menu.archived ? 'Archive' : 'Un-archive' }}
                    Menu
                </button>
            </div>
        </div>
    </sliding-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import SlidingSidebar from '../SlidingSidebar';
import LargeRadioButton from '../formComponents/LargeRadioButton';
import UploadcareInline from '@/components/widgets/UploadcareInline';

export default {
    name: 'MenuSettingsSidebar',
    components: {
        SlidingSidebar,
        LargeRadioButton,
        UploadcareInline
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            FeaturesEnum,
            isLoading: false,
            displaySettings: [
                {
                    label: 'Disable unavailable products',
                    labelDescription:
                        'Unavailable products will be visible but unable to be selected',
                    value: false
                },
                {
                    label: 'Remove unavailable products',
                    labelDescription:
                        'Unavailable products will be completely removed from this menu',
                    value: true
                }
            ],
            displayValue: null
        };
    },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' }),

        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close() {
            this.isOpened = false;
        },
        async saveMenu() {
            this.isLoading = true;

            try {
                await this.$axios.patch(`/menus/${this.$route.params.id}`, {
                    hideUnavailableItems: this.menu.hideUnavailableItems,
                    name: this.menu.name,
                    image: this.menu.image
                });

                this.close();
                this.$notify({
                    group: 'settings',
                    title: 'Menu Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async deleteMenu() {
            try {
                await this.$axios.delete(`/menus/${this.menu.id}`);
                this.$notify({
                    group: 'settings',
                    title: 'Menu archived'
                });

                this.menu.archived = true;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async restoreMenu() {
            try {
                await this.$axios.patch(`/menus/${this.menu.id}/restore`);
                this.$notify({
                    group: 'settings',
                    title: 'Menu unarchived'
                });

                this.menu.archived = false;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async toggleArchiveMenu() {
            const action = this.menu.archived ? 'unarchive' : 'archive';

            const confirmed = confirm(
                `Are you sure you want to ${action} this menu?`
            );

            if (confirmed) {
                if (action === 'archive') {
                    await this.deleteMenu();
                } else {
                    await this.restoreMenu();
                }
            }
        },
        onImageSuccess(event) {
            this.menu.image = event.cdnUrl;
        }
    }
};
</script>
