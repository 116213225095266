export const tags = [
    {
        label: 'Celery',
        value: 100
    },
    {
        label: 'Gluten',
        value: 101
    },
    {
        label: 'Crustaceans',
        value: 102
    },
    {
        label: 'Fish',
        value: 103
    },
    {
        label: 'Eggs',
        value: 104
    },
    {
        label: 'Lupin',
        value: 105
    },
    {
        label: 'Milk',
        value: 106
    },
    {
        label: 'Molluscs',
        value: 107
    },
    {
        label: 'Mustard',
        value: 108
    },
    {
        label: 'Nuts',
        value: 109
    },
    {
        label: 'Peanuts',
        value: 110
    },
    {
        label: 'Sesame',
        value: 111
    },
    {
        label: 'Soya',
        value: 112
    },
    {
        label: 'Sulphites',
        value: 113
    },
    {
        label: 'Almonds',
        value: 114
    },
    {
        label: 'Barley',
        value: 115
    },
    {
        label: 'Brazil Nuts',
        value: 116
    },
    {
        label: 'Cashew',
        value: 117
    },
    {
        label: 'Hazelnuts',
        value: 118
    },
    {
        label: 'Kamut',
        value: 119
    },
    {
        label: 'Macadamia',
        value: 120
    },
    {
        label: 'Oats',
        value: 121
    },
    {
        label: 'Pecan',
        value: 122
    },
    {
        label: 'Pistachio',
        value: 123
    },
    {
        label: 'Rye',
        value: 124
    },
    {
        label: 'Spelt',
        value: 125
    },
    {
        label: 'Walnuts',
        value: 126
    },
    {
        label: 'Wheat',
        value: 127
    },
    {
        label: 'Sugared Drink',
        value: 128
    },
    {
        label: 'Dairy',
        value: 129
    },
    {
        label: 'Lentils',
        value: 130
    },
    {
        label: 'Queensland Nuts',
        value: 131
    },
    {
        label: 'Shellfish',
        value: 132
    },
    {
        label: 'Treenuts',
        value: 133
    },
    {
        label: 'Sources of gluten',
        value: 134
    },
    {
        label: 'Alcohol',
        value: 1
    },
    {
        label: 'Halal',
        value: 2
    },
    {
        label: 'Kosher',
        value: 3
    },
    {
        label: 'Vegan',
        value: 4
    },
    {
        label: 'Vegetarian',
        value: 5
    },
    // @TODO Filter tags
    // {
    //     label: 'Can serve alone',
    //     value: 6
    // },
    // {
    //     label: 'Bottle deposit',
    //     value: 7
    // },
    // {
    //     label: 'Organic',
    //     value: 8
    // },
    // {
    //     label: 'Natural',
    //     value: 9
    // },
    // {
    //     label: 'Soft drink',
    //     value: 10
    // },
    // {
    //     label: 'Keto',
    //     value: 11
    // },
    // {
    //     label: 'Paleo',
    //     value: 12
    // },
    // {
    //     label: 'Plant based',
    //     value: 13
    // },
    // {
    //     label: 'HFSS',
    //     value: 14
    // },
    // {
    //     label: 'Tobacco',
    //     value: 15
    // },
    // {
    //     label: 'Baby nursing and feeding',
    //     value: 16
    // },
    // {
    //     label: 'Prepared food',
    //     value: 17
    // },
    // {
    //     label: 'Prepackaged food',
    //     value: 18
    // },
    // {
    //     label: 'TPP good',
    //     value: 19
    // },
    // {
    //     label: 'Milk cocoa',
    //     value: 20
    // },
    // {
    //     label: 'Bottled coffee drink',
    //     value: 21
    // },
    // {
    //     label: 'Bottled tea drink',
    //     value: 22
    // },
    // {
    //     label: 'Prepared drink',
    //     value: 23
    // },
    // {
    //     label: 'Energy beverage',
    //     value: 24
    // },
    // {
    //     label: 'Non-carbonated, unflavoured, unsweetened water',
    //     value: 25
    // },
    // {
    //     label: 'Non-carbonated, flavoured, sweetened water',
    //     value: 26
    // },
    // {
    //     label: 'Carbonated, unflavoured, unsweetened water',
    //     value: 27
    // },
    // {
    //     label: 'Carbonated, flavoured, sweetened water',
    //     value: 28
    // },
    // {
    //     label: '100% Juice',
    //     value: 29
    // },
    // {
    //     label: 'Unheated sandwich wrap',
    //     value: 30
    // },
    // {
    //     label: 'Candy',
    //     value: 31
    // },
    // {
    //     label: 'Prepackaged snack',
    //     value: 32
    // },
    // {
    //     label: 'Prepackaged ice cream',
    //     value: 33
    // },
    // {
    //     label: 'Scooped ice cream',
    //     value: 34
    // },
    // {
    //     label: 'Bakery item',
    //     value: 35
    // },
    // {
    //     label: 'Cigarettes',
    //     value: 36
    // },
    // {
    //     label: 'Cigars',
    //     value: 37
    // },
    // {
    //     label: 'E-cigarette',
    //     value: 38
    // },
    // {
    //     label: 'Vapes',
    //     value: 39
    // },
    // {
    //     label: 'Smokeless tobacco',
    //     value: 40
    // },
    {
        label: 'No allergens',
        value: 1000
    },
    {
        label: 'Gluten Free',
        value: 1101
    },
    {
        label: 'Sugar Free',
        value: 1128
    },
    {
        label: 'Lactose Free',
        value: 1129
    }
];
